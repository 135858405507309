<template>
    <ContentBox
        :loading="isLoading"
        preview
        simple
        @save="save"
    >
        <template #title>
            {{ $t('sites.modes.appearance.appearanceTitle') }}
        </template>

        <UiRow mb="m">
            <ContentSection
                :title="$t('sites.modes.appearance.widget.style')"
            >
                <UiField
                    :label="$t('sites.modes.common.form.title')"
                    area-id="common_title"
                >
                    <UiInput
                        v-model.trim="snapshot.title"
                        :placeholder="snapshot.name"
                        :error="errTitle"
                        id="common_title"
                        @keyup.enter.native="save"
                    />
                </UiField>

                <UiRow mt="m">
                    <UiField
                        :label="$t('sites.modes.common.form.background_color')"
                        area-id="common_background_color"
                    >
                        <div :class="$style['pick-colors']">
                            <UiColorPicker
                                v-model="colors"
                                :locale="locale"
                                id="common_background_color"
                            />

                            <div :class="$style['color-list']">
                                <div
                                    v-for="color in predefinedColors"
                                    :key="color"
                                    :class="$style['color-list__item']"
                                    :style="`background-color: #${color};`"
                                    @click="colors = '#' + color"
                                />
                            </div>
                        </div>
                    </UiField>
                </UiRow>

                <UiRow :class="$style['help-row']" mt="s">
                    <UiCheckbox
                        v-model="snapshot.inversion_colors"
                        :label="$t('sites.modes.appearance.inversion.title')"
                        :error="errInversionColors"
                    />
                    <UiTooltip placement="right">
                        <template #trigger>
                            <UiIcon :class="$style['help-row__icon']" name="helpIcon" />
                        </template>
                        <div :class="$style['help-row__tooltip']">
                            {{ $t('sites.modes.appearance.inversion.tooltip') }}
                        </div>
                    </UiTooltip>
                </UiRow>

                <UiRow mt="m">
                    <UiField
                        :label="$t('sites.modes.appearance.elements.title')"
                        :class="$style['radiogroup-box']"
                        area-id="common_shape"
                    >
                        <UiRadiogroup
                            v-model="snapshot.element_shape_type"
                            label-prop-name="label"
                            size="sm"
                        >
                            <UiRadiogroupOption
                                v-for="shape in shapeTypeOptions"
                                :key="shape.value"
                                :value="shape.value"
                                :label="shape.label"
                                size="sm"
                            >
                                <template #icon>
                                    <SvgSprite :name="shape.icon"/>
                                </template>
                            </UiRadiogroupOption>
                        </UiRadiogroup>
                    </UiField>
                </UiRow>

                <UiRow mt="m">
                    <UiField
                        :label="$t('sites.modes.appearance.icon.title')"
                        :class="$style['radiogroup-box']"
                        area-id="widget_icon"
                    >
                        <UiRadiogroup
                            v-model="snapshot.logo_type"
                            label-prop-name="label"
                            size="sm"
                        >
                            <UiRadiogroupOption
                                v-for="logo in logoTypeOptions"
                                :key="logo.value"
                                :value="logo.value"
                                :label="logo.label"
                                size="sm"
                            >
                                <template #icon>
                                    <SvgSprite :name="logo.icon"/>
                                </template>
                            </UiRadiogroupOption>
                        </UiRadiogroup>
                    </UiField>

                    <UiCollapse :expanded="isCustomLogo">
                        <UiRow mt="xs">
                            <UiUploader
                                v-model="widgetLogo"
                                :upload-fn="uploadLogo"
                                inline-restriction
                                auto-upload
                                appearance="button"
                                accept="png"
                                max-file-size="10"
                            >
                                <template #customBtn>
                                    <UiButton
                                        :class="$style['secondary-button']"
                                        variant="secondary"
                                    >
                                        {{ $t('sites.modes.appearance.icon.download') }}
                                    </UiButton>
                                </template>
                            </UiUploader>
                        </UiRow>
                    </UiCollapse>
                </UiRow>

                <UiRow mt="m">
                    <UiField
                        :label="$t('sites.modes.appearance.mode.title')"
                        :class="$style['radiogroup-box']"
                        area-id="widget_mode"
                    >
                        <UiRadiogroup
                            v-model="snapshot.background_color"
                            label-prop-name="label"
                            size="sm"
                        >
                            <UiRadiogroupOption
                                v-for="mode in backgroundModeOptions"
                                :key="mode.value"
                                :value="mode.value"
                                :label="mode.label"
                                size="sm"
                            >
                                <template #icon>
                                    <SvgSprite :name="mode.icon"/>
                                </template>
                            </UiRadiogroupOption>
                        </UiRadiogroup>
                    </UiField>
                </UiRow>
            </ContentSection>
        </UiRow>

        <ContentSection :title="$t('sites.modes.appearance.additionalSetting')">
            <UiRow mt="s">
                <UiField
                    :label="$t('sites.modes.appearance.widget.display')"
                    area-id="appearance_widget_collapse"
                >
                    <UiSelect
                        v-model="snapshot.display_type"
                        :options="displayTypeOptions"
                        :error="errDisplayType"
                        label-prop-name="label"
                        value-prop-name="value"
                    />
                </UiField>

                <UiCollapse :expanded="isDisplayingDelay">
                    <UiRow :class="$style['inline-row']" mt="xs">
                        <UiInputNumber 
                            v-model="snapshot.chat_auto_open_delay"
                            :min="1"
                        /> &nbsp;{{ this.$t('sites.modes.appearance.widget.seconds_short') }}
                    </UiRow>
                    <UiRow :class="$style['select-description']" mt="xs">
                        <i18n path="sites.modes.appearance.widget.delayed_description">
                            <template #accent>
                                <b>{{ $t('sites.modes.appearance.widget.delayed_description_bold', {time: snapshot.chat_auto_open_delay}) }}</b>
                            </template>
                        </i18n>
                    </UiRow>
                </UiCollapse>
            </UiRow>

            <!-- Настройки о печати сообщения пока нету -->
            <!-- <UiRow :class="$style['help-row']" mt="m">
                <UiCheckbox
                    v-model.trim="typing_message"
                    :label="$t('sites.modes.appearance.typing')"
                />
                <UiTooltip placement="top">
                    <template #trigger>
                        <UiIcon :class="$style['help-row__icon']" name="helpIcon" />
                    </template>
                    <div :class="$style['help-row__typing']">
                        <img
                            :src="require('@/assets/images/manager_typing.png')"
                            :class="$style['help-row__image']"
                        />
                        <div :class="$style['help-row__description']">
                            {{ $t('sites.modes.appearance.typing_tooltip') }}
                        </div>
                    </div>
                </UiTooltip>
            </UiRow> -->
            <UiRow mt="m">
                <UiCheckbox
                    :model="!snapshot.hide_on_mobile"
                    :label="$t('sites.modes.appearance.showOnMobile')"
                    @change="snapshot.hide_on_mobile = !$event"
                />
            </UiRow>

            <UiRow mt="34">
                <UiText size="body" accent>
                    {{ $t('sites.modes.appearance.showOperator') }}
                </UiText>

                <UiRow mt="xs">
                    <UiRadio
                        v-model="snapshot.display_manager_type"
                        :label="$t('sites.modes.appearance.operatorManager.label')"
                        :desc="$t('sites.modes.appearance.operatorManager.descript')"
                        :value="DISPLAY_MANAGER_TYPE.STANDART"
                    />
                </UiRow>

                <UiRow mt="xs">
                    <UiRadio
                        v-model="snapshot.display_manager_type"
                        :label="$t('sites.modes.appearance.operatorCommon.label')"
                        :desc="$t('sites.modes.appearance.operatorCommon.descript')"
                        :value="DISPLAY_MANAGER_TYPE.CUSTOM"
                    />

                    <UiCollapse :expanded="snapshot.display_manager_type === DISPLAY_MANAGER_TYPE.CUSTOM">
                        <UiRow :class="$style['manager-name']" mt="s">
                            <div :class="$style['manager-name__upload']" >
                                <UiUploader
                                    v-model="managerAvatar"
                                    :upload-fn="uploadManagerAvatar"
                                    inline-restriction
                                    auto-upload
                                    appearance="button"
                                >
                                    <template #customBtn>
                                        <UiButton
                                            :class="[
                                                $style['secondary-button'],
                                                $style['secondary-button_manager']
                                            ]"
                                            variant="secondary"
                                        >
                                            <SvgSprite name="upload_file" />
                                            {{ $t('sites.modes.appearance.fileDownload') }}
                                        </UiButton>
                                    </template>
                                </UiUploader>
                            </div>

                            <UiInput
                                v-model="snapshot.manager_name"
                                :placeholder="$t('sites.modes.appearance.managerName')"
                                :class="$style['manager-name__name']"
                            />
                        </UiRow>
                    </UiCollapse>
                </UiRow>
            </UiRow>

            <UiRow mt="m">
                <UiText size="body" accent>
                    {{ $t('sites.modes.common.form.edgePageOffset.label') }}
                </UiText>

                <UiRow
                    :class="$style.row"
                    mt="xs"
                >
                    <UiField
                        :class="$style.row__col"
                        :label="offsetSideLabel"
                        area-id="common_offset_side"
                    >
                        <div :class="$style.row">
                            <UiInputNumber
                                v-model.number="snapshot.offset.side"
                                :class="$style['input-number']"
                                :error="errOffsetSide"
                                id="common_offset_side"
                                @keyup.enter.native="save"
                            />

                            <UiText :class="$style.row__caption" size="body">
                                px
                            </UiText>
                        </div>
                    </UiField>

                    <UiField
                        :class="$style.row__col"
                        :label="$t('sites.modes.common.form.edgePageOffset.fromBottom')"
                        area-id="common_offset_bottom"
                    >
                        <div :class="$style.row">
                            <UiInputNumber
                                v-model.number="snapshot.offset.bottom"
                                :class="$style['input-number']"
                                :error="errOffsetBottom"
                                id="common_offset_bottom"
                                @keyup.enter.native="save"
                            />

                            <UiText :class="$style.row__caption" size="body">
                                px
                            </UiText>
                        </div>
                    </UiField>

                    <UiField
                        :class="$style.row__col"
                        :label="$t('sites.modes.common.form.chat_position.label')"
                        area-id="common_offset_bottom"
                    >
                        <UiRadiogroup
                            v-model="snapshot.chat_position"
                            :options="chatPositionsOptions"
                            label-prop-name="label"
                            size="sm"
                        />
                    </UiField>
                </UiRow>
            </UiRow>
        </ContentSection>

        <template #preview>
            <ConsultantPreview
                v-model="opened"
                :position="snapshot.chat_position"
                :tooltip-text="snapshot.tooltip_text"
                :name="snapshot.title || site.name"
                :main-color="snapshot.elements_color ? '#' + snapshot.elements_color : '#005EEB'"
                :manager="currentManager"
                :display-manager-type="snapshot.display_manager_type"
                :rounded="isRounded"
                :logo-link="snapshot.logo_type === 'custom' ? snapshot.logo_link : null"
                :theme="snapshot.background_color"
                :inversion-color="snapshot.inversion_colors"
                :crm-url="crm.url"
                :messengers-links="snapshot.links"
                :always-show-messengers="snapshot.always_show_links_on_desktops"
                :offset="snapshot.offset"
                :icons-color="snapshot.links_mode"
                :direction="snapshot.direction_messengers"
            />
        </template>
    </ContentBox>

</template>

<script>
    import axios from 'axios'
    import {vErrText} from '../../main'
    import {maxLength, minValue, required} from 'vuelidate/lib/validators'
    import {boolCheck} from '../../main'
    import ContentBox from '../common/ContentBox'
    import ContentSection from '../common/ContentSection'
    import { SHAPE, LOGO_TYPE, BACKGROUND_MODE, DISPLAY_TYPE, DISPLAY_MANAGER_TYPE } from '@/enum/appearance.ts'
    import { VARIANT as BUTTON_VARIANT } from '@omnica/button'
    import ConsultantPreview from '../common/ConsultantPreview.vue'
    import modeMixin from './mixins/modeMixin.js'
    import currentManagerMixin from './mixins/currentManagerMixin.js'
    import clone from '@/utils/clone'

    const chatPositionKeys = ['right-bottom', 'left-bottom']

    const chatPositionVariants = (value) => chatPositionKeys.indexOf(value) >= 0
    const hexColor = (value) => value.match(/^[0-9a-fA-F]+$/)

    export default {
        components: {
            ContentBox,
            ContentSection,
            ConsultantPreview
        },

        mixins: [ modeMixin, currentManagerMixin ],

        props: {
            /**
             * @type crm
             */
            crm: {
                type: Object,
                required: true
            }
        },
        data () {
            return {
                btnClicks: 0,
                dragging: false,
                isLoading: false,
                opened: true,
                widgetLogo: this.getDefaultImage(this.site.logo_link),
                managerAvatar: this.getDefaultImage(this.site.manager_logo),
            }
        },
        validations: {
            snapshot: {
                title: {
                    required,
                    maxLength: maxLength(100)
                },

                offset: {
                    side: {
                        required,
                        minValue: minValue(0)
                    },
                    bottom: {
                        required,
                        minValue: minValue(0)
                    }
                },
                chat_position: {
                    required,
                    chatPositionVariants
                },
                hide_on_mobile: {
                    required,
                    boolCheck
                },
                inversion_colors: {
                    required,
                    boolCheck
                },
                display_type: {
                    required,
                    availableDisplayType (value) {
                        return Object.values(DISPLAY_TYPE).includes(value)
                    }
                }
            },
            backgroundColor: {
              required,
              minLength: maxLength(6),
              maxLength: maxLength(6),
              hexColor
            },
        },
        computed: {
            BUTTON_VARIANT: () => BUTTON_VARIANT,
            DISPLAY_MANAGER_TYPE: () => DISPLAY_MANAGER_TYPE,

            colors: {
                get: function () {
                    return '#' + this.backgroundColor
                },
                set: function (value) {
                    this.snapshot.elements_color = value.substring(1).toLowerCase()
                }
            },

            predefinedColors () {
                return [
                    '4CD6A8',
                    '4191FF',
                    '8453DF',
                    'FF6A68',
                    'FEA530'
                ]
            },

            backgroundColor() {
              return this.snapshot.elements_color
                  ? this.snapshot.elements_color
                  : '005EEB'
            },

            shapeTypeOptions () {
                return [
                    {
                        label: this.$t('sites.modes.appearance.elements.rounded'),
                        value: SHAPE.ROUNDED,
                        icon: 'rounded_corners'
                    },
                    {
                        label: this.$t('sites.modes.appearance.elements.straight'),
                        value: SHAPE.STRAIGHT,
                        icon: 'straight_corners'
                    }
                ]
            },

            logoTypeOptions () {
                return [
                    {
                        label: this.$t('sites.modes.appearance.icon.standart'),
                        value: LOGO_TYPE.STANDART,
                        icon: 'comment_text'
                    },
                    {
                        label: this.$t('sites.modes.appearance.icon.custom'),
                        value: LOGO_TYPE.CUSTOM,
                        icon: 'upload_file'
                    }
                ]
            },

            displayTypeOptions () {
                return [
                    {
                        label: this.$t('sites.modes.appearance.widget.collapsed'),
                        value: DISPLAY_TYPE.CLOSE
                    },
                    {
                        label: this.$t('sites.modes.appearance.widget.expanded'),
                        value: DISPLAY_TYPE.OPEN
                    },
                    {
                        label: this.$t('sites.modes.appearance.widget.delayed'),
                        value: DISPLAY_TYPE.DISPLAYING_DELAY
                    }
                ]
            },

            backgroundModeOptions () {
                return [
                    {
                        label: this.$t('sites.modes.appearance.mode.light'),
                        value: BACKGROUND_MODE.LIGHT,
                        icon: 'brightness_outlined'
                    },
                    {
                        label: this.$t('sites.modes.appearance.mode.dark'),
                        value: BACKGROUND_MODE.DARK,
                        icon: 'moon_vs_outlined'
                    }
                ]
            },

            isRounded () {
                return this.snapshot.element_shape_type === SHAPE.ROUNDED
            },

            isCustomLogo () {
                return this.snapshot.logo_type === LOGO_TYPE.CUSTOM
            },

            isDisplayingDelay () {
                return this.snapshot.display_type === DISPLAY_TYPE.DISPLAYING_DELAY
            },

            chatPositionsOptions () {
                const opts = []
                chatPositionKeys.forEach((p) => {
                    opts.push({
                        value: p,
                        label: this.$t('sites.modes.common.form.chat_position.values.' + p)
                    })
                })

                return opts.reverse()
            },

            locale () {
                return {
                    en: 'en-GB',
                    es: 'es-ES',
                    ru: 'ru-RU',
                }[this.$i18n.locale] ?? 'en-EN'
            },

            offsetSideLabel () {
                if (this.snapshot.chat_position === 'right-bottom') {
                    return this.$t('sites.modes.common.form.edgePageOffset.fromRight')
                }

                return this.$t('sites.modes.common.form.edgePageOffset.fromLeft')
            },

            errTitle () { return this.vErrText(this.$v.snapshot.title) },
            errOffsetSide () { return this.vErrText(this.$v.snapshot.offset.side) },
            errOffsetBottom () { return this.vErrText(this.$v.snapshot.offset.bottom) },
            errChatPosition () { return this.vErrText(this.$v.snapshot.chat_position, ['chatPositionVariants']) },
            errHideOnMobile () { return this.vErrText(this.$v.snapshot.hide_on_mobile, ['boolCheck']) },
            errInversionColors () { return this.vErrText(this.$v.snapshot.inversion_colors, ['boolCheck']) },
            errDisplayType () { return this.vErrText(this.$v.snapshot.display_type, ['availableDisplayType'])}
        },

        methods: {
            vErrText(v, additionalValidators) {
                if (!this.btnClicks) {
                    return false
                }

                return vErrText(v, additionalValidators)
            },

            save () {
                this.btnClicks++
                if (this.$v.$invalid) {
                    return
                }

                this.isLoading = true

                this.$emit('save', {
                    site: clone(this.snapshot),
                    callback: () => {
                        this.isLoading = false
                    }
                })
            },

            uploadLogo (image, onProgress) {
                onProgress(0)

                return new Promise((resolve, reject) => {
                    axios.post(`sites/${this.snapshot.id}/upload/widget-logo`, image, {
                        onUploadProgress: (event) => {
                            onProgress(Math.round((event.loaded * 100) / event.total))
                        }
                    })
                        .then((response) => {
                            if (response.data && response.data.url) {
                                this.snapshot.logo_link = response.data.url
                            }

                            onProgress(100)
                            resolve()
                        })
                        .catch(() => {
                            reject()
                        })
                })
            },

            uploadManagerAvatar (image, onProgress) {
                onProgress(0)

                return new Promise((resolve, reject) => {
                    axios.post(`sites/${this.snapshot.id}/upload/manager-logo`, image, {
                        onUploadProgress: (event) => {
                            onProgress(Math.round((event.loaded * 100) / event.total))
                        }
                    })
                        .then((response) => {
                            if (response.data && response.data.url) {
                                this.snapshot.manager_logo = response.data.url
                            }

                            onProgress(100)
                            resolve()
                        })
                        .catch(() => {
                            reject()
                        })
                })
            },

            getDefaultImage (imageUrl) {
                if (!imageUrl) {
                    return []
                }

                return [{
                    id: `${this.site.id}-${imageUrl}`,
                    link: imageUrl,
                    thumb: imageUrl,
                    extension: imageUrl.split('.')[1]
                }]
            }
        },
    }
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.row {
    display: flex;
    align-items: center;

    &__caption {
        margin-left: @spacing-xs;
    }

    &__col {
        &:not(:last-child) {
            margin-right: @spacing-l;
        }
    }
}

.links {
    margin-left: -22px;
}

.social {
    margin-bottom: @spacing-xs;
    display: flex;

    &__select {
        margin-right: @spacing-xs;
        width: 160px;
        flex-shrink: 0;
    }

    &__wrap {
        margin-right: @spacing-xs;
        display: flex;
        flex-grow: 1;
    }

    &__input {
        flex-grow: 1;
    }

    &__telegram {
        margin-right: @spacing-xs;
        width: 120px;
    }
}

.trigger {
    position: relative;
    width: 20px;
    height: 20px;
    align-self: center;

    &__icon {
        visibility: hidden;
        padding: 0px;
        margin: 0px;
        width: 100%;
        height: 100%;
        background-image: url("data:image/svg+xml,%3Csvg fill='%23AFB9C3' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9 8C10.1046 8 11 7.10457 11 6C11 4.89543 10.1046 4 9 4C7.89543 4 7 4.89543 7 6C7 7.10457 7.89543 8 9 8ZM7 12C7 10.8954 7.89543 10 9 10C10.1046 10 11 10.8954 11 12C11 13.1046 10.1046 14 9 14C7.89543 14 7 13.1046 7 12ZM9 16C7.89543 16 7 16.8954 7 18C7 19.1046 7.89543 20 9 20C10.1046 20 11 19.1046 11 18C11 16.8954 10.1046 16 9 16ZM17 6C17 7.10457 16.1046 8 15 8C13.8954 8 13 7.10457 13 6C13 4.89543 13.8954 4 15 4C16.1046 4 17 4.89543 17 6ZM15 10C13.8954 10 13 10.8954 13 12C13 13.1046 13.8954 14 15 14C16.1046 14 17 13.1046 17 12C17 10.8954 16.1046 10 15 10ZM13 18C13 16.8954 13.8954 16 15 16C16.1046 16 17 16.8954 17 18C17 19.1046 16.1046 20 15 20C13.8954 20 13 19.1046 13 18Z'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;
        cursor: move;
    }
}

.input-number {
    & > div > div {
        width: 52px;
    }
}

.show-links {
    & > label > span:nth-child(2) {
        position: relative;
        top: -2px;
        padding-top: 0;

        & > span {
            line-height: (24 / 16);
        }
    }
}

.color-list {
    display: flex;

    &__item {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-left: 16px;
        cursor: pointer;
        transition: .2s linear;

        &:hover {
            opacity: .8;
        }
    }
}

.pick-colors {
    display: flex;
    align-items: center;
}

.help-row {
    display: flex;
    align-items: center;

    & &__icon {
        width: 20px;
        height: 20px;
        margin-left: @spacing-xs;
        fill: @grey-700;
        transition: .2s linear;

        &:hover {
            fill: @blue-500;
        }
    }

    &__tooltip {
        max-width: 160px;
    }

    &__typing {
        max-width: 480px;
        margin: -@spacing-s;
    }

    &__image {
        width: 480px;
        height: 284px;
        border-radius: 8px 8px 0 0;
    }

    &__description {
        padding: @spacing-s @spacing-m;
        margin-top: -@spacing-xxs;
    }
}

.radiogroup-box {
    max-width: 320px;
}

.inline-row {
    display: flex;
    align-items: center;

    &__item {
        margin-left: @spacing-s;
    }
}

.manager-name {
    display: flex;
    align-items: center;

    &__upload {
        min-width: 248px;
        padding-left: @spacing-l;
        padding-right: @spacing-s;

        button {
            min-width: 200px;
        }
    }

    &__name {
        flex: 1;
        min-width: 0;
        align-self: flex-start;
    }
}

.select-description {
    max-width: 352px;
    color: @grey-900;
    font-size: 14px;
    line-height: 20px;
}

:global(.omnica-button).secondary-button {
    color: @black-500;

    &_manager {
        margin-right: -@spacing-s;
    }
}

:global(#retailcrm-consultant-app) .messages {
    flex: 1 0 1px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: none;
    max-height: 100%;
    padding-top: 16px;
}

:global(.omnica-color-picker__alpha) {
    display: none;
}
</style>

<style lang="less">
#retailcrm-consultant-app {
    .form__area {

        &, * svg {
            transition: box-shadow .2s linear !important;
        }
    }

    .consultant-button_send {
        transition: none !important;
    }
}
</style>
