<template>
    <ContentBox :loading="isLoading" preview @save="save">
        <template #title>
            {{ $t('sites.modes.contact.title') }}
        </template>

        <UiSwitch
            v-model="snapshot.use_contact_form"
            :label="$t('sites.modes.contact.form.use_contact_form')"
            :desc="$t('sites.modes.contact.description')"
            :error="errUse"
        />

        <UiRow v-show="snapshot.use_contact_form" mt="s">
            <UiRow mt="m" mb="m">
                <UiField
                    :label="$t('sites.modes.contact.form.form_display.title')"
                    area-id="contact_form_display"
                >
                    <div :class="[
                        $style['row'],
                        $style['radio-group']
                    ]">
                        <UiRadio
                            v-model="snapshot.contact_form_display"
                            v-for="option in formDisplayOptions"
                            :key="option.value"
                            :value="option.value"
                            :label="option.label"
                            focused
                            :class="$style['radio-form-display']"
                        />
                    </div>
                </UiField>
            </UiRow>

            <UiRow mb="m">
                <UiField
                    :label="$t('sites.modes.contact.form.text')"
                    area-id="contact_text"
                >
                    <UiTextarea
                        v-model.trim="snapshot.contact_form_text"
                        :error="errText"
                        :placeholder="$t('sites.modes.contact.form.text_placeholder')"
                        id="contact_text"
                        emoji
                        @keyup.enter.native="save"
                    />
                </UiField>
            </UiRow>

            <UiField :label="$t('sites.modes.contact.form.fields')">
                <div
                    v-for="field in fields"
                    :key="field"
                    :class="$style.row"
                >

                    <div :class="$style.row__field">
                        <UiSwitch
                            v-model="snapshot['contact_form_' + field]"
                            :disabled="field === 'personalData'"
                        >
                            <span :class="[
                                $style['form-fields__label'],
                                {[$style['form-fields__label_offset']]: field === 'personalData'}
                            ]">
                                {{ $t('sites.modes.offline.form.' + field) }}
                            </span>
                        </UiSwitch>

                        <UiTooltip
                            placement="right"
                            v-if="field === 'personalData'"
                            :class="$style['form-fields__hint-wrap']"
                        >
                            <template #trigger>
                                <SvgSprite :class="$style['form-fields__hint-icon']" name="info_outlined" />
                            </template>

                            <div :class="$style['form-fields__hint-tooltip']">
                                {{ $t('sites.modes.offline.form.tooltip.consent') }}
                            </div>
                        </UiTooltip>
                    </div>

                    <UiSelect
                        :disabled="!snapshot['contact_form_' + field]"
                        placement="bottom-start"
                        max-width-dropdown
                    >
                        <template #target="selectData">
                            <UiButton
                                :locked="!snapshot['contact_form_' + field] || field === 'personalData'"
                                :active="selectData.data.active"
                                appearance="tertiary"
                            >
                                {{ snapshot['contact_form_' + field + '_required'] ? $t('common.required') : $t('common.not_required') }}

                                <UiIcon name="caretDown" />
                            </UiButton>
                        </template>

                        <UiOption
                            :active="snapshot['contact_form_' + field + '_required'] === true"
                            :label="$t('common.required')"
                            value="required"
                            @click="snapshot['contact_form_' + field + '_required'] = true"
                        />

                        <UiOption
                            :active="snapshot['contact_form_' + field + '_required'] === false"
                            :label="$t('common.not_required')"
                            value="notRequired"
                            @click="snapshot['contact_form_' + field + '_required'] = false"
                        />
                    </UiSelect>
                </div>
            </UiField>
        </UiRow>

        <template v-if="snapshot.use_contact_form" #preview>
            <ConsultantPreview
                v-model="opened"
                :position="snapshot.chat_position"
                :tooltip-text="snapshot.tooltip_text"
                :name="snapshot.title"
                :main-color="snapshot.elements_color ? '#' + snapshot.elements_color : '#005EEB'"
                :manager="currentManager"
                :display-manager-type="snapshot.display_manager_type"
                :rounded="isRounded"
                :logo-link="snapshot.logo_type === 'custom' ? snapshot.logo_link : null"
                :theme="snapshot.background_color"
                :inversion-color="snapshot.inversion_colors"
                :messengers-links="snapshot.links"
                :offset="snapshot.offset"
                :crm-url="crm.url"
                :messages="[]"
                :icons-color="snapshot.links_mode"
                :direction="snapshot.direction_messengers"
                :contact-form-display="snapshot.contact_form_display"
                :is-first-message-set="true"
                :need-contact-form="true"
            >
                <ConsultantMessage
                    v-if="snapshot.contact_form_display === FORM_DISPLAY.AFTER_FIRST_MESSAGE"
                    :message="clientDemoMessage"
                />
                <ConsultantMessage :message="demoMessage" default-avatar>
                    <ContactForm
                        v-if="Object.keys(demoFormConfig).length"
                        :config="demoFormConfig"
                        :handler="() => {}"
                        :personal-data-consent="snapshot.contact_form_personalData"
                        :personal-data-link="snapshot.processing_personal_data_link"
                        form-state="start"
                    />
                </ConsultantMessage>
            </ConsultantPreview>
        </template>
    </ContentBox>
</template>

<script>
    import {vErrText} from '../../main'
    import {maxLength, required} from 'vuelidate/lib/validators'
    import {boolCheck} from '../../main'
    import ContentBox from '../common/ContentBox'
    import ConsultantPreview from '../common/ConsultantPreview.vue'
    import modeMixin from './mixins/modeMixin.js'
    import currentManagerMixin from './mixins/currentManagerMixin.js'
    import clone from '@/utils/clone'
    import previewMessage from '@/utils/previewMessage.js'
    import { SHAPE, FORM_DISPLAY } from '@/enum/appearance.ts'

    export default {
        components: {
            ContentBox,
            ConsultantPreview
        },

        mixins: [modeMixin, currentManagerMixin],

        props: {
            /**
             * @type crm
             */
            crm: {
                type: Object,
                required: true
            }
        },
        data () {
            return {
                fields: ['name', 'email', 'phone', 'personalData'],
                btnClicks: 0,
                isLoading: false,
                opened: true,
            }
        },
        validations () {
            const v = {
                snapshot: {
                    use_contact_form: {
                        required,
                        boolCheck
                    },
                    contact_form_display: {
                        required
                    }
                },
            }

            if (this.snapshot.use_contact_form) {
                v.snapshot.use_contact_form.atLeastOneFieldRequired = (value) => {
                    return !value || (
                        this.snapshot.contact_form_name_required ||
                        this.snapshot.contact_form_email_required ||
                        this.snapshot.contact_form_phone_required
                    )
                }

                v.snapshot.contact_form_text = { maxLength: maxLength(1000) }
                v.snapshot.contact_form_name = { boolCheck }
                v.snapshot.contact_form_name_required = { boolCheck }
                v.snapshot.contact_form_email = { boolCheck }
                v.snapshot.contact_form_email_required = { boolCheck }
                v.snapshot.contact_form_phone = { boolCheck }
                v.snapshot.contact_form_phone_required = { boolCheck }
            }

            return v
        },
        computed: {
            FORM_DISPLAY: () => FORM_DISPLAY,

            demoMessage () {
                return previewMessage(this.snapshot.contact_form_text, 'system')
            },

            clientDemoMessage () {
                return {
                    "id": "1",
                    "content": this.$t('messages.answer'),
                    "type": "text",
                    "fromMe": true,
                    "status": "seen",
                    "time": "2023-11-15T14:15:15",
                    "attachments": null
                }
            },

            demoFormConfig () {
                const config = {}

                if (this.snapshot.contact_form_name) {
                    config.name = {required: this.snapshot.contact_form_name_required}
                }

                if (this.snapshot.contact_form_email) {
                    config.email = {required: this.snapshot.contact_form_email_required}
                }

                if (this.snapshot.contact_form_phone) {
                    config.phone = {required: this.snapshot.contact_form_phone_required}
                }

                if (this.snapshot.contact_form_personalData) {
                    config.personalData = {required: this.snapshot.contact_form_personalData_required}
                }

                return config
            },

            isRounded () {
                return this.snapshot.element_shape_type === SHAPE.ROUNDED
            },


            formDisplayOptions () {
                return [
                    {
                        label: this.$t('sites.modes.contact.form.form_display.at_beginning_dialogue'),
                        value: FORM_DISPLAY.AT_BEGINNING_DIALOGUE,
                    },
                    {
                        label: this.$t('sites.modes.contact.form.form_display.after_first_message'),
                        value: FORM_DISPLAY.AFTER_FIRST_MESSAGE,
                    }
                ]
            },

            errUse () {
                if (!this.btnClicks) {
                    return false
                }

                if (false === this.$v.snapshot.use_contact_form.atLeastOneFieldRequired) {
                    return this.$t('sites.modes.contact.form.at_least_one_required')
                }

                return this.vErrText(this.$v.snapshot.use_offline_mode, ['boolCheck'])
            },
            errText () { return this.vErrText(this.$v.snapshot.offline_text) },
            errFormName  () { return this.vErrText(this.$v.snapshot.offline_form_name) },
            errFormNameRequired  () { return this.vErrText(this.$v.snapshot.offline_form_name_required) },
            errFormPhone  () { return this.vErrText(this.$v.snapshot.offline_form_phone) },
            errFormPhoneRequired  () { return this.vErrText(this.$v.snapshot.offline_form_phone_required) },
            errFormEmail  () { return this.vErrText(this.$v.snapshot.offline_form_email) },
            errFormEmailRequired  () { return this.vErrText(this.$v.snapshot.offline_form_email_required) },
            errFormPersonalData  () { return this.vErrText(this.$v.snapshot.offline_form_personalData) },
            errFormPersonalDataRequired  () { return this.vErrText(this.$v.snapshot.offline_form_personalData_required) },
        },
        methods: {
            unselectRequiredFieldIfNeeded (fieldName) {
                if (!this.snapshot[fieldName] && this.snapshot[fieldName + '_required']) {
                    this.snapshot[fieldName + '_required'] = false
                }
            },
            vErrText(v, additionalValidators) {
                if (!this.btnClicks) {
                    return false
                }

                return vErrText(v, additionalValidators)
            },
            save () {
                this.btnClicks++

                if (this.$v.$invalid) {
                    return
                }

                this.isLoading = true

                this.$emit('save', {
                    site: clone(this.snapshot),
                    callback: () => {
                        this.isLoading = false
                    }
                })
            },
        },
        watch: {
            'snapshot.contact_form_name': function () { this.unselectRequiredFieldIfNeeded('contact_form_name') },
            'snapshot.contact_form_email': function () { this.unselectRequiredFieldIfNeeded('contact_form_email') },
            'snapshot.contact_form_phone': function () { this.unselectRequiredFieldIfNeeded('contact_form_phone') },
        },
    }
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.row {
    display: flex;

    &:not(:last-child) {
        margin-bottom: @spacing-s;
    }

    &__field {
        display: flex;
        align-items: center;
        width: 250px;
        padding-top: 6px;
        padding-bottom: 6px;
        height: fit-content;
    }
}

.form-fields {
    &__label {
        white-space: normal;

        &_offset {
            cursor: default;
        }
    }

    &__hint-icon {
        width: @spacing-s;
        height: @spacing-s;
        fill: @grey-700;
        color: @grey-700;

        &:hover {
            fill: @blue-500;
            color: @blue-500;
        }
    }

    &__hint-wrap {
        align-self: end;
    }

    &__hint-tooltip {
        width: 192px;
        height: auto;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.15px;
    }
}

.radio-form-display {
    width: fit-content;
    margin-right: @spacing-s;
}

.radio-group {
    margin-top: 12px;
}
</style>
