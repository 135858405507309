<template>
    <button
        :class="{
            [$style['menu-item']]: true,
            [$style['menu-item_active']]: active,
        }"
        type="button"
        @click="$emit('click')"
    >
        <span :class="$style['menu-item__icon']">
            <slot name="icon" />
        </span>

        <UiText
            :class="$style['menu-item__text']"
            size="body"
            tag="span"
        >
            <slot>
                {{ label }}
            </slot>
        </UiText>
    </button>
</template>

<script>
export default {
    name: 'MenuItem',

    props: {
        active: {
            type: Boolean,
            default: false
        },

        label: {
            type: String,
            default: ''
        },
    }
}
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.menu-item {
    margin: 0;
    padding: 0 (@spacing-m / 2);
    display: flex;
    align-items: center;
    border: none;
    border-radius: @border-radius-s;
    cursor: pointer;
    background: 0 0;
    width: 100%;
    height: 48px;
    fill: @grey-600;
    transition: @transition;

    &_active {
        fill: #fff;
        color: #fff;
        background-color: @blue-500;
        box-shadow: @drop-shadow-s;
    }

    &__text {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: inherit !important;
    }

    &__icon {
        margin-right: @spacing-xs;
        width: 24px !important;
        height: 24px !important;
        fill: inherit;

        svg {
            width: 100%;
            height: 100%;
            fill: inherit;
        }
    }
}
</style>
