<template>
    <ContentBox
        :loading="isLoading"
        preview
        @save="save"
    >
        <template #title>
            {{ $t('sites.modes.minimized.title') }}
        </template>

            <UiRow>
                <UiField
                    :label="$t('sites.modes.minimized.form.tooltip_text')"
                    area-id="minimized_tooltip_text"
                >
                    <UiTextarea
                        v-model.trim="snapshot.tooltip_text"
                        :error="errTooltipText"
                        id="minimized_tooltip_text"
                        @keyup.enter.native="save"
                    />
                </UiField>
            </UiRow>

            <UiRow mt="s">
                <UiField
                    :label="$t('sites.modes.minimized.form.displaying_delay')"
                    area-id="minimized_displaying_delay"
                >
                    <InputDuration
                        v-model="snapshot.displaying_delay"
                        :error="errDisplayingDelay"
                        id="minimized_displaying_delay"
                        @keyup.enter.native="save"
                    />
                </UiField>
            </UiRow>
        
        <template #preview>
            <ConsultantPreview
                v-model="opened"
                :position="snapshot.chat_position"
                :tooltip-text="snapshot.tooltip_text"
                :name="snapshot.title || site.name"
                :main-color="snapshot.elements_color ? '#' + snapshot.elements_color : '#005EEB'"
                :manager="currentManager"
                :display-manager-type="snapshot.display_manager_type"
                :rounded="isRounded"
                :logo-link="snapshot.logo_type === 'custom' ? snapshot.logo_link : null"
                :theme="snapshot.background_color"
                :inversion-color="snapshot.inversion_colors"
                :messengers-links="snapshot.links"
                :offset="snapshot.offset"
                :crm-url="crm.url"
                :icons-color="snapshot.links_mode"
                :direction="snapshot.direction_messengers"
            />
        </template>
    </ContentBox>
</template>

<script>
    import ContentBox from '../common/ContentBox'
    import ConsultantPreview from '../common/ConsultantPreview.vue'
    import {vErrText} from "../../main"
    import {maxLength, minValue, maxValue} from 'vuelidate/lib/validators'
    import { VARIANT } from '@omnica/button'
    import { SHAPE } from '@/enum/appearance.ts'
    import InputDuration from "../common/InputDuration"
    import modeMixin from './mixins/modeMixin.js'
    import currentManagerMixin from './mixins/currentManagerMixin.js'
    import clone from '@/utils/clone'

    export default {
        components: {
            ContentBox,
            InputDuration,
            ConsultantPreview
        },

        mixins: [modeMixin, currentManagerMixin],

        props: {
            /**
             * @type crm
             */
            crm: {
                type: Object,
                required: true
            }
        },
        data () {
            return {
                state: VARIANT.DEFAULT,
                btnClicks: 0,
                isLoading: false,
                opened: false
            }
        },
        validations: {
            snapshot: {
                tooltip_text: {
                    maxLength: maxLength(255)
                },
                displaying_delay: {
                    minValue: minValue(0),
                    maxValue: maxValue(600)
                },
            }
        },
        computed: {
            isRounded () {
                return this.snapshot.element_shape_type === SHAPE.ROUNDED
            },
            errTooltipText () { return this.vErrText(this.$v.snapshot.tooltip_text) },
            errDisplayingDelay () { return this.vErrText(this.$v.snapshot.displaying_delay) },
        },
        methods: {
            vErrText(v, additionalValidators) {
                if (!this.btnClicks) {
                    return false
                }

                return vErrText(v, additionalValidators)
            },
            save () {
                this.btnClicks++
                if (this.$v.$invalid) {
                    return
                }

                this.isLoading = true

                this.$emit('save', {
                    site: clone(this.snapshot),
                    callback: () => {
                        this.isLoading = false
                    }
                })
            },
        }
    }
</script>

