import uuid4 from './uuid4'

export const SYSTEM_NAME = 'system'

export default (content = '', userName = '', isFollow = false, suggestions = []) => {
    const isSystem = userName === SYSTEM_NAME

    return new Object({
        id: isSystem ? '_offline' : uuid4(),
        status: 'seen',
        type: 'text',
        time: new Date(),
        content,
        isFollow,
        fromMe: !userName,
        system: isSystem,
        user: {
            first_name: isSystem ? '' : userName,
            last_name: '',
            avatar: require(`@/assets/images/avatar.jpg`)
        },
        attachments: {suggestions: suggestions},
    })
}
