<template>
    <ContentBox :save="false">
        <template #title>
            {{ $t('sites.modes.insert.title') }}
        </template>

        <div class="code">
            <ol>
                <li class="li1"><div class="de1"><span class="sy0">&lt;</span>script<span class="sy0">&gt;</span></div></li>
                <li class="li1"><div class="de1">&nbsp; <span class="kw1">var</span> _rcct <span class="sy0">=</span> <span class="st0">"{{ site.token }}"</span><span class="sy0">;</span></div></li>
                <li class="li1"><div class="de1">&nbsp; <span class="sy0">!</span><span class="kw1">function</span> <span class="br0">(</span>t<span class="br0">)</span> <span class="br0">{</span></div></li>
                <li class="li1"><div class="de1">&nbsp; &nbsp; &nbsp; <span class="kw1">var</span> a <span class="sy0">=</span> t.<span class="me1">getElementsByTagName</span><span class="br0">(</span><span class="st0">"head"</span><span class="br0">)</span><span class="br0">[</span><span class="nu0">0</span><span class="br0">]</span><span class="sy0">;</span></div></li>
                <li class="li1"><div class="de1">&nbsp; &nbsp; &nbsp; <span class="kw1">var</span> c <span class="sy0">=</span> t.<span class="me1">createElement</span><span class="br0">(</span><span class="st0">"script"</span><span class="br0">)</span><span class="sy0">;</span></div></li>
                <li class="li1"><div class="de1">&nbsp; &nbsp; &nbsp; c.<span class="me1">type</span> <span class="sy0">=</span> <span class="st0">"text/javascript"</span><span class="sy0">;</span></div></li>
                <li class="li1"><div class="de1">&nbsp; &nbsp; &nbsp; c.<span class="me1">src</span> <span class="sy0">=</span> <span class="st0">"{{ host }}/widget/loader.js"</span><span class="sy0">;</span></div></li>
                <li class="li1"><div class="de1">&nbsp; &nbsp; &nbsp; a.<span class="me1">appendChild</span><span class="br0">(</span>c<span class="br0">)</span><span class="sy0">;</span></div></li>
                <li class="li1"><div class="de1">&nbsp; <span class="br0">}</span> <span class="br0">(</span>document<span class="br0">)</span><span class="sy0">;</span></div></li>
                <li class="li1"><div class="de1"><span class="sy0">&lt;/</span>script<span class="sy0">&gt;</span></div></li>
            </ol>
        </div>

        <UiRow mt="s">
            <UiCopyBtn
                :copyText="code"
                :copy-tooltip-text="$t('sites.modes.insert.copy')"
                :copied-tooltip-text="$t('sites.modes.insert.copied')"
            >
                <template #copyTrigger>
                    <UiButton
                        appearance="secondary"
                        size="md"
                    >
                        <UiIcon name="copy20"/>
                        {{ $t('sites.modes.insert.copy_btn') }}
                    </UiButton>
                </template>
            </UiCopyBtn>

            <pre class="hidden" ref="code">
                &lt;script&gt;
                var _rcct = "{{ site.token }}";
                !function (t) {
                    var a = t.getElementsByTagName("head")[0];
                    var c = t.createElement("script");
                    c.type = "text/javascript";
                    c.src = "{{ host }}/widget/loader.js";
                    a.appendChild(c);
                } (document);
                &lt;/script&gt;
            </pre>
        </UiRow>
    </ContentBox>
</template>

<script>
    import ContentBox from '../common/ContentBox'

    export default {
        components: {
            ContentBox
        },

        props: {
            /**
             * @type site
             */
            site: {
                type: Object,
                required: true
            },
            /**
             * @type crm
             */
            crm: {
                type: Object,
                required: true
            }
        },
        computed: {
            host () {
                return this.$root.host
            },
            code () {
                /* eslint-disable no-useless-escape */
                return `
                    <script>
                        var _rcct = "${this.site.token}";
                        !function (t) {
                            var a = t.getElementsByTagName("head")[0];
                            var c = t.createElement("script");
                            c.type = "text/javascript";
                            c.src = "${this.host}/widget/loader.js";
                            a.appendChild(c);
                        } (document);
                    <\/script>
                `
            }
        }
    }
</script>

<style scoped>
    .code {
        padding: 0 13px;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
    }

    ol {
        display: block;
        list-style-type: none;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 0;
    }

    .li1 {
        font-family: 'Courier New', Courier, monospace;
        font-size: 12px;
        color: #000060;
        padding-bottom: 0;
    }

    .de1 {
        color: #000060;
        font-weight: normal;
    }

    .sy0 {
        color: #339933;
    }

    .kw1 {
        color: #000066;
        font-weight: bold;
    }

    .br0 {
        color: #009900;
    }

    .me1 {
        color: #660066;
    }

    .nu0 {
        color: #CC0000;
    }

    .st0 {
        color: #3366CC;
    }

    .hidden {
        display: none;
    }
</style>
