var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style['preview-box'],style:(`
        width: ${350 + (_vm.isRightPosition ? 10 : 0) + _vm.offset.side}px;
        height: ${608 + _vm.offset.bottom}px;
    `)},[_c('div',{class:{
            [_vm.$style['preview-box__page']]: true,
            [_vm.$style['preview-box__page_right']]: _vm.isRightPosition
        }}),_c('ConsultantApp',{attrs:{"loading":false,"position":_vm.position,"offset-side":_vm.offset.side,"offset-bottom":_vm.offset.bottom,"tooltip-text":_vm.tooltipText,"is-offline":_vm.isOffline,"need-contact-form":_vm.needContactForm,"is-mobile":false,"name":_vm.name,"logo-link":_vm.logoLink,"main-color":`#${_vm.mainColor}`,"manager":_vm.manager,"messengers-links":_vm.messengerLinksProcessed,"domain-config":_vm.domainConfig,"domain":`test`,"show-messengers":_vm.alwaysShowMessengers || _vm.showMessengersBox,"rounded":_vm.rounded,"theme":_vm.theme,"inversion-color":_vm.inversionColor,"direction":_vm.direction,"icons-color":_vm.iconsColor,"personal-data-link":_vm.personalDataLink,"isFirstMessageSet":_vm.isFirstMessageSet,"contactFormDisplay":_vm.contactFormDisplay},on:{"send-message":_vm.sendMessage,"show-messengers":function($event){_vm.showMessengersBox = true},"hide-messengers":function($event){_vm.showMessengersBox = false}},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('div',{class:_vm.$style['messages']},[_vm._t("default"),_vm._l((_vm.messagesList),function(message){return _c('div',{key:message.id},[_c('ConsultantMessage',{attrs:{"message":message.fromMe ? message : _vm.replaceManagerMessageUser(message),"is-follow":message.isFollow,"default-avatar":message.system}})],1)})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }