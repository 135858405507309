<template>
    <AuthPage v-if="!loggedIn" :crm-setter="setCrm" />
    <UiLoader v-else-if="!crm"/>

    <MainLayout v-else :crm="crm" :class="$style['layout']">
        <template v-if="false" v-slot:asideContentBottom>
            <div>
                <UiButton @click="logout">
                    {{ $t('auth.logout') }}
                </UiButton>
            </div>
        </template>
    </MainLayout>
</template>

<script>
    /**
     * @typedef {{
     *     code: string,
     *     name: string
     * }} crmSite
     *
     * @typedef {{
     *     code: string,
     *     name: string
     * }} orderType
     *
     * @typedef {{
     *     code: string,
     *     name: string
     * }} orderMethod
     *
     * @typedef {{
     *     id: number,
     *     name: string
     * }} manager
     *
     * @typedef {{
     *     id: number,
     *     url: string,
     *     active: boolean,
     *     sites: crmSite[],
     *     types: orderType[],
     *     methods: orderMethod[],
     *     managers: manager[],
     * }} crm
     */

    import AuthPage from "./components/AuthPage"
    import MainLayout from "./components/MainLayout"

    export default {
        components: {
            AuthPage,
            MainLayout
        },

        data () {
            return {
                /**
                 * @type crm
                 */
                crm: null
            }
        },
        mounted () {
            if (this.loggedIn) {
                this.$http.get('crm')
                    .then(response => {
                        this.setCrm(response.body)
                    })
                    .catch(() => {
                        this.$root.clientId = null
                    })
            }
        },
        computed: {
            loggedIn () {
                return !!this.$root.clientId
            }
        },
        methods: {
            logout () {
                if (!confirm(this.$t('auth.logout') + '?')) {
                    return
                }

                this.$root.clientId = null
            },

            setCrm ({id, url, active, crm_order_methods, crm_order_types, crm_sites, managers}) {
                this.crm = {
                    id,
                    url,
                    active,
                    managers,
                    methods: crm_order_methods,
                    types: crm_order_types,
                    sites: crm_sites
                }
            }
        }
    }
</script>

<style lang="less" module>
.layout {
    min-width: 1440px !important;
}
</style>