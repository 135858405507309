<template>
    <UiLoader v-if="!sites"/>

    <UiIntegrationLayout
        v-else
        :integration="$t('app.type')"
        :integrationValue="$t('app.name')"
        :main-link="crm.url"
        :integration-link="crm.url + '/admin/integration/list'"
        :class="$style.layout"
    >
        <template #asideContent>
            <SiteSelector
                v-model="selected"
                :crm="crm"
                :sites="sites"
                :change-site-handler="canChangeSite"
                @add-site="onAddSite"
                @remove-site="onRemoveSite"
            />

            <div v-if="selected">
                <MenuItem
                    v-for="(m,i) in settingsModes"
                    :key="i"
                    :active="mode === m"
                    @click="setMode(m)"
                >
                    <template #icon>
                        <SvgSprite :name="getIcon(m)" />
                    </template>

                    {{ getLabel(m) }}
                </MenuItem>
            </div>
        </template>

        <template #mainContent>
            <div>
                <component
                    v-if="selected"
                    ref="section"
                    :is="mode"
                    :crm="crm"
                    :site="selected"
                    :class="$style['setting-box']"
                    @save="save($event)"
                >
                    <template #underTitle>
                        <UiAlert type="error" :title="error" v-show="error" @close="error = null" />
                    </template>
                </component>
            </div>
        </template>
    </UiIntegrationLayout>
</template>

<script>
    import {
        SHAPE,
        LOGO_TYPE,
        BACKGROUND_MODE,
        DISPLAY_TYPE,
        DISPLAY_MANAGER_TYPE,
        LINKS_MODE,
        DIRECTION,
        FORM_DISPLAY
    } from '@/enum/appearance.ts'

    /**
     * @typedef {{
     *     bottom: number,
     *     side: number
     * }} offset
     *
     * @typedef {{
     *     type: string,
     *     prefix: string,
     *     url: string
     * }} link
     *
     * @typedef {{
     *     id: number,
     *     name: string,
     *     token: string,
     *     title: string,
     *     crm_site: string,
     *     background_color: string,
     *     offset: offset,
     *     chat_position: string,
     *     tooltip_text: string,
     *     hide_on_mobile: boolean,
     *     displaying_delay: number,
     *     intro_text: string,
     *     use_sound_alerts: boolean,
     *     use_unread_counter: boolean,
     *     always_show_links_on_desktops: boolean,
     *     links: link[],
     *     use_offline_mode: boolean,
     *     offline_crm_order_type: string,
     *     offline_crm_order_method: string,
     *     offline_managers: number[],
     *     offline_text: string,
     *     offline_form_name: boolean,
     *     offline_form_name_required: boolean,
     *     offline_form_email: boolean,
     *     offline_form_email_required: boolean,
     *     offline_form_phone: boolean,
     *     offline_form_phone_required: boolean,
     *     offline_form_comment: boolean,
     *     offline_form_comment_required: boolean,
     *     offline_form_result_text: string,
     *     use_contact_form: boolean,
     *     contact_form_text: string,
     *     contact_form_name: boolean,
     *     contact_form_name_required: boolean,
     *     contact_form_email: boolean,
     *     contact_form_email_required: boolean,
     *     contact_form_phone: boolean,
     *     contact_form_phone_required: boolean
     * }} site
     */

    import MenuItem from './common/MenuItem'
    import SiteSelector from "./SiteSelector"
    import * as modes from './modes'
    import clone from '@/utils/clone'

    export default {
        components: {
            MenuItem,
            SiteSelector,
            ...modes
        },

        props: {
            /**
             * @type crm
             */
            crm: {
                type: Object,
            },
        },

        provide () {
            return {
                __registerSection: this.registerSection,
                __unregisterSection: this.unregisterSection
            }
        },

        data() {
            return {
                sites: null,
                selected: null,
                mode: 'appearance',
                error: null,
                saving: false,
                settingsModes: ['appearance', 'common', 'minimized', 'opened', 'offline', 'contact', 'insert', 'actions'],
                sections: new Map()
            }
        },

        mounted() {
            this.$http.get('sites')
                .then((r) => {
                    this.sites = r.body
                    this.selected = this.sites[0]
                })

            this.$root.$on('set-settings-mode', (mode) => {
                if (!this.settingsModes.includes(mode)) {
                    return
                }

                this.mode = mode
            })
        },

        watch: {
            selected () {
                // Временная установка дефолтных значений для новых настроек
                if (!this.selected) {
                    return
                }

                if (!this.selected?.logo_type) {
                    this.selected.logo_type = LOGO_TYPE.STANDART
                }

                if (!this.selected?.element_shape_type) {
                    this.selected.element_shape_type = SHAPE.ROUNDED
                }

                if (!this.selected?.display_type) {
                    this.selected.display_type = DISPLAY_TYPE.CLOSE
                }

                if (!this.selected?.display_manager_type) {
                    this.selected.display_manager_type = DISPLAY_MANAGER_TYPE.STANDART
                }

                if (!this.selected?.links_mode) {
                    this.selected.links_mode = LINKS_MODE.STANDARD
                }

                if (!this.selected?.direction_messengers) {
                    this.selected.direction_messengers = DIRECTION.VERTICAL
                }
                if (!this.selected?.contact_form_display) {
                    this.selected.contact_form_display = FORM_DISPLAY.AT_BEGINNING_DIALOGUE
                }

                if (![BACKGROUND_MODE.LIGHT, BACKGROUND_MODE.DARK].includes(this.selected.background_color)) {
                    this.selected.background_color = BACKGROUND_MODE.LIGHT
                }

                if (!this.selected?.offline_form_personalData) {
                    this.selected.offline_form_personalData = this.selected.processing_personal_data
                }

                if (!this.selected?.offline_form_personalData_required) {
                    this.selected.offline_form_personalData_required = true
                }

                if (!this.selected?.contact_form_personalData) {
                    this.selected.contact_form_personalData = this.selected.processing_personal_data
                }

                if (!this.selected?.contact_form_personalData_required) {
                    this.selected.contact_form_personalData_required = true
                }

                if (!this.selected.crm_site && this.crm?.sites?.length) {
                    // По умолчанию выбран первый магазин
                    this.selected.crm_site = this.crm.sites[0].code
                }

                this.mode = this.mode || 'actions'
            }
        },

        computed: {
            settingsIsActive() {
                return this.settingsModes.includes(this.mode)
            }
        },

        methods: {
            getIcon (mode) {
                return {
                    appearance: 'invert_colors',
                    common: 'settings_outlined',
                    minimized: 'collapse_all_2',
                    opened: 'expand_all_2',
                    offline: 'cloud_off_outlined',
                    contact: 'format_points',
                    insert: 'code',
                    actions: 'sms_chat_outlined',
                }[mode] ?? ''
            },

            getLabel (mode) {
                return {
                    appearance: this.$i18n.t('sites.modes.appearance.appearanceTitle'),
                    common: this.$i18n.t('sites.modes.common.title'),
                    minimized: this.$i18n.t('sites.modes.minimized.title'),
                    opened: this.$i18n.t('sites.modes.opened.title'),
                    offline: this.$i18n.t('sites.modes.offline.title'),
                    contact: this.$i18n.t('sites.modes.contact.title'),
                    insert: this.$i18n.t('sites.modes.insert.title'),
                    actions: this.$i18n.t('sites.modes.actions.title'),
                }[mode] ?? ''
            },

            save ({site, callback}) {
                this.saving = true
                this.$http.post(`sites/${site.id}`, site).then(response => {
                        this.error = null
                        this.selected = site
                        if (typeof callback === "function") {
                            callback(response)
                        }
                    })
                    .catch(response => {
                        this.error = this.$t('common.error_while_saving')
                        callback(response)
                    })
                    .finally(() => {
                        this.saving = false
                    })
            },

            onClickActions () {
                this.mode = 'actions'
                this.$root.$emit('move-to-list')
            },

            onAddSite (site) {
                this.sites.push(clone(site))
            },

            onRemoveSite (index) {
                this.sites.splice(index, 1)
            },

            /** Регистрирует активную секцию настроек */
            registerSection (id, sectionChange) {
                this.sections.set(id, sectionChange)
            },

            /** Удаляет активную секцию настроек */
            unregisterSection (id) {
                this.sections.delete(id)
            },

            /**
             * Проверяет наличие изменений в активной секции настроек
             */
            sectionHasChanges () {
                for (let sectionChange of this.sections.values()) {
                    if (typeof sectionChange === 'function' && sectionChange()) {
                        return true
                    }
                }

                return false
            },

            /** Смена раздела настроек */
            async setMode (mode) {
                if (!this.sectionHasChanges() || this.saving) {
                    this.mode = mode
                    this.goToActions(mode)
                    return
                }

                this.$omnicaModalWindow.msgBoxConfirm(this.$t('systemPopup.dataLostDescription'))
                    .then(ok => {
                        if (ok) {
                            this.mode = mode
                            this.goToActions(mode)
                        }
                    })
            },

            /** Смена текущего сайта */
            async canChangeSite () {
                if (!this.sectionHasChanges()) {
                    return true
                }

                return await this.$omnicaModalWindow.msgBoxConfirm(this.$t('systemPopup.dataLostDescription'))
            },

            /** Переход в корневой раздел автосообщений */
            goToActions (mode) {
                if (mode !== 'actions') {
                    return
                }

                if (this.$refs.section && typeof this.$refs.section.resetActionId === 'function') {
                    this.$refs.section.resetActionId()
                }
            }
        }
    }
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.layout {
    & > header {
        box-shadow: none;
        border-bottom: 1px solid @grey-500;
    }

    & > main > aside {
        border-right: 1px solid @grey-500;
    }

    & > main > div {
        padding-left: @spacing-xl;
        padding-right: @spacing-xl;
        padding-bottom: 73px;
    }
}
</style>
