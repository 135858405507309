<template>
    <UiCollapseBox
        id="managers"
        v-model="expanded"
        :collapsible="!savingAvailable"
    >
        <template #title>
            {{ $t('sites.modes.actions.form.managers.title') }}
        </template>

        <template #description>
            {{ $t('sites.modes.actions.form.managers.description') }}
        </template>

        <template #body-content>
            <div class="ui-content-box ui-content-box_status">
                <div class="ui-status">
                    <UiSwitch
                        v-model="form.on"
                        :label="$t('sites.modes.actions.form.managers.on')"
                    />

                    <UiRow v-if="form.on" mt="m">
                        <div>
                            <UiRadiogroup v-model="form.list" rubber>
                                <UiRadiogroupOption
                                    :label="$t('sites.modes.actions.form.managers.any')"
                                    :value="false"
                                    size="sm"
                                />

                                <UiRadiogroupOption
                                    :label="$t('sites.modes.actions.form.managers.from_list')"
                                    :value="true"
                                    size="sm"
                                />
                            </UiRadiogroup>

                            <UiRow v-if="form.list" mt="m">
                                <UiField :label="$t('sites.modes.actions.form.managers.from_list')">
                                    <div :class="$style.manager">
                                        <UiSelect
                                            v-model="form.ids"
                                            :class="$style.manager__select"
                                            :options="managers"
                                            :placeholder="$t('sites.modes.actions.form.managers.choose_manager')"
                                            label-prop-name="name"
                                            value-prop-name="id"
                                            multiple
                                            tags
                                        >
                                            <template #tags>
                                                <UiTag
                                                    v-for="manager in selecdedManagers"
                                                    :key="`manager-${manager.id}`"
                                                    :class="$style['manager-tag']"
                                                    removable
                                                    @remove="removeManager(manager)"
                                                >
                                                    {{ manager.name }}
                                                </UiTag>

                                            </template>
                                        </UiSelect>

                                        <UiSelect
                                            v-model="form.listMode"
                                            :options="listModes"
                                            label-prop-name="label"
                                            max-width-dropdown
                                        >
                                            <template #target="selectData">
                                                <UiButton
                                                    :active="selectData.data.active"
                                                    appearance="tertiary"
                                                >
                                                    {{ selectData.data.value }}
                                                    <SvgSprite name="caret_down" />
                                                </UiButton>
                                            </template>
                                        </UiSelect>
                                    </div>
                                </UiField>
                            </UiRow>
                        </div>
                    </UiRow>
                </div>

                <UiRow v-show="!form.on" mt="m">
                    <UiInfoBox icon="info-outlined" type="warning">
                        <template #description>
                            <UiText :class="$style.info" size="small">
                                {{ $t('sites.modes.actions.form.managers.offline_form_begin') }}
                                <UiLink size="small" @click.native="openOfflineMode">
                                    {{ $t('sites.modes.actions.form.managers.offline_form_link') }}
                                </UiLink>
                                {{ $t('sites.modes.actions.form.managers.offline_form_end') }}
                            </UiText>
                        </template>
                    </UiInfoBox>
                </UiRow>

                <UiText v-if="errIds" color="action">
                    {{ errIds }}
                </UiText>
            </div>
        </template>

        <template #footer-content>
            <FooterButtons
                :hide-save="hideSave"
                :saving="saving"
                @close="onCollapse"
                @save="save"
            />
        </template>
    </UiCollapseBox>
</template>

<script>
    import FooterButtons from './FooterButtons'
    import {required} from 'vuelidate/lib/validators'
    import {vErrText} from '../../../main'
    import actionSectionMixin from '../mixins/actionSectionMixin'
    import clone from '@/utils/clone'

    const MODE = Object.freeze({
        OFF: '',
        ANY: 'any',
        SOMEONE: 'someone',
        ANOTHER: 'another'
    })

    export default {
        components: {
            FooterButtons
        },

        mixins: [ actionSectionMixin ],

        props: {
            /**
             * @type action
             */
            action: {
                type: Object,
                required: true
            },
            managers: {
                type: Array,
                required: true,
            }
        },
        validations () {
            const v = {form: {}}
            const f = v.form

            if (this.form.on && this.form.list) {
                f.ids = { required }
            }

            return v
        },
        data() {
            const listModeSomeOne = {
                label: this.$t('sites.modes.actions.form.managers.list_modes.someone'),
                value: MODE.SOMEONE
            }

            const listModeAnother = {
                label: this.$t('sites.modes.actions.form.managers.list_modes.another'),
                value: MODE.ANOTHER
            }

            return {
                expanded: false,
                saving: false,
                btnClicks: 0,
                error: null,
                listModes: [listModeSomeOne, listModeAnother],
                selectedManager: null,
                form: this.defaultForm()
            }
        },
        computed: {
            selecdedManagers () {
                return this.managers.filter(manager => this.form.ids.find(id => id === manager.id))
            },
            errIds () { return this.vErrText(this.$v.form.ids) },
            hideSave () {
                return this.action.id > 0 && !this.savingAvailable
            }
        },
        methods: {
            vErrText(v, additionalValidators) {
                if (!this.btnClicks) {
                    return false
                }

                return vErrText(v, additionalValidators)
            },
            save() {
                this.btnClicks++
                if (this.$v.form.$invalid) {
                    return
                }

                this.saving = true

                const data = {
                    mode: this.getMode()
                }

                const f = this.form
                if (f.list) {
                    data.list = f.ids
                }

                this.$emit('save', {
                    data: {
                        conditions: {
                            online_managers: data
                        }
                    },
                    onSuccess: () => { this.onSave() },
                    onError: () => {
                        this.error = this.$t('common.error_while_saving')
                        setTimeout(() => {
                            this.error = null
                        }, 5000)
                    },
                    onFinally: () => { this.saving = false }
                })
            },
            getMode () {
                const f = this.form
                if (!f.on) {
                    return MODE.OFF
                }

                if (!f.list) {
                    return MODE.ANY
                }

                return f.listMode.value
            },
            removeManager (manager) {
                const index = this.form.ids.findIndex((id) => id === manager.id)
                if (index === -1) {
                    return
                }

                this.form.ids.splice(index, 1)
            },
            openOfflineMode () {
                if (this.changed) {
                    if (!confirm(this.$t('common.not_save'))) {
                        return
                    }
                }

                this.$root.$emit('set-settings-mode', 'offline')
            },
            defaultForm () {
                const om = this.action.conditions.online_managers
                const listModeSomeOne = {
                    label: this.$t('sites.modes.actions.form.managers.list_modes.someone'),
                    value: MODE.SOMEONE
                }

                const listModeAnother = {
                    label: this.$t('sites.modes.actions.form.managers.list_modes.another'),
                    value: MODE.ANOTHER
                }

                const list = [MODE.SOMEONE, MODE.ANOTHER].includes(om.mode)
                let listMode = listModeSomeOne
                if (om.mode === MODE.ANOTHER) {
                    listMode = listModeAnother
                }

                return {
                    on: om.mode !== MODE.OFF,
                    list,
                    listMode: clone(listMode),
                    ids: om.list || [],
                }
            }
        }
    }
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.info {
    color: @grey-900 !important;
}

.manager {
    display: flex;
    align-items: flex-start;

    &__select {
        margin-right: @spacing-xs;
        width: 320px;
    }
}

.manager-tag {
    margin-right: @spacing-xs;
    margin-bottom: @spacing-xs;
}
</style>
