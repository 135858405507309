<template>
    <div :class="$style['section']">
        <UiRow
            v-if="title || $slots.title"
            :class="$style['content']"
            mt="-4"
            mb="s"
        >
            <UiText v-if="title"  size="paragraph" accent>
                <slot name="title">
                    {{ title }}
                </slot>
            </UiText>
        </UiRow>

        <slot />
    </div>
</template>

<script>
export default {
    name: 'ContentSection',

    props: {
        title: {
            type: String,
            default: ''
        },
    }
}
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.section {
    align-self: flex-start;
    padding: @spacing-m;
    width: 100%;
    max-width: 640px;
    background-color: #fff;
    border: 1px solid @grey-500;
    border-radius: @border-radius-l;
}
</style>
