<template>
    <div>
        <div v-if="simpleTitle">
            <slot name="title">
                {{ title }}
            </slot>
        </div>
        <UiText v-else size="title-02" accent>
            <slot name="title">
                {{ title }}
            </slot>
        </UiText>

        <UiRow :class="$style['content']" mt="m">
            <component
                :is="simple ? 'div' : 'ContentSection'"
                :class="$style['content__main']"
            >
                <slot />
            </component>

            <slot name="preview" />
        </UiRow>

        <div v-if="save" :class="$style.panel">
            <UiButton
                :disabled="loading"
                variant="success"
                @click="$emit('save')"
            >
                <UiIcon v-if="loading" name="circleLoading" />
                <UiIcon v-else name="done" />
                {{ $t('common.btn_save') }}
            </UiButton>
        </div>
    </div>
</template>

<script>
import ContentSection from './ContentSection.vue'

export default {
    name: 'ContentBox',
    components: { ContentSection },

    props: {
        title: {
            type: String,
            default: ''
        },

        save: {
            type: Boolean,
            default: true
        },

        preview: {
            type: Boolean,
            default: false
        },

        loading: {
            type: Boolean,
            default: false
        },

        simple: {
            type: Boolean,
            default: false
        },

        simpleTitle: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.content {
    display: flex;

    &__main {
        width: 640px;
    }
}

.panel {
    position: fixed;
    z-index: 10000;
    bottom: 0;
    left: 288px;
    right: 0;
    padding: 10px 40px;
    background-color: #fff;
    border-top: 1px solid @grey-500;
}
</style>
