export const messengerLinkPrefixes = {
    fbmessenger: 'm.me/',
    telegram: ['t.me/', 'teleg.run/', 'tele.click/', 'tglink.ru/'],
    whatsapp: 'wa.me/',
    viber: 'viber://pa?chatURI=',
    fb: 'fb.me/',
    instagram: 'instagram.com/',
    vk: 'vk.me/'
}

export const messengerLinkPrefixesProto = {
    fbmessenger: 'https://m.me/',
    telegram: ['https://t.me/', 'https://teleg.run/', 'https://tele.click/', 'https://tglink.ru/'],
    whatsapp: 'https://wa.me/',
    viber: 'viber://pa?chatURI=',
    fb: 'https://fb.me/',
    instagram: 'https://instagram.com/',
    vk: 'https://vk.me/'
}
