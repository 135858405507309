<template>
    <div class="ui-auth">
        <UiText
            class="ui-auth__title"
            size="title-02"
        >
            {{ $t('auth.title') }}
        </UiText>

        <div v-if="showGeneralError && !!error" class="ui-auth-err">
            <UiAlert
                type="danger"
                @closeAlert="showGeneralError = false"
            >
                {{ error }}
            </UiAlert>
        </div>

        <div class="ui-auth-box">
            <div class="ui-auth-box__aside ui-auth-box__aside_action">
                <div class="ui-auth-box__logo">
                    <img src="data:image/svg+xml,%3csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M19.9994 39.4853C30.7611 39.4853 39.4851 30.7612 39.4851 19.9995C39.4851 9.23785 30.7611 0.513794 19.9994 0.513794C9.23773 0.513794 0.513672 9.23785 0.513672 19.9995C0.513672 30.7612 9.23773 39.4853 19.9994 39.4853Z' fill='white' stroke='%23E0676B' stroke-width='0.967'/%3e %3cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.9232 23.0872H23.4291V24.3636H14.9232V23.0872ZM14.9232 19.8963H25.8595V21.1727H14.9243L14.9232 19.8963ZM14.9232 16.7065H25.2521V17.9828H14.9243L14.9232 16.7065ZM25.5234 28.316L25.0575 31.007C25.0383 31.1195 24.9907 31.2244 24.9197 31.3109C24.8487 31.3974 24.7569 31.4623 24.6538 31.4988C24.5508 31.5353 24.4404 31.5421 24.334 31.5185C24.2276 31.4948 24.1293 31.4416 24.0492 31.3644L20.8838 28.316H13.3171C12.6726 28.3157 12.0546 28.0468 11.5989 27.5683C11.1431 27.0899 10.887 26.441 10.8867 25.7644V14.2793C10.8867 13.6025 11.1427 12.9533 11.5985 12.4746C12.0543 11.9959 12.6724 11.7269 13.3171 11.7266H26.6838C27.3285 11.7269 27.9466 11.9959 28.4024 12.4746C28.8582 12.9533 29.1142 13.6025 29.1142 14.2793V25.7654C29.1136 26.4419 28.8574 27.0905 28.4017 27.5687C27.946 28.0469 27.3281 28.3157 26.6838 28.316H25.5234Z' fill='%23FC5A5A'/%3e %3c/svg%3e">
                </div>
                <div class="ui-auth-box__title">{{ $t('app.name') }}</div>
            </div>
            <div class="ui-auth-box__content">
                <UiRow>
                    <UiField
                        :label="$t('auth.form.url')"
                        area-id="auth_url"
                    >
                        <UiInput
                            v-model="form.url"
                            :placeholder="$t('auth.form.url_placeholder')"
                            :error="errUrl"
                            id="auth_url"
                            ref="auth_url"
                            @keyup.enter.native="auth"
                            @focusin.native="urlFocused"
                        />
                    </UiField>
                </UiRow>

                <UiRow mt="s">
                    <UiField
                        :label="$t('auth.form.key')"
                        area-id="auth_key"
                    >
                        <UiInput
                            v-model="form.key"
                            :error="errKey"
                            placeholder="eJR750wfWQ7zqWLmwnjlW8wU97iubgy5"
                            id="auth_key"
                            @keyup.enter.native="auth"
                        />
                    </UiField>
                </UiRow>

                <UiRow mt="l">
                    <UiButton
                        size="sm"
                        :disabled="loading"
                        @click="auth"
                    >
                        <UiIcon v-if="loading" name="circleLoading" />
                        {{ $t('auth.form.send') }}
                    </UiButton>
                </UiRow>
            </div>
        </div>
    </div>
</template>

<script>
    import {maxLength, minLength, required, url} from 'vuelidate/lib/validators'
    import {vErrText} from '../main'

    export default {
        props: {
            crmSetter: {
                type: Function,
                required: true
            }
        },

        data() {
            return {
                form: {
                },
                showGeneralError: true,
                authError: null,
                btnClicks: 0,
                loading: false
            }
        },
        validations: {
            form: {
                url: {
                    required,
                    maxLength: maxLength(1000),
                    url
                },
                key: {
                    required,
                    minLength: minLength(3),
                    maxLength: maxLength(100)
                }
            }
        },
        created() {
            const url = new URL(window.location.href)
            let initialUrl = url.searchParams.get('account') || null
            if (initialUrl) {
                this.form.url = initialUrl.replace(/\/$/g, '')
            }
        },
        watch: {
            form: {
                deep: true,
                handler() {
                    this.$root.error = null
                    this.authError = null
                }
            }
        },
        computed: {
            errUrl() {
                return this.vErrText(this.$v.form.url)
            },
            errKey() {
                return this.vErrText(this.$v.form.key)
            },
            error () {
                return this.$root.error || this.authError || null
            }
        },
        methods: {
            vErrText(v, additionalValidators) {
                if (!this.btnClicks) {
                    return false
                }

                return vErrText(v, additionalValidators)
            },
            auth() {
                this.showGeneralError = true
                this.btnClicks++
                if (this.$v.form.$invalid || this.error) {
                    return
                }

                this.loading = true
                this.$http.post('crm/activate', this.form)
                    .then(r => {
                        this.$root.clientId = r.body.client_id
                        this.crmSetter(r.body)
                    })
                    .catch(() => {
                        // todo при несуществующем аккаунте сервер отдаёт 500
                        this.authError = this.$t('auth.error', {
                            url: this.$t("auth.form.url"),
                            key: this.$t("auth.form.key")
                        })
                    })
                    .finally(() => this.loading = false)
            },
            clearErrors () {

            },
            urlFocused () {
                if (this.form.url) {
                    return
                }

                this.form.url = this.$t('auth.form.url_default')

                this.$nextTick(() => {
                    setTimeout(() => {
                        const input = this.$refs['auth_url'] && this.$refs['auth_url'].$refs['inputArea'] || null
                        if (!input) {
                            return
                        }
                        // https://|.retail...
                        input.setSelectionRange(8, 8)
                    }, 50)
                })
            }
        },
    }
</script>

<style scoped>
    .ui-auth-err {
        margin: 10px 123px 0 136px;
        max-width: 704px;
    }
</style>
