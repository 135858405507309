<template>
    <div>
        <div v-show="!editMode" class="ui-aside-menu">
            <div class="ui-aside-menu__area">
                <UiSelect
                    :value="selectedSiteId"
                    :options="sites"
                    value-prop-name="id"
                    label-prop-name="name"
                    @changeSelect="setSelectSiteId"
                >
                    <template #iconLeft>
                        <SvgSprite :class="$style.icon" name="website" />
                    </template>

                    <UiOption
                        v-for="site in sites"
                        :key="site.id + site.crm_site"
                        :label="site.name"
                        :value="site"
                    />

                    <UiMenuItem
                        :hover-icon="false"
                        icon="addCircleOutlined"
                        iconClass="ui-icon-green"
                        @click.native="add"
                    >
                        {{ $t('sites.selector.add') }}
                    </UiMenuItem>
                </UiSelect>
            </div>
            <div class="ui-aside-menu__action ui-aside-menu__action_btn">
                <UiSelect max-width-dropdown>
                    <template #target="selectData">
                        <UiButton
                            :active="selectData.data.active"
                            class="omnica-button_muted"
                            appearance="tertiary"
                            shape="square"
                        >
                            <UiIcon name="moreHorizontal" />
                        </UiButton>
                    </template>

                    <UiOption
                        :label="$t('sites.selector.rename')"
                        value="edit"
                        @click="edit"
                    >
                        <template #iconPrefix>
                            <UiIcon name="edit" />
                        </template>
                    </UiOption>

                    <UiOption
                        :label="$t('sites.selector.remove')"
                        value="remove"
                        @click="remove"
                    >
                        <template #iconPrefix>
                            <UiIcon name="deleteOutlined" />
                        </template>
                    </UiOption>
                </UiSelect>
            </div>
        </div>

        <div v-show="editMode" class="ui-aside-menu">
            <div class="ui-aside-menu__area">
                <UiInput
                    v-model="siteName"
                    ref="inputSiteName"
                    min
                    @keyup.native.enter="save"
                    @keyup.native.esc="cancel"
                />
            </div>

            <div class="ui-aside-menu__action ui-aside-menu__action_btn">
                <UiButton
                    :class="$style.action"
                    :disabled="saving || invalidSiteName"
                    variant="success"
                    shape="square"
                    @click="save"
                >
                    <UiIcon name="done" />
                </UiButton>

                <UiButton
                    :class="$style.action"
                    :disabled="saving"
                    appearance="secondary"
                    variant="danger"
                    shape="square"
                    @click="cancel"
                >
                    <UiIcon name="clear" />
                </UiButton>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        SHAPE,
        LOGO_TYPE,
        BACKGROUND_MODE,
        DISPLAY_TYPE,
        DISPLAY_MANAGER_TYPE
    } from '@/enum/appearance.ts'

    export default {
        model: {
            prop: 'model',
            event: 'change'
        },
        props: {
            /**
             * @type site
             */
            model: {
                type: [Object],
            },
            /**
             * @type crm
             */
            crm: {
                type: [Object],
            },
            /**
             * @type site[]
             */
            sites: {
                type: Array,
            },
            /** Функция, вызываемая перед изменением сайта */
            changeSiteHandler: {
                type: Function,
                default: async () => true
            }
        },
        watch: {
            selectedSiteId (newId) {
                for (const site of this.sites) {
                    if (site.id === newId) {
                        if (!this.model || this.model.id !== site.id) {
                            this.$emit('change', site)
                        }

                        return
                    }
                }

                this.$emit('change', null)
            },
            editMode (isOn) {
                if (isOn) {
                    this.siteName = this.model && this.model.name || ''
                    this.$nextTick(() => {
                        if (this.$refs['inputSiteName'] && this.$refs['inputSiteName'].$refs['inputArea']) {
                            this.$refs['inputSiteName'].$refs['inputArea'].focus()
                        }
                    })
                } else {
                    this.siteName = null
                }
            }
        },
        data () {
            return {
                saving: false,
                editMode: false,
                siteName: null,
                selectedSiteId: this.model  && this.model.id || null,
                prevSiteId: null
            }
        },
        computed: {
            invalidSiteName () {
                const sn = this.siteName
                return !sn || sn.length === 0 || sn.length > 50
            }
        },
        methods: {
            async add () {
                if (await this.changeSiteHandler()) {
                    this.prevSiteId = this.selectedSiteId
                    this.selectedSiteId = null
                    this.editMode = true
                }
            },
            edit () {
                this.editMode = true
            },
            remove () {
                if (!this.selectedSiteId || !confirm(this.$t('common.remove_confirm'))) {
                    return
                }

                this.saving = true
                this.$http.delete(`sites/${this.selectedSiteId}`)
                    .then(() => {
                        const i = this.sites.findIndex((s) => s.id === this.selectedSiteId)
                        if (i === -1) {
                            return
                        }

                        this.$emit('remove-site', i)

                        this.selectedSiteId = this.sites[0] && this.sites[0].id || null
                    })
                    .finally(() => {this.saving = false})
            },
            save () {
                if (this.saving || this.invalidSiteName) {
                    return
                }

                const success = () => {
                    this.prevSiteId = null
                    this.editMode = false
                }

                this.saving = true
                if (!this.selectedSiteId) {
                    this.$http.post('sites', {name: this.siteName})
                        .then((r) => {
                            const site = r.body

                            if (!site?.logo_type) {
                                site.logo_type = LOGO_TYPE.STANDART
                            }

                            if (!site?.element_shape_type) {
                                site.element_shape_type = SHAPE.ROUNDED
                            }

                            if (!site?.display_type) {
                                site.display_type = DISPLAY_TYPE.CLOSE
                            }

                            if (!site?.display_manager_type) {
                                site.display_manager_type = DISPLAY_MANAGER_TYPE.STANDART
                            }

                            if (![BACKGROUND_MODE.LIGHT, BACKGROUND_MODE.DARK].includes(site.background_color)) {
                                site.background_color = BACKGROUND_MODE.LIGHT
                            }

                            // все данные по сайтам раньше отправлялись одной формой, а теперь отдельно
                            // устанавливаем сайт по умолчанию, так как он обязателен и если этого не сделать
                            // при сохранении любого блока настроек кроме общих будет не понятная ошибка
                            const defaultSiteCode = this.crm && this.crm.sites[0] && this.crm.sites[0].code || null
                            if (defaultSiteCode) {
                                site.crm_site = defaultSiteCode
                                this.$http.post(`sites/${site.id}`, site)
                            }

                            this.$emit('add-site', site)
                            this.selectedSiteId = site.id
                            success()
                        })
                        .catch(() => { alert(this.$t('common.error_while_saving')) })
                        .finally(() => { this.saving = false })

                    return
                }

                const i = this.sites.findIndex((s) => s.id === this.selectedSiteId)
                if (i === -1) {
                    return
                }
                const site = this.sites[i]

                this.$http.post(`sites/${site.id}`, {...site, name: this.siteName})
                    .then(() => {
                        site.name = this.siteName
                        success()
                    })
                    .catch(() => { alert(this.$t('common.error_while_saving')) })
                    .finally(() => { this.saving = false })

            },
            cancel () {
                if (this.prevSiteId) {
                    this.selectedSiteId = this.prevSiteId
                    this.prevSiteId = null
                }

                this.editMode = false
            },

            async setSelectSiteId (id) {
                if (await this.changeSiteHandler()) {
                    this.selectedSiteId = id
                }
            }
        }
    }
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.icon {
    width: 24px;
    height: 24px;
}

.action {
    &:not(:last-child) {
        margin-right: @spacing-xxs;
    }
}
</style>
