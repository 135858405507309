<template>
    <UiLoader v-if="!action" />
    <ContentBox
        v-else
        :save="false"
        preview
        simple
        simple-title
    >
        <template #title>
            <NameEdited
                v-model="action.name"
                :is-new="isNew"
                @save="save($event)"
            />
        </template>

        <activity
            :enabled="action.enabled"
            :active_from="action.active_from"
            :active_until="action.active_until"
            :is-new="isNew"
            @change="save($event)"
        >
            <template #additionalActions>
                <UiButton
                    v-tooltip="{
                        content: $t('sites.modes.actions.remove'),
                        disabled: isNew,
                    }"
                    :class="$style.box__control"
                    :locked="isNew"
                    class="omnica-button_muted"
                    appearance="tertiary"
                    variant="danger"
                    shape="square"
                    @click="remove"
                >
                    <SvgSprite name="delete_outlined" />
                </UiButton>
            </template>
        </activity>

        <UiRow mt="m">
            <UiCollapseGroup
                v-model="section"
                @expand-cancelled="onExpandCancelled"
            >
                <Type
                    ref="type"
                    :action="action"
                    :site="this.site"
                    @save="save($event)"
                    @update-action="onUpdateAction"
                />

                <Managers
                    ref="managers"
                    :action="action"
                    :managers="managers"
                    @save="save($event)"
                />

                <Condition
                    ref="condition"
                    :action="action"
                    :displaying-delay="site.displaying_delay"
                    @save="save($event)"
                />

                <Schedule
                    ref="shedule"
                    :action="action"
                    @save="save($event)"
                />
            </UiCollapseGroup>
        </UiRow>

        <template #preview>
            <ConsultantPreview
                v-model="opened"
                :position="site.chat_position"
                :tooltip-text="demoTooltip"
                :name="site.title"
                :main-color="site.elements_color ? '#' + site.elements_color : '#005EEB'"
                :rounded="isRounded"
                :theme="site.background_color"
                :inversion-color="site.inversion_colors"
                :offset="site.offset"
                :crm-url="crmUrl"
                :messages="[]"
            >
                <div
                    v-for="(message, index) in demoMessages"
                    :key="`message-${index}`"
                >
                    <ConsultantMessage
                        :message="message"
                        :is-follow="message.isFollow"
                        default-avatar
                    >
                        <ContactForm
                            v-if="demoContactForm"
                            :config="demoContactForm"
                            :handler="() => {}"
                            :personal-data-consent="site.processing_personal_data"
                            :personal-data-link="site.processing_personal_data_link ? site.processing_personal_data_link : ''"
                            form-state="start"
                        />
                    </ConsultantMessage>

                </div>
            </ConsultantPreview>
        </template>
    </ContentBox>
</template>

<script>
    import ContentBox from '../../common/ContentBox'
    import ConsultantPreview from '../../common/ConsultantPreview.vue'
    import { SHAPE } from '@/enum/appearance.ts'
    import { ACTION_TYPE } from '@/enum/actions.ts'
    import clone from '@/utils/clone'
    import previewMessage from '@/utils/previewMessage.js'
    import * as sub from './sub'

    function maxNameCounter (items, defName) {
        const regExp = new RegExp(defName + '\\s*([\\d]+)')

        return items.reduce((acc, i) => {
            const res = i.name.match(regExp)
            if (res && parseInt(res[1]) > acc) {
                return parseInt(res[1])
            }

            return acc
        }, 0)
    }

    /**
     * @param action
     */
    function initAction(action) {
        for (const prop of ['active_from', 'active_until']) {
            if (action[prop] ===  undefined) {
                action[prop] = null
            }
        }
    }

    export default {
        components: {
            ...sub,
            ContentBox,
            ConsultantPreview
        },
        props: {
            site: {
                type: Object,
                required: true
            },
            actionId: {
                type: Number,
                required: true,
            },
            managers: {
                type: Array,
                required: true,
            },
            crmUrl: {
                type: String,
                default: ''
            },
        },
        data () {
            return {
                /**
                 * @type action|null
                 */
                action: null,
                previewAction: null,
                section: null,
                opened: false,
            }
        },
        mounted () {
            if (this.action) {
                return
            }

            if (this.actionId > 0) {
                this.$http.get(`sites/${this.site.id}/actions/${this.actionId}`).then((r) => {
                    this.action = r.body
                    this.previewAction = clone(r.body)
                })
                return
            }

            const defName = this.$t('sites.modes.actions.add')
            this.action = {
                id: 0,
                name:  defName + ' ' + (maxNameCounter(this.$parent.actions, defName) + 1),
                enabled: false,
                type: "popup_message",
                sec_on_site: 20,
                sec_on_page: 10,
                conditions: {
                    online_managers: {
                        mode: 'any'
                    }
                },
            }

            this.previewAction = clone(this.action)
        },
        watch: {
            'action.name': function (name) { if (!this.isNew) { this.$emit('change', {name}) } },
            'action.enabled': function (enabled) { if (!this.isNew) { this.$emit('change', {enabled}) } },
            'action.active_from': function (active_from) {
                if (!this.isNew) {
                    this.$emit('change', {active_from: active_from || null})
                }
            },
            'action.active_until': function (active_until) {
                if (!this.isNew) {
                    this.$emit('change', {active_until: active_until || null})
                }
            },
        },
        computed: {
            isRounded () {
                return this.site.element_shape_type === SHAPE.ROUNDED
            },
            isNew () {
                return this.action.id === 0
            },

            demoMessages () {
                if (this.previewAction?.type === ACTION_TYPE.POPUP_MESSAGE) {
                    return []
                }

                const messages = []

                if (this.previewAction.messages && this.previewAction.messages[0]) {
                    messages.push(previewMessage(this.previewAction.messages[0], 'system'))
                } else if (this.previewAction?.type === ACTION_TYPE.CONTACT_FORM) {
                    messages.push(previewMessage('', 'system'))
                }

                if (this.previewAction.messages && this.previewAction.messages[1]) {
                    messages.push(previewMessage(this.previewAction.messages[1], 'system', true))
                }

                return messages
            },

            demoContactForm () {
                if (this.previewAction.type !== ACTION_TYPE.CONTACT_FORM) {
                    return null
                }

                const config = {}

                if (this.previewAction.contact_form.name) {
                    config.name = {required: this.previewAction.contact_form.name_required.value}
                }

                if (this.previewAction.contact_form.email) {
                    config.email = {required: this.previewAction.contact_form.email_required.value}
                }

                if (this.previewAction.contact_form.phone) {
                    config.phone = {required: this.previewAction.contact_form.phone_required.value}
                }

                if (this.site.processing_personal_data) {
                    config.personalData = {required: this.site.processing_personal_data}
                }

                if (config.name || config.email || config.phone) {
                    return config
                }

                return null
            },

            demoTooltip () {
                if (this.previewAction.type === ACTION_TYPE.POPUP_MESSAGE && this.previewAction.messages) {
                    return this.previewAction.messages[0]
                }

                return null
            },

            isPersonalData () {
                return this.demoContactForm?.personalData ? true : false
            }
        },
        methods: {
            save ({data, onSuccess, onError, onFinally}) {
                if (!data) {
                    return
                }

                if (!onSuccess) { onSuccess = () => {} }
                if (!onError) { onError = () => {} }
                if (!onFinally) { onFinally = () => {} }

                if (this.isNew) {
                    this.$http.post(`sites/${this.site.id}/actions`, {...this.action, ...data})
                        .then((r) => {
                            this.action = r.body
                            initAction(this.action)
                            onSuccess(r)
                            this.$emit('action-created', this.action)
                        })
                        .catch(onError)
                        .finally(onFinally)
                } else {
                    this.$http.put(`sites/${this.site.id}/actions/${this.action.id}`, data)
                        .then((r) => {
                            this.action = r.body
                            initAction(this.action)
                            onSuccess(r)
                        })
                        .catch((r) => {
                            if (r.status === 400 && r.body.errors.includes('messages required')) {
                                const type = this.$refs['type']
                                if (!type) {
                                    return
                                }

                                const box = type.$refs['box']
                                if (box && !box.isActive) {
                                    box.toggleBox()
                                }

                                this.$nextTick(() => {
                                    const type = this.$refs['type'] || null
                                    type.btnClicks++
                                })
                                return
                            }

                            onError(r)
                        })
                        .finally(onFinally)
                }
            },
            remove () {
                if (this.isNew) {
                    return
                }

                this.$omnicaModalWindow.msgBoxConfirm(() => this.$t('common.remove_confirm'), {
                    variant: 'danger',
                }).then(ok => {
                    if (ok) {
                        this.$http.delete(`sites/${this.site.id}/actions/${this.actionId}`)
                            .then(() => {
                                this.$emit('action-removed', this.actionId)
                            })
                    }
                })
            },

            onExpandCancelled (e) {
                this.$omnicaModalWindow.msgBoxConfirm(this.$t('systemPopup.dataLostDescription'))
                    .then(ok => {
                        if (ok) {
                            const actual = this.$refs[e.actual]
                            if (!actual && typeof actual.resetForm !== 'function') {
                                return
                            }

                            actual.resetForm()

                            this.$nextTick(() => {
                                this.section = e.tried
                            })
                        }
                    })
            },

            onUpdateAction (action) {
                this.opened = action.type !== ACTION_TYPE.POPUP_MESSAGE

                for (let key in action) {
                    if (!this.previewAction[key]) {
                        this.$set(this.previewAction, key, action[key])
                    } else {
                        this.previewAction[key] = action[key]
                    }
                }
            }
        }
    }
</script>

<style lang="less" module>
.action {
    width: 100%;
    max-width: 640px;
}
</style>
