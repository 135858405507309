<template>
    <div v-if="!editingModeIsActive" :class="$style.wrap">
        <UiText size="title-02">{{ value }}</UiText>

        <div class="ui-title__btn" :title="$t('sites.modes.actions.form.change_name_title')">
            <UiIcon
                :title="$t('sites.modes.actions.form.change_name_title')"
                name="edit"
                btn
                @click.native="edit"
            />
        </div>
    </div>
    <div v-else :class="$style.wrap">
        <div :class="$style.wrap__input">
            <UiInput
                v-model.trim="newValue"
                :error="errNewValue"
                :disabled="saving"
                ref="new-value-input"
                min
                @keyup.native.enter="save"
                @keyup.native.esc="cancel"
            />
        </div>
        <div :class="$style.wrap__controls">
            <UiButton
                :class="$style.wrap__control"
                :disabled="!!errNewValue"
                :variant="!saving ? 'success' : 'loading'"
                shape="square"
                @click="save"
            >
                <UiIcon name="done" />
            </UiButton>

            <UiButton
                :class="$style.wrap__control"
                :variant="!saving ? 'cancel' : 'loading'"
                appearance="secondary"
                shape="square"
                @click="cancel"
            >
                <UiIcon name="clear" />
            </UiButton>
        </div>
    </div>
</template>

<script>
    import {vErrText} from '../../../main'
    import {maxLength, required} from 'vuelidate/lib/validators'

    export default {
        props: {
            value: String,
            isNew: {
                type: Boolean,
                required: true,
            },
        },
        data () {
            return {
                editingModeIsActive: false,
                newValue: null,
                saving: false,
                error: null,
            }
        },
        validations: {
            newValue: {
                required,
                maxLength: maxLength(255),
            },
        },
        computed: {
            errNewValue () { return this.error || vErrText(this.$v.newValue) },
        },
        methods: {
            edit () {
                this.newValue = this.value
                this.editingModeIsActive = true

                this.$nextTick(() => {
                    const input = this.$refs['new-value-input']
                    if (input) {
                        input.$refs['inputArea'].focus()
                        input.$refs['inputArea'].setSelectionRange(0, this.newValue.length)
                    }
                })
            },
            save () {
                if (this.$v.$invalid) {
                    return
                }

                const onSuccess = () => {
                    this.editingModeIsActive = false
                    this.newValue = null
                }

                if (!this.isNew && this.value === this.newValue) {
                    onSuccess()
                    return
                }

                this.saving = true
                this.$emit('save', {
                    data: { name: this.newValue },
                    onSuccess,
                    onError: () => {
                        this.error = this.$t('common.error_while_saving')
                        setTimeout(() => { this.error = null }, 5000)
                    },
                    onFinally: () => {
                        this.saving = false
                    }
                })
            },
            cancel () {
                this.editingModeIsActive = false
                this.newValue = null
            }
        }
    }
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.wrap {
    display: flex;
    align-items: center;
    min-height: 36px;
    max-width: 640px;

    &__input {
        margin-right: @spacing-xxs;
        flex-grow: 1;
    }

    &__controls {
        display: flex;
        align-items: center;
    }

    &__control {
        &:not(:last-child) {
            margin-right: @spacing-xxs;
        }
    }
}
</style>
