import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'

import Raven from 'raven-js'
import RavenVue from 'raven-js/plugins/vue'

import VueI18n from 'vue-i18n'
import ru from '../static/translations/ru.json'
import en from '../static/translations/en.json'
import es from '../static/translations/es.json'

import '@ui-kit/core/dist/ui-kit.esm.css'
import UiLibrary from '@ui-kit/core'


import ConsultantUiKit from '@message-gateway/consultant-ui-kit/dist/consultant-ui-kit.esm.js'
import '@message-gateway/consultant-ui-kit/dist/consultant-ui-kit.esm.css'

import VueResource from 'vue-resource'
import Vuelidate from 'vuelidate'
import VTooltip from 'v-tooltip'

import '@omnica/input/dist/input.css'
import '@omnica/color-picker/dist/color-picker.css'
import UiColorPicker from '@omnica/color-picker-vue2'

import SvgSprite from './components/common/SvgSprite'

Vue.use(VTooltip)

Vue.component('UiColorPicker', UiColorPicker)
Vue.component('SvgSprite', SvgSprite)

if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_RAVEN_DSN) {
    const tags = {}

    if (process.env.VUE_APP_COMMIT) {
        tags.commit = process.env.VUE_APP_COMMIT + ''
    }

    Raven
        .config(process.env.VUE_APP_RAVEN_DSN, {
            tags: tags,
        })
        .addPlugin(RavenVue, Vue)
        .install()
}

Vue.use(VueI18n)
let lang = 'en';
if (navigator.language) {
    lang = navigator.language.substring(0, 2)
} else if (navigator.userLanguage) {
    lang = navigator.userLanguage.substring(0, 2)
}
const i18n = new VueI18n({
    locale: lang,
    fallbackLocale: 'en',
    messages: {ru, en, es}
})

export function getLocale() {
    switch (lang) {
        case 'en':
            return 'en_GB'
        case 'es':
            return 'es_ES'
        case 'ru':
            return 'ru_RU'
        default:
            return 'en_GB'
    }
}

import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/es'
import dates from "./filters/dates"

Vue.filter('dateTime', dates.dateTime(i18n, moment))

Vue.use(UiLibrary, {
    getLocale: getLocale,
    modals: {
        confirm: {
            title: () => i18n.t('systemPopup.confirm'),
            okTitle: () => i18n.t('systemPopup.buttons.ok'),
            cancelTitle: () => i18n.t('systemPopup.buttons.cancel'),
            attributes: {
                'style': {
                    zIndex: 10000,
                    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
                },
            },
        },
    }
})

Vue.use(ConsultantUiKit, {
    i18n: {locale: getLocale()},
    previewUrl: url => url
})

Vue.use(VueResource)
const url = new URL(window.location.href)
const clientId = url.searchParams.get('c') || null
Vue.http.interceptors.push(function (request, next) {
    request.headers.set('X-Client-Id', this.$root.clientId)
    next()

    return function(response) {
        if (response.status === 403) {
            const err = response.body.errors && response.body.errors[0] || null
            if (err === 'Module for account is not active') {
                this.$root.clientId = null

                let account = null
                const url = this.crm && this.crm.url || null
                if (url) {
                    const l = document.createElement('a')
                    l.href = url
                    account = l.hostname
                }

                this.$root.error = account ? this.$t('common.integration_account_disabled', {account})
                    : this.$t('common.integration_disabled')
            }
        }

        return response
    };
});

axios.interceptors.response.use((response) => response, (error) => {
    if (error.response && error.response.status === 403) {
        const err = error.response.data && error.response.data.errors[0] || null

        if (err === 'Module for account is not active') {
            axios.defaults.headers.common['X-Client-Id'] = null
            $app.$root.clientId = null

            let account = null
            const url = $app.crm && $app.crm.url || null

            if (url) {
                const link = document.createElement('a')
                link.href = url
                account = link.hostname
            }

            $app.$root.error = account ? $app.$t('common.integration_account_disabled', {account})
                    : $app.$t('common.integration_disabled')
        }
    }

    return Promise.reject(error)
})

const BACKEND_HOST = process.env.VUE_APP_BACK_URL || ''

Vue.http.options.root = BACKEND_HOST + '/api/cabinet/v1';

axios.defaults.baseURL = Vue.http.options.root;
axios.defaults.headers.common['X-Client-Id'] = clientId;

Vue.config.productionTip = false

Vue.use(Vuelidate)

export function boolCheck (value) {
    return value === true || value === false
}

export function vErrText($v, additionalValidators) {
    const validators = {
        required: 'field_is_required',
        minLength: 'too_short_value',
        maxLength: 'too_long_value',
        minValue: 'too_small_value',
        maxValue: 'too_large_value',
        url: 'invalid_value',
        atLeastOneField: 'at_least_one_form_field'
    }

    if (additionalValidators && additionalValidators.length > 0) {
        additionalValidators.forEach((av) => {
            validators[av] = 'invalid_value'
        })
    }

    for (const validator in validators) {
        const result = $v && $v[validator] && undefined

        if (result !== undefined && result === false) {
            return i18n.t(`validation.${validators[validator]}`)
        }
    }

    return false
}

const $app = new Vue({
    data: {
        clientId,
        host: BACKEND_HOST,
        error: null,
    },
    render: h => h(App),
    i18n
}).$mount('#app')

export function debug (msg) {
    if (process.env.NODE_ENV === 'production') {
        return
    }

    const now = new Date()
    const z = (v) => (v < 10 ? '0' + v : v)
    const time = `${z(now.getHours())}:${z(now.getMinutes())}:${z(now.getSeconds())}`

    // eslint-disable-next-line no-console
    console.log(`${time} - ${msg}`)
}
