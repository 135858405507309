<template>
    <ContentBox
        :loading="isLoading"
        preview
        @save="save"
    >
        <template #title>
            {{ $t('sites.modes.offline.title') }}
        </template>

        <UiRow>
            <UiSwitch
                v-model="snapshot.use_offline_mode"
                :label="$t('sites.modes.offline.form.use_offline_mode')"
                :error="errUse"
            />
        </UiRow>

        <UiRow v-show="snapshot.use_offline_mode" mt="s">
            <UiRow>
                <UiField
                    :label="$t('sites.modes.offline.form.managers')"
                    area-id="offline_managers"
                >
                    <UiSelect
                        v-model="snapshot.offline_managers"
                        :options="crm.managers"
                        :error="errManagers"
                        id="offline_managers"
                        value-prop-name="id"
                        label-prop-name="name"
                        multiple
                        tags
                    >
                        <template #tags>
                            <div v-for="id in snapshot.offline_managers" :key="id">
                                <UiTag
                                    v-if="!!getManagerName(id)"
                                    :class="$style.tag"
                                >
                                    {{ getManagerName(id) }}
                                </UiTag>
                            </div>
                        </template>
                    </UiSelect>
                </UiField>
            </UiRow>

            <UiRow mt="s">
                <UiField
                    :label="$t('sites.modes.offline.form.text')"
                    area-id="offline_text"
                >
                    <UiTextarea
                        v-model.number="snapshot.offline_text"
                        :error="errText"
                        id="offline_text"
                        emoji
                        @keyup.enter.native="save"
                    />
                </UiField>
            </UiRow>

            <UiRow mt="m">
                <UiField :label="$t('sites.modes.offline.form.fields')">
                    <div
                        v-for="field in fields"
                        :key="field"
                        :class="$style.row"
                    >

                        <div :class="$style.row__field">
                            <UiSwitch
                                v-model="snapshot['offline_form_' + field]"
                                :disabled="field === 'personalData'"
                            >
                                <span :class="[
                                    $style['form-fields__label'],
                                    {[$style['form-fields__label_offset']]: field === 'personalData'}
                                ]">
                                    {{ $t('sites.modes.offline.form.' + field) }}
                                </span>
                            </UiSwitch>

                            <UiTooltip
                                placement="right"
                                v-if="field === 'personalData'"
                                :class="$style['form-fields__hint-wrap']"
                            >
                                <template #trigger>
                                    <SvgSprite :class="$style['form-fields__hint-icon']" name="info_outlined" />
                                </template>

                                <div :class="$style['form-fields__hint-tooltip']">
                                    {{ $t('sites.modes.offline.form.tooltip.consent') }}
                                </div>
                            </UiTooltip>
                        </div>

                        <div class="offline_fields_required">
                            <UiSelect
                                :disabled="!snapshot['offline_form_' + field]"
                                placement="bottom-start"
                                max-width-dropdown
                            >
                                <template #target="selectData">
                                    <UiButton
                                        :locked="!snapshot['offline_form_' + field] || field === 'personalData'"
                                        :active="selectData.data.active"
                                        appearance="tertiary"
                                    >
                                        {{ snapshot['offline_form_' + field + '_required'] ? $t('common.required') : $t('common.not_required') }}

                                        <UiIcon name="caretDown" />
                                    </UiButton>
                                </template>

                                <UiOption
                                    :active="snapshot['offline_form_' + field + '_required'] === true"
                                    :label="$t('common.required')"
                                    value="required"
                                    @click="snapshot['offline_form_' + field + '_required'] = true"
                                />

                                <UiOption
                                    :active="snapshot['offline_form_' + field + '_required'] === false"
                                    :label="$t('common.not_required')"
                                    value="notRequired"
                                    @click="snapshot['offline_form_' + field + '_required'] = false"
                                />
                            </UiSelect>
                        </div>
                    </div>
                </UiField>
            </UiRow>

            <UiRow mt="m">
                <UiField
                    :label="$t('sites.modes.offline.form.result_text')"
                    area-id="offline_form_result_text"
                >
                    <UiTextarea
                        v-model.number="snapshot.offline_form_result_text"
                        :error="errResultText"
                        id="offline_form_result_text"
                        @keyup.enter.native="save"
                        @focus="resultTextFocus = true"
                        @blur="resultTextFocus = false"
                    />
                </UiField>
            </UiRow>

            <UiRow mt="m">
                <UiText size="paragraph" accent>
                    {{ $t('sites.modes.offline.form.order_settings') }}
                </UiText>

                <UiRow mt="xxs">
                    <UiText :class="$style.desc" size="small">
                        {{ $t('sites.modes.offline.form.order_settings_desc') }}
                    </UiText>
                </UiRow>
            </UiRow>

            <UiRow mt="m">
                <UiField
                    :label="$t('sites.modes.offline.form.order_type')"
                    area-id="offline_crm_order_type"
                >
                    <UiSelect
                        v-model="snapshot.offline_crm_order_type"
                        :error="errOrderType"
                        :options="crm.types"
                        id="offline_crm_order_type"
                        value-prop-name="code"
                        label-prop-name="name"
                    />
                </UiField>
            </UiRow>

            <UiRow mt="m">
                <UiField :label="$t('sites.modes.offline.form.order_method')">
                    <UiSelect
                        v-model="snapshot.offline_crm_order_method"
                        :options="crm.methods"
                        :error="errOrderMethod"
                        id="offline_crm_order_method"
                        value-prop-name="code"
                        label-prop-name="name"
                    />
                </UiField>
            </UiRow>
        </UiRow>

        <template v-if="snapshot.use_offline_mode" #preview>
            <ConsultantPreview
                v-model="opened"
                :position="snapshot.chat_position"
                :tooltip-text="snapshot.tooltip_text"
                :name="snapshot.title"
                :main-color="snapshot.elements_color ? '#' + snapshot.elements_color : '#005EEB'"
                :rounded="isRounded"
                :theme="snapshot.background_color"
                :inversion-color="snapshot.inversion_colors"
                :messengers-links="snapshot.links"
                :offset="snapshot.offset"
                :crm-url="crm.url"
                :messages="[]"
                is-offline
                :icons-color="snapshot.links_mode"
                :direction="snapshot.direction_messengers"
            >
                <ConsultantMessage
                    v-if="Object.keys(demoFormConfig).length"
                    :message="demoMessage"
                    default-avatar
                >
                    <ContactForm
                        v-if="!resultTextFocus"
                        :config="demoFormConfig"
                        :handler="() => {}"
                        :personal-data-consent="snapshot.offline_form_personalData"
                        :personal-data-link="snapshot.processing_personal_data_link"
                        form-state="start"
                    />
                </ConsultantMessage>
            </ConsultantPreview>
        </template>
    </ContentBox>
</template>

<script>
    import {vErrText} from '../../main'
    import {maxLength, required} from 'vuelidate/lib/validators'
    import {boolCheck} from '../../main'
    import ContentBox from '../common/ContentBox'
    import ConsultantPreview from '../common/ConsultantPreview.vue'
    import { SHAPE } from '@/enum/appearance.ts'
    import modeMixin from './mixins/modeMixin.js'
    import clone from '@/utils/clone'
    import previewMessage from '@/utils/previewMessage.js'

    export default {
        components: {
            ContentBox,
            ConsultantPreview
        },

        mixins: [modeMixin],

        props: {
            /**
             * @type crm
             */
            crm: {
                type: Object,
                required: true
            }
        },
        data () {
            return {
                fields: ['name', 'email', 'phone', 'comment', 'personalData'],
                btnClicks: 0,
                isLoading: false,
                opened: true,
                resultTextFocus: false
            }
        },
        validations () {
            const v = {
                snapshot: {
                    use_offline_mode: {
                        required,
                        boolCheck
                    }
                },
            }

            if (this.snapshot.use_offline_mode) {
                v.snapshot.use_offline_mode.atLeastOneField = (value) => {
                    return !value || (
                        this.snapshot.offline_form_name ||
                        this.snapshot.offline_form_email ||
                        this.snapshot.offline_form_phone ||
                        this.snapshot.offline_form_comment
                    )
                }

                v.snapshot.offline_managers = {
                    required
                }
                v.snapshot.offline_text = {
                    maxLength: maxLength(1000)
                }
                v.snapshot.offline_form_name = { boolCheck }
                v.snapshot.offline_form_name_required = { boolCheck }
                v.snapshot.offline_form_email = { boolCheck }
                v.snapshot.offline_form_email_required = { boolCheck }
                v.snapshot.offline_form_phone = { boolCheck }
                v.snapshot.offline_form_phone_required = { boolCheck }
                v.snapshot.offline_form_comment = { boolCheck }
                v.snapshot.offline_form_comment_required = { boolCheck }
                v.snapshot.offline_form_personalData = { boolCheck }
                v.snapshot.offline_form_personalData_required = { boolCheck }
                v.snapshot.offline_form_result_text = { maxLength: maxLength(1000) }

                v.snapshot.offline_crm_order_type = {
                    required,
                    crmOrderTypeVariants (value) {
                        for (let t of this.crm.types) {
                            if (t.code === value) {
                                return true
                            }
                        }

                        return false
                    }
                }
                v.snapshot.offline_crm_order_method = {
                    required,
                    crmOrderMethodVariants (value) {
                        for (let m of this.crm.methods) {
                            if (m.code === value) {
                                return true
                            }
                        }

                        return false
                    }
                }
            }

            return v
        },
        computed: {
            isRounded () {
                return this.snapshot.element_shape_type === SHAPE.ROUNDED
            },

            demoMessage () {
                const message = this.snapshot[this.resultTextFocus ? 'offline_form_result_text' : 'offline_text']
                return previewMessage(message, 'system')
            },

            demoFormConfig () {
                const config = {}

                if (this.snapshot.offline_form_name) {
                    config.name = {required: this.snapshot.offline_form_name_required}
                }

                if (this.snapshot.offline_form_email) {
                    config.email = {required: this.snapshot.offline_form_email_required}
                }

                if (this.snapshot.offline_form_phone) {
                    config.phone = {required: this.snapshot.offline_form_phone_required}
                }

                if (this.snapshot.offline_form_comment) {
                    config.comment = {required: this.snapshot.offline_form_comment_required}
                }

                if (this.snapshot.offline_form_personalData) {
                    config.personalData = {required: this.snapshot.offline_form_personalData_required}
                }

                return config
            },
            errUse () { return this.vErrText(this.$v.snapshot.use_offline_mode, ['boolCheck']) },
            errManagers () { return this.vErrText(this.$v.snapshot.offline_managers) },
            errText () { return this.vErrText(this.$v.snapshot.offline_text) },
            errFormName  () { return this.vErrText(this.$v.snapshot.offline_form_name) },
            errFormNameRequired  () { return this.vErrText(this.$v.snapshot.offline_form_name_required) },
            errFormPhone  () { return this.vErrText(this.$v.snapshot.offline_form_phone) },
            errFormPhoneRequired  () { return this.vErrText(this.$v.snapshot.offline_form_phone_required) },
            errFormEmail  () { return this.vErrText(this.$v.snapshot.offline_form_email) },
            errFormEmailRequired  () { return this.vErrText(this.$v.snapshot.offline_form_email_required) },
            errFormComment  () { return this.vErrText(this.$v.snapshot.offline_form_comment) },
            errFormCommentRequired  () { return this.vErrText(this.$v.snapshot.offline_form_comment_required) },
            errFormPersonalData  () { return this.vErrText(this.$v.snapshot.offline_form_personalData) },
            errFormPersonalDataRequired  () { return this.vErrText(this.$v.snapshot.offline_form_personalData_required) },
            errResultText  () { return this.vErrText(this.$v.snapshot.offline_form_result_text) },
            errOrderType  () { return this.vErrText(this.$v.snapshot.offline_crm_order_type, ['crmOrderTypeVariants']) },
            errOrderMethod  () { return this.vErrText(this.$v.snapshot.offline_crm_order_method, ['crmOrderMethodVariants'])},
        },
        methods: {
            unselectRequiredFieldIfNeeded (fieldName) {
                if (!this.snapshot[fieldName] && this.snapshot[fieldName + '_required']) {
                    this.snapshot[fieldName + '_required'] = false
                }
            },
            vErrText(v, additionalValidators) {
                if (!this.btnClicks) {
                    return false
                }

                return vErrText(v, additionalValidators)
            },
            save () {
                this.btnClicks++
                if (this.$v.$invalid) {
                    return
                }

                this.isLoading = true

                this.$emit('save', {
                    site: clone(this.snapshot),
                    callback: () => {
                        this.isLoading = false
                    }
                })
            },
            getManagerName (id) {
                return this.crm.managers.find(m => m.id === id)?.name ?? ''
            }
        },
        watch: {
            'snapshot.offline_form_name': function () { this.unselectRequiredFieldIfNeeded('offline_form_name') },
            'snapshot.offline_form_email': function () { this.unselectRequiredFieldIfNeeded('offline_form_email') },
            'snapshot.offline_form_phone': function () { this.unselectRequiredFieldIfNeeded('offline_form_phone') },
            'snapshot.offline_form_comment': function () { this.unselectRequiredFieldIfNeeded('offline_form_comment') },
        },
    }
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.row {
    display: flex;

    &:not(:last-child) {
        margin-bottom: @spacing-s;
    }

    &__field {
        display: flex;
        align-items: center;
        width: 250px;
        padding-top: 6px;
        padding-bottom: 6px;
        height: fit-content;
    }
}

.desc {
    color: @grey-900 !important;
}

.tag {
    margin: 0 @spacing-xs @spacing-xs 0;
}

.form-fields {
    &__label {
        white-space: normal;

        &_offset {
            cursor: default;
        }
    }

    &__hint-icon {
        width: @spacing-s;
        height: @spacing-s;
        fill: @grey-700;
        color: @grey-700;

        &:hover {
            fill: @blue-500;
            color: @blue-500;
        }
    }

    &__hint-wrap {
        align-self: end;
    }

    &__hint-tooltip {
        width: 192px;
        height: auto;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.15px;
    }
}
</style>
