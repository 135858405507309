<template>
    <div>
        <div class="ui-flex ui-flex_center">
            <div :class="$style.input" class="ui-form-area_min">
                <UiInput
                    v-model="value"
                    :error="error"
                    :id="id"
                    ref="inputArea"
                />
            </div>

            <div class="ui-flex__col">
                <UiSelect
                    v-model="unit"
                    :options="units"
                    label-prop-name="label"
                    max-width-dropdown
                >
                    <template #target="selectData">
                        <UiButton
                            :active="selectData.data.active"
                            appearance="tertiary"
                        >
                            {{ unit.label }}
                        </UiButton>
                    </template>
                </UiSelect>
            </div>
            <div v-if="tooltip" class="ui-flex__col">
                <UiTooltip>
                    {{ tooltip }}
                </UiTooltip>
            </div>
        </div>
    </div>
</template>

<script>
    const units = [
        {value: 'sec', multiply: 1, label: ''},
        {value: 'min', multiply: 60, label: ''},
    ]

    function initValue(value, defSec) {
        if (!value) {
            value = defSec || 0
        }

        const r = {
            value: value,
            unit: units[0]
        }

        for (let i = units.length - 1; i >= 0; i--) {
            const u = units[i]
            if (value / u.multiply > 0 && value % u.multiply === 0) {
                r.value = value / u.multiply
                r.unit = u
                break
            }
        }

        return r
    }

    export default {
        model: {
            prop: 'model',
            event: 'change'
        },
        props: {
            model: [Number],
            defaultSec: {
                type: [Number, String],
                default: 0
            },
            tooltip: [String],
            error: {
                type: [Boolean, String],
                default: false
            },
            id: [String],
        },
        data () {
            units.forEach((u) => {u.label = this.$t('mixin.time_duration.units.' + u.value)})

            return {
                ...initValue(this.model, parseInt(this.defaultSec)),
                units
            }
        },
        mounted() {
            this.change()
        },
        watch: {
            value () { this.change() },
            unit () { this.change() },
            model () {
                this.value = this.model / this.unit.multiply
            }
        },
        methods: {
            change () {
                let v = '' + this.value
                v = v.replace(/[^0-9]/gim,'')
                v = parseInt(v)

                if (Number.isNaN(v) || v < 0) {
                    v = 0
                }

                if (this.value !== v) {
                    this.value = v
                }

                const sec = v * this.unit.multiply
                if (sec === this.model) {
                    return
                }

                this.$emit('change', sec)
            }
        }
    }
</script>

<style module>
.input {
    width: 128px;
}
</style>
