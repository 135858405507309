<template>
    <div>
        <div v-if="onlyButton">
            <button
                :class="{
                    [$style.button]: true,
                    [$style.button_active]: active
                }"
                v-tooltip="tooltipOnlyButton"
                type="button"
                @click="toggle"
            >
                <SvgSprite
                    :name="active ? 'play' : 'pause'"
                    :class="{
                        [$style.icon]: true,
                        [$style.icon_active]: active
                    }"
                />
            </button>
        </div>

        <div v-else :class="$style.box">
            <div class="ui-flex ui-flex_center">
                <SvgSprite
                    :name="active ? 'play' : 'pause'"
                    :class="{
                        [$style.icon]: true,
                        [$style.icon_active]: active
                    }"
                />

                <div class="ui-flex__col">
                    <UiText size="body">
                        {{ $t('sites.modes.actions.form.activity.state.' + (active ? '' : 'not_') + 'active.current') }}
                    </UiText>
                </div>

                <div class="ui-flex__col" v-if="!active">
                    <UiTooltip>
                        {{ stateDescription }}
                    </UiTooltip>
                </div>
            </div>

            <div :class="$style.box__controls">
                <UiButton
                    v-tooltip="tooltipButton"
                    :class="$style.box__control"
                    :disabled="isNew"
                    :state="state"
                    appearance="tertiary"
                    @click="toggle"
                >
                    {{ doLabelShort }}
                </UiButton>

                <slot name="additionalActions" />
            </div>
        </div>
    </div>
</template>

<script>
    import { VARIANT } from '@omnica/button'

    const CAUSE = Object.freeze({
        NONE: '',
        DISABLED: 'disabled',
        BEFORE: 'before', // истекло
        LATER: 'later' // ещё не наступило
    })

    export default {
        props: {
            enabled: {
                type: Boolean,
                required: true,
            },
            active_from: String,
            active_until: String,
            onlyButton: {
                type: Boolean,
                default: false
            },
            isNew: {
                type: Boolean,
                default: false
            },
        },
        data () {
            return {
                now: null,
                ticker: null,
                state: VARIANT.DEFAULT,
                from: this.active_from ? new Date(this.active_from) : null,
                until: this.active_until ? new Date(this.active_until) : null,
                alreadyDisabled: false,
            }
        },
        mounted () {
            this.checkTicker()
        },
        computed: {
            cause () {
                if (!this.enabled) {
                    return CAUSE.DISABLED
                }

                if (this.now && this.from && this.now < this.from) {
                    return CAUSE.LATER
                }

                if (this.now && this.until && this.now > this.until) {
                    return CAUSE.BEFORE
                }

                return CAUSE.NONE
            },
            active () {
                return this.cause === CAUSE.NONE
            },
            doLabel () {
                const t = (key) => this.$t('sites.modes.actions.form.activity.state.' + key)
                switch (this.cause) {
                    case CAUSE.NONE: return t('not_active.do')
                    case CAUSE.DISABLED: return t('active.do.disabled')
                    default:return !this.alreadyDisabled ? t('not_active.do') : t('active.do.' + this.cause)
                }
            },
            doLabelShort () {
                const t = (key) => this.$t('sites.modes.actions.form.activity.state.' + key)
                switch (this.cause) {
                    case CAUSE.NONE: return t('not_active.do')
                    case CAUSE.DISABLED: return t('active.do_short.disabled')
                    default:return !this.alreadyDisabled ? t('not_active.do') : t('active.do_short.' + this.cause)
                }
            },
            stateDescription () {
                if (this.cause === CAUSE.NONE) {
                    return ''
                }

                return this.$t('sites.modes.actions.form.activity.state.not_active.cause.' + this.cause)
            },
            tooltipButton () {
                if (this.doLabel.length <= 15) {
                    return {}
                }

                return {
                    content: this.doLabel,
                    classes: 'icon-tooltip'
                }
            },
            tooltipOnlyButton () {
                const d = this.stateDescription
                return {
                    content: `${d}${d ? '<br>\n' : ''}${this.doLabel}`,
                    classes: 'icon-tooltip'
                }
            }
        },
        watch: {
            enabled: function () { this.checkTicker() },
            active_from: function () {
                this.from = this.active_from ? new Date(this.active_from) : null
                this.checkTicker()
            },
            active_until: function () {
                this.until = this.active_until ? new Date(this.active_until) : null
                this.checkTicker()
            },
        },
        methods: {
            silentPlay () {
                if (this.cause === CAUSE.DISABLED) {
                    this.toggle()
                }
            },
            silentStop () {
                if (this.cause === CAUSE.NONE) {
                    this.toggle()
                }
            },
            toggle () {
                const confirmAndSetDataForToggleSchedule = (prop, data) => {
                    if (!this.alreadyDisabled) {
                        this.alreadyDisabled = true
                        data.enabled = false
                        return true
                    }

                    if (!confirm(this.$t('sites.modes.actions.form.activity.state.not_active.' + prop))) {
                        this.alreadyDisabled = false
                        return false
                    }

                    data[prop] = true
                    return true
                }

                const data = {}
                switch (this.cause) {
                    case CAUSE.NONE:
                        data.enabled = false
                        break;
                    case CAUSE.DISABLED:
                        data.enabled = true
                        break;
                    case CAUSE.BEFORE:
                        if (!confirmAndSetDataForToggleSchedule('reset_active_until', data)) {
                            return
                        }
                        break;
                    case CAUSE.LATER:
                        if (!confirmAndSetDataForToggleSchedule('reset_active_from', data)) {
                            return
                        }
                        break
                    default:
                        new Error('unknown cause')
                        return
                }

                this.state = VARIANT.DANGER

                this.$emit('change', {
                    data,
                    onFinally: () => { this.state = VARIANT.DEFAULT },
                })
            },
            checkTicker() {
                if (!this.from && !this.until || [CAUSE.DISABLED, CAUSE.BEFORE].includes(this.cause)) {
                    if (this.ticker) {
                        this.now = null
                        clearInterval(this.ticker)
                        this.ticker = null
                    }

                    return
                }

                if (this.ticker) {
                    return
                }

                const tick = () => { this.now = new Date() }

                tick()
                this.ticker = setInterval(tick, 1000)
            },
        }
    }
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.box {
    padding: @spacing-s @spacing-m;
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid @grey-500;
    border-radius: @border-radius-l;

    &__controls {
        margin-left: auto;
        display: flex;
    }

    &__control {
        &:not(:last-child) {
            margin-right: @spacing-xs;
        }
    }
}

.button {
    margin: 0;
    padding: 0;
    background: 0 0;
    border: none;
    display: block;
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.icon {
    width: 24px;
    height: 24px;
    fill: @yellow-500;

    &_active {
        fill: @green-500;
    }
}
</style>
