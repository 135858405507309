
function constructorOf (value) {
    return Object.getPrototypeOf(value).constructor
}

function isRecord (value) {
    return constructorOf(value) === Object && Object.keys(Object.getPrototypeOf(value)).length === 0
}

function isRecordLike (value) {
    return typeof value === 'object' && value !== null && (isRecord(value) || Array.isArray(value))
}

const isScalar = (value) => {
    switch (typeof value) {
        case 'bigint':
        case 'boolean':
        case 'number':
        case 'string':
        case 'symbol':
        case 'undefined':
            return true
        default:
            return value === null
    }
}

const cloneInstance = original => {
    return Object.assign(Object.create(Object.getPrototypeOf(original)), original)
}

export default function clone (value) {
    // scalars & immutables
    if (isScalar(value) || value instanceof Blob) {
        return value
    }

    if (value instanceof Date) {
        return new Date(value)
    }

    if (!isRecordLike(value)) {
        return cloneInstance(value)
    }

    if (Array.isArray(value)) {
        return value.slice().map(clone)
    }

    const source = value

    return Object.keys(source).reduce((copy, key) => ({
        ...copy,
        [key]: clone(source[key]),
    }), {})
}