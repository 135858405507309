<script>
export default {
    name: 'SvgSprite',

    functional: true,

    props: {
        name: {
            type: String,
            required: true,
        },
    },

    render (h, { props, data }) {
        let icon = require(`@/assets/icons/${props.name}.svg`);

        if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
            icon = icon.default;
        }

        return h('svg', data, [
            h('use', { attrs: { 'xlink:href': icon.url} }),
        ])
    },
}
</script>
