import { DISPLAY_MANAGER_TYPE } from '@/enum/appearance.ts'

export default {
    computed: {
        currentManager () {
            if (this.snapshot.display_manager_type !== DISPLAY_MANAGER_TYPE.CUSTOM) {
                return null
            }

            return {
                avatar: this.snapshot.manager_logo || undefined,
                first_name: this.snapshot.manager_name || undefined,
            }
        },
    }
}