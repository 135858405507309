export const SHAPE = {
    ROUNDED: 'rounded',
    STRAIGHT: 'rectangular form'
}

export const LOGO_TYPE = {
    STANDART: 'standard',
    CUSTOM: 'custom'
}

export const BACKGROUND_MODE = {
    LIGHT: 'light',
    DARK: 'dark'
}

export const DISPLAY_MANAGER_TYPE = {
    STANDART: 'standard',
    CUSTOM: 'custom'
}

export const DISPLAY_TYPE = {
    CLOSE: 'close',
    OPEN: 'open',
    DISPLAYING_DELAY: 'displaying_delay'
}

export const LINKS_MODE = {
    STANDARD: 'standard',
    BLACK: 'black',
    GREY: 'grey',
    WIDGET: 'widget'
}

export const DIRECTION = {
    VERTICAL: 'vertical',
    HORIZONTAL: 'horizontal'
}

export const FORM_DISPLAY = {
    AT_BEGINNING_DIALOGUE: 'at_beginning_dialogue',
    AFTER_FIRST_MESSAGE: 'after_first_message',
}
