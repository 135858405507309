<template>
    <div
        :class="$style['preview-box']"
        :style="`
            width: ${350 + (isRightPosition ? 10 : 0) + offset.side}px;
            height: ${608 + offset.bottom}px;
        `"
    >
        <div
            :class="{
                [$style['preview-box__page']]: true,
                [$style['preview-box__page_right']]: isRightPosition
            }"
        />
        <ConsultantApp
            v-model="isOpen"
            :loading="false"
            :position="position"
            :offset-side="offset.side"
            :offset-bottom="offset.bottom"
            :tooltip-text="tooltipText"
            :is-offline="isOffline"
            :need-contact-form="needContactForm"
            :is-mobile="false"
            :name="name"
            :logo-link="logoLink"
            :main-color="`#${mainColor}`"
            :manager="manager"
            :messengers-links="messengerLinksProcessed"
            :domain-config="domainConfig"
            :domain="`test`"
            :show-messengers="alwaysShowMessengers || showMessengersBox"
            :rounded="rounded"
            :theme="theme"
            :inversion-color="inversionColor"
            :direction="direction"
            :icons-color="iconsColor"
            :personal-data-link="personalDataLink"
            :isFirstMessageSet="isFirstMessageSet"
            :contactFormDisplay="contactFormDisplay"
            @send-message="sendMessage"
            @show-messengers="showMessengersBox = true"
            @hide-messengers="showMessengersBox = false"
        >
            <div :class="$style['messages']">
                <slot />
                <div
                    v-for="message in messagesList"
                    :key="message.id"
                >
                    <ConsultantMessage
                        :message="message.fromMe ? message : replaceManagerMessageUser(message)"
                        :is-follow="message.isFollow"
                        :default-avatar="message.system"
                    />
                </div>
            </div>
        </ConsultantApp>
    </div>
</template>

<script>
import previewMessage from '@/utils/previewMessage.js'
import { messengerLinkPrefixesProto } from '../../enum/messenger-link-prefixes'
import { DISPLAY_MANAGER_TYPE, DIRECTION, LINKS_MODE } from '@/enum/appearance.ts'

const logoURL = 'https://s3-s1.retailcrm.tech/eu-central-1/retailcrm-static/branding/retailcrm/logo/logo_horiz.svg'

export default {
    name: 'ConsultantPreview',
    model: {
        event: 'toggle-opened',
        prop: 'opened'
    },

    props: {
        /** Состояние виджета */
        opened: {
            type: Boolean,
            default: true
        },

        /** Положение виджета */
        position: {
            type: String,
            default: 'right-bottom'
        },

        /** Имя виджета */
        name: {
            type: String,
            default: ''
        },

        /** Логотип виджета */
        logoLink: {
            type: String,
            default: null
        },

        /** Инвертировать цвета */
        inversionColor: {
            type: Boolean,
            default: false
        },

        /** Базовый цвет элементов интерфейса */
        mainColor: {
            type: String,
            default: '000'
        },

        /** Оффлан режим */
        isOffline: {
            type: Boolean,
            default: false,
        },

        /** Текст тултипа, отображаемого при
         * закрытом виджете
         **/
        tooltipText: {
            type: String,
            default: ''
        },

        /** Тема (dark | light) */
        theme: {
            type: String,
            default: 'light'
        },

        /** Скруглённые углы в интерфейсе */
        rounded: {
            type: Boolean,
            default: true
        },

        /** Нужна форма контактов */
        needContactForm: {
            type: Boolean,
            default: false
        },

        /** Показывать ссылки на соцсети */
        alwaysShowMessengers: {
            type: Boolean,
            default: false
        },

        /** Ссылки на соцсети */
        messengersLinks: {
            type: Array,
            default: () => []
        },

        /** Информация о менеджере */
        manager: {
            type: Object,
            default: null
        },

        /** Информация о режиме отображения менеджера */
        displayManagerType: {
            type: String,
            default: DISPLAY_MANAGER_TYPE.STANDARD
        },

        /** URL текущей crm */
        crmUrl: {
            type: String,
            default: '#'
        },

        /** Возможность отключить отображение бренда в футере */
        showBrand: {
            type: Boolean,
            default: true
        },

        /** Дефолтный набор сообщений в превью */
        messages: {
            type: Array,
            default: null
        },

        offset: {
            type: Object,
            default: () => ({
                bottom: 0,
                side: 0
            })
        },

        direction: {
            type: String,
            default: DIRECTION.VERTICAL
        },

        iconsColor: {
            type: String,
            default: LINKS_MODE.STANDARD
        },

        personalDataLink: {
            type: String,
            default: '',
        },

        isFirstMessageSet: {
            type: Boolean,
            default: false,
        },

        contactFormDisplay: {
            type: String,
            default: ""
        }
    },

    data () {
        return {
            newMessages: [],
            showMessengersBox: false
        }
    },

    computed: {
        isOpen: {
            get () {
                return this.opened
            },
            set (event) {
                this.$emit('toggle-opened', event)
            }
        },

        isRightPosition () {
            return this.position === 'right-bottom'
        },

        messagesList () {
            if (this.messages) {
                return this.messages
            }

            // Быстрые ответы
            let suggestions = [
                {title: this.$t('messages.suggestion.about_promo')},
                {title: this.$t('messages.suggestion.about_order')},
                {title: this.$t('messages.suggestion.about_delivery_and_payment')},
            ]

            return [
                previewMessage(this.$t('messages.start'), this.$t('messages.name'), false, suggestions),
                previewMessage(this.$t('messages.answer'))
            ]
        },

        /**
         * Опции для отображения бренда в подписи
         */
        domainConfig () {
            return {
                logo: logoURL,
                URL: 'https://retailcrm.ru/',
                free: this.showBrand && this.crmUrl.includes('retailcrm')
            }
        },

        messengerLinksProcessed () {
            if (this.messengersLinks) {
                return [...this.messengersLinks].map((link) => {
                    if (link.type !== 'telegram' && link.type in messengerLinkPrefixesProto) {
                        return { ...link, url: messengerLinkPrefixesProto[link.type] + link.url }
                    }

                    // Затрагивает пока что только Telegram, протокол там не хранится.
                    if (link.prefix) {
                        return { ...link, url: 'https://' + link.prefix + link.url }
                    }

                    return link
                })
            }

            return []
        }
    },

    methods: {
        sendMessage (message) {
            this.newMessages.push(previewMessage(message, null, true))
        },

        replaceManagerMessageUser (message) {
            if (this.displayManagerType === DISPLAY_MANAGER_TYPE.CUSTOM) {
                return { ...message, user: this.manager }
            }

            return message
        }
    }
}
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.preview-box {
    margin-left: 32px;
    width: 390px;
    height: 608px;
    position: sticky;
    top: 0;
    transform: translate3d(0, 0, 0);

    &__page {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 80px;
        height: 80px;
        border-left: 1px solid @grey-500;
        border-bottom: 1px solid @grey-500;

        &_right {
            right: 0;
            left: auto;
            border-right: 1px solid @grey-500;
            border-left: none;
        }
    }
}

:global(#retailcrm-consultant-app) .messages {
    flex: 1 0 1px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: none;
    max-height: 100%;
    padding-top: 16px;
}

:global(#retailcrm-consultant-app .window) {
    min-height: 540px;
}
</style>
