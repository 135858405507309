<template>
    <UiCollapseBox
        id="shedule"
        v-model="expanded"
        :collapsible="!savingAvailable"
    >
        <template #title>
            {{ $t('sites.modes.actions.form.schedule.title') }}
        </template>

        <template #description>
            {{ $t('sites.modes.actions.form.schedule.description') }}
        </template>

        <template #body-content>
            <div class="ui-content-box ui-content-box_timetable">
                <div>
                    <UiText size="body" accent>
                        {{ $t('sites.modes.actions.form.schedule.from.label') }}
                    </UiText>

                    <UiRow mt="s">
                        <UiRadio
                            :label="$t('sites.modes.actions.form.schedule.from.disabled')"
                            v-model="form.from.enabled"
                            :value="false"
                        />

                        <UiRow mt="s">
                            <UiRadio
                                :label="$t('sites.modes.actions.form.schedule.from.enabled')"
                                v-model="form.from.enabled"
                                :value="true"
                            />
                        </UiRow>

                        <UiRow v-show="form.from.enabled" mt="s">
                            <div class="ui-flex ui-flex_center">
                                <div class="ui-form-area_timetable">
                                    <UiDatepicker
                                        v-model="fromDate"
                                        :min-date="fromMinDate"
                                        :max-date="untilDate"
                                    />
                                </div>

                                <div class="ui-flex__col_content">
                                    <div class="ui-form-area_timetable-min">
                                        <UiTimepicker v-model="form.from.time"/>
                                    </div>
                                </div>
                            </div>
                        </UiRow>
                    </UiRow>
                </div>

                <UiRow mt="m">
                    <UiText size="body" accent>
                        {{ $t('sites.modes.actions.form.schedule.until.label') }}
                    </UiText>

                    <UiRow mt="s">
                        <UiRadio
                            v-model="form.until.enabled"
                            :label="$t('sites.modes.actions.form.schedule.until.disabled')"
                            :value="false"
                        />
                    </UiRow>

                    <UiRow mt="s">
                        <UiRadio
                            v-model="form.until.enabled"
                            :label="$t('sites.modes.actions.form.schedule.until.enabled')"
                            :value="true"
                        />
                    </UiRow>

                    <UiRow v-show="form.until.enabled" mt="s">
                        <div class="ui-flex ui-flex_center">
                            <div class="ui-form-area_timetable">
                                <UiDatepicker
                                    v-model="untilDate"
                                    :min-date="untilMinDate"
                                />
                            </div>

                            <div class="ui-flex__col_content">
                                <div class="ui-form-area_timetable-min">
                                    <UiTimepicker v-model="form.until.time" />
                                </div>
                            </div>
                        </div>
                    </UiRow>
                </UiRow>
            </div>
        </template>

        <template #footer-content>
            <FooterButtons
                :hide-save="hideSave"
                :saving="saving"
                @close="onCollapse"
                @save="save"
            />
        </template>
    </UiCollapseBox>
</template>

<script>
    import FooterButtons from "./FooterButtons"
    import actionSectionMixin from '../mixins/actionSectionMixin'

    const formatDateAndTime = (d) => {
        if (!d) {
            d = new Date()
            d.setHours(0,0,0,0)
        }

        const z = (v) => v < 10 ? '0' + v : v

        return {
            date: `${z(d.getDate())}.${z(d.getMonth() + 1)}.${d.getFullYear()}`,
            time: `${z(d.getHours())}:${z(d.getMinutes())}`,
        }
    }

    const formatDateToSend = (value) => {
        const dc = value.split('.')

        return `${dc[2]}-${dc[1]}-${dc[0]}`
    }

    const initDT = (type, form, modelValue) => {
        if (!form) {
            if (!modelValue) {
                return {
                    enabled: false,
                    date: '',
                    time: '',
                }
            }

            return {
                enabled: true,
                ...formatDateAndTime(new Date(modelValue))
            }
        }

        const f = form[type]

        if (!f.enabled && f.date) {
            f.date = ''
            f.time = ''

            return f
        }

        if (f.enabled && f.date) {
            return f
        }

        let dt = null

        if (modelValue) {
            modelValue = new Date(modelValue)
            dt = modelValue
        }

        const today = new Date()
        today.setHours(0, 0, 0, 0)

        const secOffset = (24*60*60*1000) * 5
        if (type === 'from') {
            const until = form.until
            if (until.enabled && until.date) {
                const ud = new Date(formatDateToSend(until.date))
                if (!dt || dt > ud) {
                    ud.setTime(ud.getTime() - secOffset)
                    ud.setHours(0, 0, 0, 0)
                    if (ud < today) {
                        dt = today
                    } else {
                        dt = ud
                    }
                }
            }
        } else {
            const from = form.from
            if (from.enabled && from.date) {
                const fd = new Date(formatDateToSend(from.date))
                if (!dt || dt < fd) {
                    fd.setTime(fd.getTime() + secOffset)
                    fd.setHours(0, 0, 0, 0)
                    dt = fd
                }
            } else {
                today.setTime(today.getTime() + secOffset)
                dt = today
            }
        }

        const n = formatDateAndTime(dt)
        f.date = n.date
        f.time = n.time

        return f
    }

    export default {
        components: {
            FooterButtons
        },

        mixins: [ actionSectionMixin ],

        props: {
            /**
             * @type action
             */
            action: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                expanded: false,
                saving: false,
                error: null,
                form: this.defaultForm()
            }
        },
        computed: {
            hideSave () {
                return this.action.id > 0 && !this.savingAvailable
            },
            fromDate: {
                get () {
                    return this.stringToDate(this.form.from.date)
                },

                set (val) {
                    this.form.from.date = this.dateToString(val)
                }
            },
            fromMinDate () {
                return this.stringToDate(formatDateAndTime().date)
            },
            untilDate: {
                get () {
                    return this.stringToDate(this.form.until.date)
                },

                set (val) {
                    this.form.until.date = this.dateToString(val)
                }
            },
            untilMinDate () {
                return this.stringToDate(this.form.from.date) || this.stringToDate(formatDateAndTime().date)
            }
        },
        watch: {
            'action.active_from': function () {
                if (this.resetting) {
                    return
                }

                const v = this.action.active_from
                if (!v) {
                    this.form.from.enabled = false
                    return
                }

                this.form.from = initDT('from', this.form, v)
            },
            'action.active_until': function () {
                if (this.resetting) {
                    return
                }

                const v = this.action.active_until
                if (!v) {
                    this.form.until.enabled = false
                    return
                }

                this.form.until = initDT('until', this.form, v)
            },
            'form.from.enabled': function () {
                if (this.resetting) {
                    return
                }
                
                initDT('from', this.form, this.action.active_from)
            },
            'form.until.enabled': function () {
                if (this.resetting) {
                    return
                }
                
                initDT('until', this.form, this.action.active_until)
            },
        },
        methods: {
            stringToDate (string) {
                const parts = string.split('.');

                return  new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
            },

            dateToString (val) {
                let date = new Date(val);
                let day = date.getDate().toString().padStart(2, '0');
                let month = (date.getMonth() + 1).toString().padStart(2, '0');
                let year = date.getFullYear();
                let formattedDateString = `${day}.${month}.${year}`;

                return formattedDateString
            },

            save() {
                this.saving = true

                const data = {}

                for (const prop of ['from', 'until']) {
                    const f = this.form[prop]
                    if (!f.enabled) {
                        data['reset_active_' + prop] = true
                        continue
                    }

                    let dt = formatDateToSend(f.date)

                    if (!f.time) {
                        dt += 'T00:00:00'
                    } else {
                        const tc = f.time.split(':')
                        if (tc.length !== 2) {
                            dt += 'T00:00:00'
                        } else {
                            let z = (v, max) => {
                                v = parseInt(v)
                                if (v < 0) { v = 0 }
                                if (v > max) { v = max }

                                return (v < 10 ? '0' : '') + v
                            }

                            dt += `T${z(tc[0], 23)}:${z(tc[1], 59)}:00`
                        }
                    }


                    const tz = new Date().getTimezoneOffset() / 60 * -1
                    if (tz === 0) {
                        dt += 'Z'
                    } else {
                        dt += `${tz < 0 ? '-' : '+'}${tz > -10 & tz < 10 ? '0' : ''}${tz}:00`
                    }

                    data['active_' + prop] = dt
                }

                const f = this.form
                if (f.list) {
                    data.list = f.ids
                }

                this.$emit('save', {
                    data,
                    onSuccess: () => { this.onSave() },
                    onError: () => {
                        this.error = this.$t('common.error_while_saving')
                        setTimeout(() => {
                            this.error = null
                        }, 5000)
                    },
                    onFinally: () => { this.saving = false }
                })
            },

            defaultForm () {
                return {
                    from: initDT('from', null, this.action.active_from),
                    until: initDT('until', null, this.action.active_until),
                }
            }
        }
    }
</script>

<style scoped>

</style>
