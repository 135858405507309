var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.onlyButton)?_c('div',[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltipOnlyButton),expression:"tooltipOnlyButton"}],class:{
                [_vm.$style.button]: true,
                [_vm.$style.button_active]: _vm.active
            },attrs:{"type":"button"},on:{"click":_vm.toggle}},[_c('SvgSprite',{class:{
                    [_vm.$style.icon]: true,
                    [_vm.$style.icon_active]: _vm.active
                },attrs:{"name":_vm.active ? 'play' : 'pause'}})],1)]):_c('div',{class:_vm.$style.box},[_c('div',{staticClass:"ui-flex ui-flex_center"},[_c('SvgSprite',{class:{
                    [_vm.$style.icon]: true,
                    [_vm.$style.icon_active]: _vm.active
                },attrs:{"name":_vm.active ? 'play' : 'pause'}}),_c('div',{staticClass:"ui-flex__col"},[_c('UiText',{attrs:{"size":"body"}},[_vm._v(" "+_vm._s(_vm.$t('sites.modes.actions.form.activity.state.' + (_vm.active ? '' : 'not_') + 'active.current'))+" ")])],1),(!_vm.active)?_c('div',{staticClass:"ui-flex__col"},[_c('UiTooltip',[_vm._v(" "+_vm._s(_vm.stateDescription)+" ")])],1):_vm._e()],1),_c('div',{class:_vm.$style.box__controls},[_c('UiButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltipButton),expression:"tooltipButton"}],class:_vm.$style.box__control,attrs:{"disabled":_vm.isNew,"state":_vm.state,"appearance":"tertiary"},on:{"click":_vm.toggle}},[_vm._v(" "+_vm._s(_vm.doLabelShort)+" ")]),_vm._t("additionalActions")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }