export default {
    dateTime: function (i18n, moment) {
        return function (value, locale) {
            locale = locale || i18n.locale

            return moment(value).format(i18n._t('moment.dateTimeFormat', locale, i18n.messages))
        }
    },
    date: function (i18n, moment) {
        return function (value, locale) {
            locale = locale || i18n.locale

            return moment(value).format(i18n._t('moment.dateFormat', locale, i18n.messages))
        }
    },
}
