<template>
    <div :class="$style.panel">
        <div :class="$style.panel__btn" v-show="!hideSave">
            <UiButton
                :locked="saving"
                size="sm"
                class="ui-btn-main"
                @click="save"
            >
                <UiIcon v-if="saving" name="circleLoading" />
                {{ $t('common.btn_save') }}
            </UiButton>
        </div>

        <div :class="$style.panel__btn">
            <UiButton
                appearance="secondary"
                size="sm"
                @click="close"
            >
                {{ $t('common.btn_close') }}
            </UiButton>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            saving: {
                type: Boolean,
                default: false
            },
            hideSave: {
                type: Boolean,
                default: true,
            }
        },
        methods: {
            save () {
                this.$emit('save')
            },
            close () {
                this.$emit('close')
            }
        }
    }
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.panel {
    display: flex;

    &__btn {
        &:not(:last-child) {
            margin-right: @spacing-s;
        }
    }
}
</style>
