<template>
<div class="banner" v-if="show">
    <!--<img class="banner__img" src="/static/img/auto-actions.svg" />-->
    <div class="banner__close"  @click="hide">
        <UiIcon name="clear"/>
    </div>
    <div class="banner__inner">
        <div class="banner__content">
            <UiText class="banner__title" size="title-02">
                {{ $t('sites.modes.actions.banner.title') }}
            </UiText>
            <UiText class="banner__descript" size="md">
                {{ $t('sites.modes.actions.banner.description') }}
            </UiText>

            <div class="banner-list">
                <div
                    class="banner-list__item"
                    v-for="i in 6"
                    :key="i"
                >
                    {{ $t('sites.modes.actions.banner.list.i' + i) }}
                </div>
            </div>

            <div class="banner-footer">
                <div class="banner-footer__col">
                    <UiButton size="sm" class="banner__btn-primary" @click="hide">
                        {{ $t('sites.modes.actions.banner.hide_btn') }}
                    </UiButton>
                </div>
                <div class="banner-footer__col">
                    <UiButton appearance="secondary" size="sm" class="banner__btn_secondary" @click="openHelp">
                        {{ $t('sites.modes.actions.banner.desc_btn') }}
                        <UiIcon name="externalLink" />
                    </UiButton>
                </div>
            </div>
        </div>
        <div class="banner__img-wrap">
            <img class="banner__img" src="/static/img/auto-actions.svg" />
        </div>
    </div>
</div>
</template>
<script>
import {debug} from './../main'
export default {
    props: {
        localStorageKey: {
            type: String,
            default: 'hide_actions_banner_at',
        }
    },
    data () {
        let show = true
        try {
            show = !localStorage.getItem(this.localStorageKey)
        } catch (e) {
            debug(e.message)
        }

        return {
            show: show
        }
    },
    methods: {
        hide () {
            this.show = false
            try {
                localStorage.setItem(this.localStorageKey, +new Date())
            } catch (e) {
                debug(e.message)
            }
        },
        openHelp () {
            window.open(this.$t('app.help_domain') + "/Users/Chats/ConnectionOfMessengersAndChats/LiveChat")
        }
    }
}
</script>
<style>
.banner {
    max-width: 1000px;
    min-width: 800px;
    width: 100%;
    margin-bottom: 32px;
    min-height: 320px;
    background: #6528D7;
    box-shadow: 0px 2px 4px rgba(30, 34, 72, 0.16);
    border-radius: 8px;
    position: relative;
    padding: 34px 0 0 48px;
}
.banner__content {
    width: 485px;
    padding-bottom: 35px;
}
.banner__inner {
    display: flex;
    justify-content: space-between;
}
.banner__img {
    max-width: 450px;
}
.banner__img-wrap {
    flex: 1 0 1px;
    min-width: 0;
    max-width: 478px;
}
.banner__img {
    width: 100%;
    height: auto;
}
.banner__content .banner__title {
    color: #FFB066;
}
.banner__content .banner__descript {
    color: white;
    margin-top: 9px;
}
.banner-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 17px;
}
.banner-list__item {
    width: 50%;
    position: relative;
    margin-top: 4px;
    color: #E0D4F7;
    font-size: 14px;
    line-height: 20px;
    padding-left: 14px;
}
.banner-list__item:nth-child(1),
.banner-list__item:nth-child(2) {
    margin-top: 0;
}
.banner-list__item::before {
    content: "";
    width: 4px;
    height: 4px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
    background: #E0D4F7;
}
.banner-footer {
    margin-top: 27px;
    display: flex;
}
.banner-footer__col + .banner-footer__col {
    margin-left: 16px;
}
.banner-footer .banner__btn-primary {
    background: white;
    color: #6528D7;
}
.banner-footer .banner__btn-primary:hover,
.banner-footer .banner__btn-primary:active {
    box-shadow: none;
}
.banner-footer .banner__btn-primary:active {
    background: #e0d4f7;
}

.banner-footer .banner__btn_secondary {
    background: #6528D7;
    color: white;
    border: 1px solid white;
}
.banner-footer .banner__btn_secondary svg {
    fill: white;
}
.banner-footer .banner__btn_secondary:hover {
   color: white;
   border: 1px solid white;
}
.banner-footer .banner__btn_secondary:hover svg {
   fill: white !important;
}
.banner-footer .banner__btn_secondary:active {
   color: #e0d4f7;
   border: 1px solid #e0d4f7;
   background: #6528D7;
}
/*.banner__img {
    position: absolute;
    top: 30px;
    right: 12px;
    height: auto;
    width: 450px;
}*/

.banner__close {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 15px;
    right: 22px;
    cursor: pointer;
}
.banner__close svg {
    fill: white;
}

@media screen and (max-width: 1320px) {
    .banner-list {
        max-width: 300px;
    }
    .banner-list__item {
        width: 100%;
    }
    .banner__content {
        width: 350px;
    }
    .banner__img-wrap {
        display: flex;
        align-items: center;
    }
}
</style>
