<template>
    <div>
        <UiLoader v-if="loading"/>

        <ActionSection
            v-else-if="editedId !== null"
            :action-id="editedId"
            :site="site"
            :crm-url="crm.url"
            :managers="crm.managers"
            ref="action"
            @action-created="created($event)"
            @change="changeAction(editedId, $event)"
            @action-removed="removed($event)"
        />

        <div v-else>
            <MainBanner />

            <div :class="$style.row">
                <div class="ui-flex ui-flex_center">
                    <UiText size="title-02" accent>
                        {{ $t('sites.modes.actions.title_long') }}
                    </UiText>

                    <template v-if="selectedIds.length">
                        <div class="ui-flex__col ui-flex__col_head">
                            <UiText :class="$style.row__count" size="body">
                                {{ $t('sites.modes.actions.selected', {cnt: selectedIds.length}) }}
                            </UiText>
                        </div>

                        <div class="ui-flex__col ui-flex__col_head">
                            <UiSelect max-width-dropdown>
                                <template #target="selectData">
                                    <UiButton
                                        :active="selectData.data.active"
                                        appearance="tertiary"
                                    >
                                        {{ $t('sites.modes.actions.label') }}
                                    </UiButton>
                                </template>

                                <UiOption
                                    :label="$t('sites.modes.actions.start')"
                                    value="play"
                                    @click="startSelected"
                                >
                                    <template #iconPrefix>
                                        <SvgSprite
                                            :class="$style.row__icon"
                                            name="play"
                                        />
                                    </template>
                                </UiOption>

                                <UiOption
                                    :label="$t('sites.modes.actions.stop')"
                                    value="pause"
                                    @click="stopSelected"
                                >
                                    <template #iconPrefix>
                                        <SvgSprite
                                            :class="$style.row__icon"
                                            name="pause"
                                        />
                                    </template>
                                </UiOption>
                            </UiSelect>
                        </div>
                    </template>
                </div>

                <div :class="$style.row__add">
                    <UiButton size="md" @click="add">
                        <UiIcon name="addCircleOutlined"/>
                        {{ $t('sites.modes.actions.add') }}
                    </UiButton>
                </div>
            </div>

            <UiRow :class="$style.table" mt="m">
                <table :class="$style.table__inner">
                    <tr>
                        <th>
                            <UiCheckbox
                                :indeterminate="selectedIds.length > 0 && selectedIds.length < actions.length"
                                :model="selectedIds.length > 0"
                                @change="selectedIds = $event ? actions.map(o => o.id) : []"
                            />
                        </th>

                        <UiText tag="th" size="tiny" accent>
                            {{ $t('sites.modes.actions.header.name') }}
                        </UiText>

                        <UiText tag="th" size="tiny" accent>
                            {{ $t('sites.modes.actions.header.status') }}
                        </UiText>

                        <UiText tag="th" size="tiny" accent>
                            {{ $t('sites.modes.actions.header.number_of_operations') }}
                        </UiText>

                        <UiText tag="th" size="tiny" accent>
                            {{ $t('sites.modes.actions.header.number_of_successful') }}
                        </UiText>

                        <UiText tag="th" size="tiny" accent>
                            {{ $t('sites.modes.actions.header.number_of_failures') }}
                        </UiText>

                        <UiText tag="th" size="tiny" accent>
                            {{ $t('sites.modes.actions.header.last_successful_at') }}
                        </UiText>

                        <UiText tag="th" size="tiny" accent>
                            {{ $t('sites.modes.actions.header.created_at') }}
                        </UiText>
                    </tr>

                    <tr v-if="!actions.length">
                        <td :class="$style.list_empty" colspan="8">
                            {{ $t('sites.modes.actions.empty_text') }}
                        </td>
                    </tr>

                    <tr
                        v-else
                        v-for="(action, i) in actions"
                        :key="i"
                    >
                        <td>
                            <UiCheckbox
                                v-model="selectedIds"
                                :value="action.id"
                            />
                        </td>

                        <td :class="$style['action-list-title-cell']">
                            <UiText
                                size="body"
                                ellipsis
                                link
                                @click.native="editedId = action.id"
                            >
                                {{ action.name }}
                            </UiText>
                        </td>

                        <td>
                            <ActivitySection
                                :enabled="actions[i].enabled"
                                :active_from="actions[i].active_from"
                                :active_until="actions[i].active_until"
                                :ref="'activity' + action.id"
                                only-button
                                @change="changeActivity(action.id, $event)"
                            />
                        </td>

                        <td>
                        <UiText size="body">
                            {{ action.all }}
                        </UiText>
                        </td>

                        <UiText
                            :class="$style.table__answers"
                            size="body"
                            tag="td"
                        >
                            {{ answers(action).percent }}%
                        </UiText>

                        <UiText
                            v-tooltip="tooltipFailures(action)"
                            :class="$style.table__failures"
                            size="body"
                            tag="td"
                        >
                            {{ failures(action).percent}}%
                        </UiText>

                        <td>
                            <UiText
                                v-if="!action.last_execution_at"
                                size="body"
                            >
                                &mdash;
                            </UiText>

                            <UiText
                                v-else
                                size="body"
                            >
                                {{ action.last_execution_at | dateTime }}
                            </UiText>
                        </td>

                        <UiText size="body" tag="td">
                            {{ action.created_at | dateTime }}
                        </UiText>
                    </tr>
                </table>
            </UiRow>
        </div>
        </div>
</template>

<script>
    /**
     * @typedef {{
     *     type: string,
     *     key: string,
     *     value: string
     * }} condition
     *
     * @typedef {{
     *     urls: condition[],
     *     titles: condition[],
     *     utms: condition[],
     *     utms_mode: string,
     *     online_managers: {
     *         mode: string,
     *         list: number[]
     *     }
     * }} conditions
     *
     * @typedef {{
     *     text: string,
     *     name: boolean,
     *     name_required: boolean,
     *     email: boolean,
     *     email_required: boolean,
     *     phone: boolean,
     *     phone_required: boolean
     * }} contact_form
     *
     * @typedef {{
     *     id: number,
     *     name: string,
     *     type: string,
     *     enabled: boolean,
     *     created_at: Date,
     *     active_from: Date,
     *     active_until: Date,
     *     messages: string[],
     *     contact_form: contact_form
     *     conditions: conditions,
     *     user_leaves_page: boolean,
     *     sec_on_site: number,
     *     sec_on_page: number,
     *     allow_retry: boolean,
     *     sec_after_go_to_page: number,
     *     sec_after_close: number,
     *     last_execution_at: Date,
     *     all: number,
     *     answers: number,
     *     transitions: number,
     *     closing: number
     * }} action
     */

    import ActionSection from "./ActionsSettings/ActionSection";
    import ActivitySection from "./ActionsSettings/ActivitySection";
    import MainBanner from "../MainBanner"

    export default {
        components: {
            ActivitySection,
            ActionSection,
            MainBanner
        },

        props: {
            /**
             * @type site
             */
            site: {
                type: Object,
                required: true
            },
            /**
             * @type crm
             */
            crm: {
                type: Object,
                required: true
            }
        },
        data () {
            return {
                /**
                 * @type number[]
                 */
                selectedIds: [],
                /**
                 * @type number
                 */
                editedId: null,
                /**
                 * @type action[]
                 */
                actions: [],
                selectAll: false,
                loading: false,
            }
        },
        mounted () {
            this.$root.$on('move-to-list', () => {
                this.editedId = null
            })

            this.fetchActions()
        },
        methods: {
            startSelected () {
                for (const id of this.selectedIds){
                    this.$refs['activity' + id][0].silentPlay()
                }
                this.selectedIds = []
                this.selectAll = false
            },
            stopSelected () {
                for (const id of this.selectedIds){
                    this.$refs['activity' + id][0].silentStop()
                }
                this.selectedIds = []
                this.selectAll = false
            },
            add () {
                this.editedId = 0
            },
            created ({id, name, enabled, created_at, active_from, active_until}) {
                this.actions.push({
                    id,
                    name,
                    enabled,
                    created_at: new Date(created_at),
                    active_from,
                    active_until,
                    last_execution_at: null,
                    all: 0,
                    answers: 0,
                    closing: 0,
                    transitions: 0,
                })
                this.editedId = id
            },
            removed (id) {
                if (this.editedId === id) {
                    this.editedId = null
                }

                let index = this.actions.findIndex((a) =>  (a.id === id))
                if (index === -1) {
                    return
                }

                const action = this.actions[index]
                this.actions.splice(index, 1)

                index = this.selectedIds.findIndex((id) => (id === action.id))
                if (index === -1) {
                    return
                }
                this.selectedIds.splice(index, 1)
            },
            changeAction (id, changedAction) {
                if (!id) {
                    return
                }

                let edited = null
                for (const action of this.actions) {
                    if (action.id === id) {
                        edited = action
                        break
                    }
                }

                if (!edited) {
                    return
                }

                for (const prop of ['name', 'enabled', 'active_from', 'active_until']) {
                    const value = changedAction[prop]
                    if (value !== undefined) {
                        edited[prop] = value
                    }
                }
            },
            changeActivity (id, {data, onError, onFinally}) {
                this.$http.put(`sites/${this.site.id}/actions/${id}`, data)
                    .then((r) => {
                        const a = r.body
                        this.changeAction(id, {
                            enabled: a.enabled,
                            active_from: a.active_from || null,
                            active_until: a.active_until || null,
                        })
                    })
                    .catch((r) => {
                        if (r.status === 400 && r.body.errors.includes('messages required')) {
                            this.editedId = id

                            this.$nextTick(
                                setTimeout(() => {
                                    const type = this.$refs['action'] && this.$refs['action'].$refs['type'] || null
                                    if (!type) {
                                        return
                                    }

                                    type.btnClicks++
                                }, 2000)
                            )
                            return
                        }

                        onError(r)
                    })
                    .finally(onFinally)
            },
            fetchActions () {
                const actions = []

                this.loading = true
                this.$http.get(`sites/${this.site.id}/actions`)
                    .then((r) => {
                        r.body.forEach((a) => {
                            actions.push({
                                id: a.id,
                                name: a.name,
                                enabled: a.enabled,
                                created_at: new Date(a.created_at),
                                active_from: a.active_from,
                                active_until: a.active_until,
                                last_execution_at: a.last_execution_at ? new Date(a.last_execution_at) : null,
                                all: a.all,
                                answers: a.answers,
                                closing: a.closing,
                                transitions: a.transitions,
                            })
                        })
                    })
                    .finally(() => {
                        this.actions = actions
                        this.loading = false
                    })
            },
            doSelectAll () {
                this.selectedIds = []
                if (!this.selectAll) {
                    this.actions.forEach((a) => { this.selectedIds.push(a.id)})
                }
            },
            answers (action) {
                return {
                    cnt: action.answers,
                    percent: action.all > 0 ? Math.round(action.answers * 100 / action.all) : 0
                }
            },
            failures (action) {
                const f = action.closing + action.transitions
                return {
                    cnt: f,
                    percent: action.all > 0 ? Math.round(f * 100 / action.all) : 0
                }
            },
            tooltipFailures (action) {
                const failures = this.failures(action)
                if (failures.percent === 0) {
                    return {}
                }

                const closingPercent =  failures.cnt > 0 ? Math.round(action.closing * 100 / failures.cnt) : 0
                const transitionsPercent =  failures.cnt > 0 ? Math.round(action.transitions * 100 / failures.cnt) : 0

                const trKey = 'sites.modes.actions.failures.'
                let msg = this.$t(trKey + 'close', {
                    cnt: action.closing,
                    percent: closingPercent,
                })
                msg += "<br>" + this.$t(trKey + 'transition', {cnt: action.transitions, percent: transitionsPercent})

                return {

                    content: msg,
                    classes: 'icon-tooltip'
                }
            },
            resetActionId () {
                this.editedId = null
            }
        },
        watch: {
            selectedIds () {
                this.selectAll = this.selectedIds.length === this.actions.length
            },
            site () {
                this.fetchActions()
            }
        }
    }
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.table {
    width: 100%;
    max-width: 1000px;
    border: 1px solid @grey-500;
    border-radius: @border-radius-l;
    background-color: #fff;
    overflow: hidden;

    &__inner {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
    }

    th,
    td {
        padding: 0 @spacing-xs;

        &:first-child {
            padding-left: @spacing-m;
        }

        &:last-child {
            padding-right: @spacing-m;
        }
    }

    th {
        height: 52px;
        text-align: left;
        background-color: @grey-200;
        border-bottom: 1px solid @grey-500;
    }

    td {
        height: 56px;
    }

    tr:not(:last-child) {
        td {
            border-bottom: 1px solid @grey-500;
        }
    }

    &__answers {
        color: @green-600 !important;
    }

    &__failures {
        color: @red-600 !important;
    }
}

.row {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1000px;

    &__count {
        color: @grey-900 !important;
    }

    &__add {
        margin-left: auto;
    }

    &__icon {
        width: 24px;
        height: 24px;
    }
}

.list_empty {
    text-align: left !important;
    color: @grey-900;
    font-size: 12px;
}

.action-list-title-cell {
    max-width: 400px;
}
</style>
