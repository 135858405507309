<template>
    <UiCollapseBox
        id="condition"
        v-model="expanded"
        :collapsible="!savingAvailable"
    >
        <template #title>
            {{ $t('sites.modes.actions.form.condition.title') }}
        </template>

        <template #description>
            {{ $t('sites.modes.actions.form.condition.description') }}
        </template>

        <template #body-content>
            <div class="ui-content-box ui-content-box_status">
                <UiSwitch
                    v-model.number="form.enableSecOnSite"
                    :label="$t('sites.modes.actions.form.condition.delayed_timer')"
                    :desc="$t('sites.modes.actions.form.condition.timer_description')"
                >
                    {{ $t('sites.modes.actions.form.condition.delayed_timer') }}
                </UiSwitch>

                <UiRow v-show="form.enableSecOnSite" :class="$style.duration" mt="xs">
                    <InputDuration
                        v-model="form.secOnSite"
                        :error="errSecOnSite"
                        default-sec="20"
                        ref="secOnSite"
                    />
                </UiRow>

                <UiRow mt="m">
                    <UiSwitch v-model="form.allowRetry">
                        {{ $t('sites.modes.actions.form.condition.allow_retry') }}
                    </UiSwitch>
                </UiRow>

                <div v-show="form.allowRetry">
                    <UiRow :class="$style.duration" mt="xs">
                        <UiField
                            :label="$t('sites.modes.actions.form.condition.sec_after_close')"
                            :tooltip-text="$t('sites.modes.actions.form.condition.sec_after_close_hint')"
                            tooltip-max-width="220"
                        >
                            <InputDuration
                                v-model="form.secAfterClose"
                                default-sec="600"
                                :error="errSecAfterClose"
                                ref="secAfterClose"
                            />
                        </UiField>
                    </UiRow>

                    <UiRow :class="$style.duration" mt="s">
                        <UiField
                            :label="$t('sites.modes.actions.form.condition.sec_after_go_to_page')"
                            :tooltip-text="$t('sites.modes.actions.form.condition.sec_after_go_to_page_hint')"
                            tooltip-max-width="220"
                        >
                            <InputDuration
                                v-model="form.secAfterGoToPage"
                                :error="errSecAfterGoToPage"
                                default-sec="300"
                            />
                        </UiField>
                    </UiRow>
                </div>

                <div class="ui-content-box__row">
                    <UiText size="paragraph" accent>
                        {{ $t('sites.modes.actions.form.condition.page_settings') }}
                    </UiText>

                    <UiText :class="$style.desc" size="body">
                        {{ $t('sites.modes.actions.form.condition.page_settings_hint') }}
                    </UiText>

                    <UiText color="primary" v-if="displayingDelay > 0">
                        {{ $t('sites.modes.actions.form.condition.displaying_delay_warn', {sec: displayingDelay}) }}
                    </UiText>
                </div>

                <div class="ui-content-box__row_radio-group">
                    <div class="ui-content-box__row_radio">
                        <UiRadio
                            :label="$t ('sites.modes.actions.form.condition.run_types.no_delay')"
                            v-model="form.runType" value="no_delay"
                        />
                    </div>

                    <div class="ui-content-box__row_radio">
                        <UiRadio
                            :label="$t ('sites.modes.actions.form.condition.run_types.after_sec')"
                            v-model="form.runType" value="after_sec"
                        />
                    </div>

                    <div v-show="form.runType === 'after_sec'">
                        <div class="ui-content-box__row_radio ui-content-box__row_radio-inner">
                            <div class="ui-flex ui-flex_center">
                                <InputDuration
                                    v-model="form.secOnPage"
                                    default-sec="10"
                                    :tooltip="$t ('sites.modes.actions.form.condition.run_types.after_sec_hint')"
                                    :error="errSecOnPage"
                                    ref="secOnPage"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="ui-content-box__row_radio">
                        <UiRadio
                            :label="$t('sites.modes.actions.form.condition.run_types.leaves_page')"
                            v-model="form.runType" value="leaves_page"
                        />
                    </div>
                </div>

                <UiRow mt="m" mb="xs">
                    <UiText size="paragraph" accent>
                        {{ $t('sites.modes.actions.form.condition.additionalConditions') }}
                    </UiText>
                </UiRow>

                <div
                    v-for="(c, i) in notEmptyConditions"
                    :key="i"
                    :class="$style.condition"
                >
                    <div>
                        <UiRow :class="$style.condition__header" mb="s">
                            <UiText :class="$style.condition__title" size="body" accent>
                                {{ $t('sites.modes.actions.form.condition.condition.' + c) }}
                            </UiText>

                            <div v-if="c === 'utms'" :class="$style.condition__select">
                                <UiSelect
                                    v-model="form.conditions.utms_mode"
                                    :options="conditionModes"
                                    label-prop-name="label"
                                    max-width-dropdown
                                >
                                    <template #target="selectData">
                                        <UiButton
                                            :active="selectData.data.active"
                                            appearance="tertiary"
                                        >
                                            {{ selectData.data.value }}

                                            <SvgSprite name="caret_down" />
                                        </UiButton>
                                    </template>
                                </UiSelect>
                            </div>
                        </UiRow>

                        <div
                            v-for="(_, ci) in form.conditions[c]"
                            :key="ci"
                            :class="$style.condition__row"
                            class="ui-info-group__row condition-row"
                        >
                            <div :class="$style.condition__col">
                                <UiSelect
                                    v-model="form.conditions[c][ci].type"
                                    :options="conditionTypes"
                                    label-prop-name="label"
                                    max-width-dropdown
                                >
                                    <template #target="selectData">
                                        <UiButton
                                            :active="selectData.data.active"
                                            appearance="tertiary"
                                        >
                                            {{ selectData.data.value }}

                                            <SvgSprite name="caret_down" />
                                        </UiButton>
                                    </template>
                                </UiSelect>
                            </div>

                            <div
                                v-if="c === 'utms'"
                                :class="[$style.condition__col, $style.condition__col_second]"
                            >
                                <UiSelect
                                    v-model="form.conditions[c][ci].key"
                                    :options="utmOptions"
                                    max-width-dropdown
                                >
                                    <template #target="selectData">
                                        <UiButton
                                            :active="selectData.data.active"
                                            appearance="tertiary"
                                        >
                                            {{ selectData.data.value }}

                                            <SvgSprite name="caret_down" />
                                        </UiButton>
                                    </template>
                                </UiSelect>
                            </div>

                            <div class="ui-info-group__col ui-info-group__col_full">
                                <UiInput
                                    v-model="form.conditions[c][ci].value"
                                    :error="errConditionValue(c, ci)"
                                />
                            </div>

                            <div class="ui-info-group__col ui-info-group__col_btn">
                                <UiButton
                                    v-tooltip="$t('sites.modes.actions.form.condition.remove')"
                                    class="omnica-button_muted"
                                    appearance="tertiary"
                                    variant="danger"
                                    shape="square"
                                    @click="removeCondition(c, ci)"
                                >
                                    <SvgSprite name="delete_outlined" />
                                </UiButton>
                            </div>
                        </div>

                        <UiRow mt="s">
                            <UiButton
                                appearance="tertiary"
                                @click="addCondition(c)"
                            >
                                <SvgSprite name="add" />
                                {{ $t('sites.modes.actions.form.condition.add_' + c ) }}
                            </UiButton>
                        </UiRow>
                    </div>

                    <UiButton
                        v-tooltip="$t('sites.modes.actions.form.condition.remove')"
                        :class="$style.condition__remove"
                        class="omnica-button_muted"
                        appearance="tertiary"
                        variant="danger"
                        shape="square"
                        @click="clearConditions(c)"
                    >
                        <SvgSprite name="delete_outlined" />
                    </UiButton>
                </div>

                <UiRow v-show="emptyConditions.length > 0" :mt="notEmptyConditions.length ? 'm' : ''">
                    <UiSelect
                        :class="$style.conditions"
                        :dropdown-class="$style.conditions__dropdown"
                        placement="bottom-start"
                        max-width-dropdown
                    >
                        <template #target="selectData">
                            <UiButton
                                :active="selectData.data.active"
                                appearance="tertiary"
                            >
                                <SvgSprite name="add" />
                                {{ $t('sites.modes.actions.form.condition.add_condition') }}
                            </UiButton>
                        </template>

                        <UiOption
                            v-for="(c, i) in emptyConditions"
                            :key="i"
                            :label="$t('sites.modes.actions.form.condition.condition.' + c)"
                            :value="c"
                            @click="addCondition(c)"
                        />
                    </UiSelect>
                </UiRow>
            </div>
        </template>

        <template #footer-content>
            <FooterButtons
                :hide-save="hideSave"
                :saving="saving"
                @close="onCollapse"
                @save="save"
            />
        </template>
    </UiCollapseBox>
</template>

<script>
    import FooterButtons from './FooterButtons'
    import {vErrText} from '../../../main'
    import {required} from 'vuelidate/lib/validators'
    import InputDuration from '../../common/InputDuration'
    import actionSectionMixin from '../mixins/actionSectionMixin'

    const RUN_TYPE = Object.freeze({
        NO_DELAY: 'no_delay',
        AFTER_SEC: 'after_sec',
        LEAVES_PAGE: 'leaves_page'
    })

    const CONDITION = Object.freeze({
        URL: 'urls',
        UTM: 'utms',
        TITLE: 'titles'
    })

    const CONDITION_TYPE = Object.freeze({
        CONTAINS: "contains",
        NOT_CONTAINS: "not_contains",
    })

    const CONDITION_MODE = Object.freeze({
        AND: "and",
        OR: "or",
    })

    export default {
        components: {
            InputDuration,
            FooterButtons
        },

        mixins: [ actionSectionMixin ],

        props: {
            /**
             * @type action
             */
            action: {
                type: Object,
                required: true
            },
            displayingDelay: {
                type: Number,
                default: 0,
            },
        },
        validations () {
            const moreThanZero = {
                required,
                moreThanZero (value) {
                    return value > 0
                }
            }
            const conditionsValid = {
                $each: {
                    value: { required },
                }
            }

            const v = {
                form: {
                    conditions: {
                        urls: conditionsValid,
                        titles: conditionsValid,
                        utms: conditionsValid,
                    },
                },
            }
            const f = v.form

            if (this.form.enableSecOnSite) {
                f.secOnSite = moreThanZero
            }

            if (this.form.allowRetry) {
                f.secAfterClose = moreThanZero
                f.secAfterGoToPage = moreThanZero
            }

            if (this.form.runType === RUN_TYPE.AFTER_SEC) {
                f.secOnPage = moreThanZero
            }

            return v
        },
        data() {
            return {
                expanded: false,
                saving: false,
                btnClicks: 0,
                error: null,
                conditions: Object.values(CONDITION),
                form: this.defaultForm()
            }
        },
        computed: {
            emptyConditions () {
                return this.conditions.filter((c) => (this.form.conditions[c].length === 0))
            },
            notEmptyConditions () {
                return this.conditions.filter((c) => (this.form.conditions[c].length > 0))
            },
            errSecOnSite () { return this.vErrText(this.$v.form.secOnSite, ['moreThanZero']) },
            errSecAfterClose () { return this.vErrText(this.$v.form.secAfterClose, ['moreThanZero']) },
            errSecAfterGoToPage () { return this.vErrText(this.$v.form.secAfterGoToPage, ['moreThanZero']) },
            errSecOnPage () { return this.vErrText(this.$v.form.secOnPage, ['moreThanZero']) },
            hideSave () {
                return this.action.id > 0 && !this.savingAvailable
            },
            conditionTypes () {
                return Object.values(CONDITION_TYPE).map((t) => ({
                    value: t,
                    label: this.$t('sites.modes.actions.form.condition.condition_types.' + t)
                }))
            },

            conditionModes () {
                return Object.values(CONDITION_MODE).map(mode => ({
                    label: this.$t('sites.modes.actions.form.condition.condition_modes.' + mode),
                    value: mode
                }))
            },

            utmOptions () {
                const utmOptions = []
                for (const utm of ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term']) {
                    utmOptions.push({label: utm, value: utm})
                }

                return utmOptions
            }
        },
        methods: {
            vErrText(v, additionalValidators) {
                if (!this.btnClicks) {
                    return false
                }

                return vErrText(v, additionalValidators)
            },
            errConditionValue (type, i) {
                return this.vErrText(this.$v.form.conditions[type].$each[i].value, [])
            },
            save() {
                this.btnClicks++
                if (this.$v.$invalid) {
                    return
                }

                this.saving = true

                const f = this.form
                const data = {
                    allow_retry: f.allowRetry,
                    sec_after_close: f.secAfterClose,
                    sec_after_go_to_page: f.secAfterGoToPage,
                }

                if (f.enableSecOnSite) {
                    data.sec_on_site = f.secOnSite
                } else {
                    data.sec_on_site = 0
                }

                switch (f.runType) {
                    case RUN_TYPE.LEAVES_PAGE:
                        data.sec_on_page = 0
                        data.user_leaves_page = true
                        break
                    case RUN_TYPE.NO_DELAY:
                        data.sec_on_page = 0
                        data.user_leaves_page = false
                        break
                    case RUN_TYPE.AFTER_SEC:
                        data.sec_on_page = f.secOnPage
                        data.user_leaves_page = false
                }

                data.conditions = {}
                for (const ct in f.conditions) {
                    if (ct === 'utms_mode') {
                        data.conditions.utms_mode = f.conditions[ct].value
                        continue
                    }

                    data.conditions[ct] = []
                    for (const i in f.conditions[ct]) {
                        const c = f.conditions[ct][i]

                        const n = {
                            type: c.type.value,
                            value: c.value
                        }
                        if (c.key) {
                            n.key = c.key.value
                        }

                        data.conditions[ct].push(n)
                    }
                }

                this.$emit('save', {
                    data,
                    onSuccess: () => { this.onSave() },
                    onError: () => {
                        this.error = this.$t('common.error_while_saving')
                        setTimeout(() => {
                            this.error = null
                        }, 5000)
                    },
                    onFinally: () => { this.saving = false }
                })
            },
            addCondition (type) {
                const menu =  this.$refs['add-condition-menu']
                if (menu) {
                    menu.hide()
                }

                const cond = {
                    type: this.conditionTypes[0],
                    value: '',
                }

                if (type === CONDITION.UTM) {
                    cond.key = this.utmOptions[0]
                }

                this.form.conditions[type].push(cond)
            },
            removeCondition(type, i) {
                const conditions = this.form.conditions[type]
                const condition = conditions[i]

                if (!condition || (condition.value && !confirm(this.$t('common.remove_confirm')))) {
                    return
                }

                conditions.splice(i, 1)
            },
            clearConditions(type) {
                if (
                    this.form.conditions[type].filter((c) => c.value).length > 0
                        && !confirm(this.$t('common.remove_confirm'))
                ) {
                    return
                }

                this.form.conditions[type] = []
            },
            defaultForm () {
                const a = this.action
                const conditionTypes = []
                Object.values(CONDITION_TYPE).forEach((t) => {conditionTypes.push({
                    value: t,
                    label: this.$t('sites.modes.actions.form.condition.condition_types.' + t)
                })})


                let runType = RUN_TYPE.LEAVES_PAGE
                if (!a.user_leaves_page) {
                    runType = a.sec_on_page > 0 ? RUN_TYPE.AFTER_SEC : RUN_TYPE.NO_DELAY
                }

                const utmOptions = []
                for (const utm of ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term']) {
                    utmOptions.push({label: utm, value: utm})
                }

                const changeTypes = (conds) => {
                    if (!conds) {
                        return []
                    }

                    for (const i in conds) {
                        const c = conds[i]
                        c.type = conditionTypes[c.type === CONDITION_TYPE.CONTAINS ?  0 : 1]

                        if (c.key && typeof c.key !== 'object') {
                            c.key = {label: c.key, value: c.key}
                        }
                    }

                    return conds
                }

                const conditionModes = []
                for (const mode of Object.values(CONDITION_MODE)) {
                    conditionModes.push({
                        label: this.$t('sites.modes.actions.form.condition.condition_modes.' + mode),
                        value: mode
                    })
                }

                let utms_mode = conditionModes[0]
                if (a.conditions.utms_mode) {
                    const index = conditionModes.findIndex((m) => m.value = a.conditions.utms_mode)
                    if (index !== -1) {
                        utms_mode = conditionModes[index]
                    }
                }

                const conditions = {
                    urls: changeTypes(a.conditions.urls),
                    titles: changeTypes(a.conditions.titles),
                    utms: changeTypes(a.conditions.utms),
                    utms_mode,
                }

                return {
                    enableSecOnSite: this.action.sec_on_site > 0,
                    secOnSite: this.action.sec_on_site,
                    allowRetry: this.action.allow_retry,
                    secAfterClose: this.action.sec_after_close,
                    secAfterGoToPage: this.action.sec_after_go_to_page,
                    runType,
                    secOnPage: this.action.sec_on_page,
                    conditions,
                }
            }
        },

        watch: {
            'form.enableSecOnSite': function () {
                const f = this.form
                if (!f.enableSecOnSite) {
                    return
                }

                this.$nextTick(() => {
                    this.$refs['secOnSite'].$refs['inputArea'].$refs['inputArea'].focus()
                })
            },
            'form.allowRetry': function () {
                const f = this.form
                if (!f.allowRetry) {
                    return
                }

                this.$nextTick(() => {
                    this.$refs['secAfterClose'].$refs['inputArea'].$refs['inputArea'].focus()
                })
            },
            'form.runType': function () {
                if (this.form.runType === RUN_TYPE.AFTER_SEC) {
                    this.$nextTick(() => {
                        this.$refs['secOnPage'].$refs['inputArea'].$refs['inputArea'].focus()
                    })
                }
            },
        }
    }
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.desc {
    color: @grey-900 !important;
}

.duration {
    padding-left: 52px !important;
}

.condition {
    position: relative;
    padding: @spacing-s @spacing-m;
    background-color: @grey-200;
    border-radius: @border-radius-m;

    & + & {
        margin-top: @spacing-xs;
    }

    &__header {
        display: flex;
        align-items: center;
    }

    &__title {
        margin-right: @spacing-xs;
    }

    &__remove {
        position: absolute;
        top: 0;
        left: calc(100% + @spacing-xs);
    }

    &__row {
        &:not(:last-child) {
            margin-bottom: @spacing-xs;
        }
    }

    &__col {
        width: 148px;

        &_second {
            width: 155px;
        }
    }
}

.conditions {
    &__dropdown {
        min-width: 200px !important;
    }
}
</style>
