<template>
    <ContentBox
        :loading="isLoading"
        preview
        @save="save"
    >
        <template #title>
            {{ $t('sites.modes.common.title') }}
        </template>

        <div>

            <UiRow mt="s">
                <UiField
                    :label="$t('sites.modes.common.form.crm_site')"
                    area-id="common_crm_site"
                >
                    <UiSelect
                        v-model="snapshot.crm_site"
                        :options="crm.sites"
                        :error="errCrmSite"
                        id="common_crm_site"
                        label-prop-name="name"
                        value-prop-name="code"
                    />
                </UiField>
            </UiRow>

            <UiRow mt="xl" pb="20" :class="$style['row-consultant-settings']">
                <UiRow mb="xs">
                    <UiText size="paragraph" tag="label" accent>
                        {{ $t('sites.modes.common.form.consultant_settings.label') }}
                    </UiText>
                </UiRow>

                <UiRow mt="20">
                    <UiCheckbox
                        v-model.trim="snapshot.processing_personal_data"
                        :label="$t('sites.modes.common.form.consultant_settings.processing_personal_data.label')"
                        :desc="$t('sites.modes.common.form.consultant_settings.processing_personal_data.description')"
                        :error="errProcessingPersonalData"
                    />
                </UiRow>

                <UiRow mt="20" v-if="snapshot.processing_personal_data" :class="$style['row-consent']">
                    <UiField
                        :label="$t('sites.modes.common.form.consultant_settings.processing_personal_data.link')"
                        area-id="processing_personal_data_link"
                    >
                        <UiInput
                            v-model.trim="snapshot.processing_personal_data_link"
                            :placeholder="'https://'"
                            :error="errProcessingPersonalDataLink"
                            id="processing_personal_data_link"
                        />
                    </UiField>
                </UiRow>
            </UiRow>

            <UiRow mt="20">
                <UiCheckbox
                    v-model.trim="snapshot.use_sound_alerts"
                    :label="$t('sites.modes.common.form.use_sound_alerts')"
                    :error="errUseSoundAlerts"
                />
            </UiRow>

            <UiRow mt="20">
                <UiCheckbox
                    v-model.trim="snapshot.use_unread_counter"
                    :label="$t('sites.modes.common.form.use_unread_counter')"
                    :error="errUseUnreadCounter"
                />
            </UiRow>

            <UiRow mt="20">
                <UiCheckbox
                    v-model.trim="snapshot.always_show_links_on_desktops"
                    :class="$style['show-links']"
                    :label="$t('sites.modes.common.form.always_show_links_on_desktops')"
                    :error="errAlwaysShowLinksOnDesktop"
                />
            </UiRow>

            <UiRow mt="xl">
                <UiRow mb="xs">
                    <UiText size="paragraph" tag="label" accent>
                        {{ $t('sites.modes.common.form.links.label') }}
                    </UiText>
                </UiRow>

                <Draggable
                    v-model="snapshot.links"
                    :handle="`.${$style.trigger__icon}`"
                    :class="$style.links"
                    @start="dragging = true"
                    @end="$nextTick(() => { dragging = false })"
                >
                    <div v-for="(link, i) in snapshot.links" :key="i" :class="$style.social">
                        <div :class="$style.trigger">
                            <div v-if="!dragging" :class="$style.trigger__icon" />
                        </div>

                        <div :class="$style.social__select">
                            <UiSelect
                                v-model="link.type"
                                :options="linkTypesOptions"
                                label-prop-name="label"
                                value-prop-name="value"
                                max-width-dropdown
                            >
                                <template #target>
                                    <UiInput
                                        :value="getLabel(link.type)"
                                        :error="errLinksType(i)"
                                        error-tooltip-placement="left"
                                        icon-right="caret-down"
                                        readonly
                                    />
                                </template>
                            </UiSelect>
                        </div>

                        <div :class="$style.social__wrap">
                            <template v-if="link.type && link.type === 'telegram'">
                                <UiSelect
                                    v-model="link.prefix"
                                    :class="$style.social__telegram"
                                    :options="linkTypePrefixes['telegram']"
                                    max-width-dropdown
                                    @changeSelect="link.prefix = $event"
                                />

                                <UiInput
                                    v-model="link.url"
                                    :class="$style.social__input"
                                    :error="errLinksUrl(i)"
                                />
                            </template>

                            <UiInput
                                v-else
                                v-model="link.url"
                                :class="$style.social__input"
                                :error="errLinksUrl(i)"
                                :prefix="linkTypePrefixes[link.type] || link.prefix"
                            />
                        </div>
                        <div class="common_links_buttons">
                            <UiPopconfirm
                                :title="$t('common.remove_confirm')"
                                :okTitle="$t('sites.modes.common.form.links.remove')"
                                :cancelTitle="$t('common.cancel')"
                                :okVariant="BUTTON_VARIANT.DANGER"
                                placement="right"
                                @ok="removeLink(i)"
                            >
                                <template #trigger>
                                    <UiButton
                                        class="omnica-button_muted"
                                        appearance="tertiary"
                                        variant="danger"
                                        shape="square"
                                    >
                                        <SvgSprite name="delete_outlined" />
                                    </UiButton>
                                </template>
                            </UiPopconfirm>
                        </div>
                    </div>
                </Draggable>

                <UiButton appearance="tertiary" @click="addLink">
                    <UiIcon name="add" />
                    {{ $t('sites.modes.common.form.links.add') }}
                </UiButton>
            </UiRow>

            <UiRow mt="m">
                <UiField
                    :label="$t('sites.modes.common.form.icon_placement.title')"
                    :class="$style['radiogroup-box']"
                    area-id="common_direction"
                >
                    <UiRadiogroup
                        v-model="snapshot.direction_messengers"
                        label-prop-name="label"
                        size="sm"
                        :class="$style['radiogroup']"
                    >
                        <UiRadiogroupOption
                            v-for="direction in directionMessengersOptions"
                            :key="direction.value"
                            :value="direction.value"
                            :label="direction.label"
                            size="sm"
                        >
                            <template #icon>
                                <SvgSprite :name="direction.icon"/>
                            </template>
                        </UiRadiogroupOption>
                    </UiRadiogroup>
                </UiField>
            </UiRow>

            <UiRow mt="m">
                <UiText size="body" accent>
                    {{ $t('sites.modes.common.form.icon_apperance.title') }}
                </UiText>
                <UiRow
                    v-for="color in iconsColorOptions"
                    :key="color.value"
                    :class="$style['row-color']"
                >
                    <UiRadio
                        v-model="snapshot.links_mode"
                        :label="color.label"
                        :value="color.value"
                        :class="$style['radio-color']"
                    />
                    <UiRow
                        mt="m"
                        :class="$style['row-messengers']"
                    >
                        <div
                            v-for="icon in messengersIcons"
                            :key="icon.value"
                            :class="[
                                $style['messengers'],
                                $style[`messengers_${color.value}`],
                                $style[`messengers_${icon.value}`],
                            ]"
                            :style="{ ...cssVariables }"
                        >
                            <div
                                :class="[
                                    $style['messengers__image'],
                                    $style[`messengers__image_${color.value}`],
                                    $style[`messengers__image_${icon.value}`],
                                ]"
                            />
                        </div>
                    </UiRow>
                </UiRow>
            </UiRow>
        </div>

        <template #preview>
            <ConsultantPreview
                v-model="opened"
                :position="snapshot.chat_position"
                :tooltip-text="snapshot.tooltip_text"
                :name="snapshot.title || site.name"
                :main-color="snapshot.elements_color ? '#' + snapshot.elements_color : '#005EEB'"
                :manager="currentManager"
                :display-manager-type="snapshot.display_manager_type"
                :rounded="isRounded"
                :logo-link="snapshot.logo_type === 'custom' ? snapshot.logo_link : null"
                :theme="snapshot.background_color"
                :inversion-color="snapshot.inversion_colors"
                :messengers-links="snapshot.links"
                :offset="snapshot.offset"
                :crm-url="crm.url"
                :always-show-messengers="snapshot.always_show_links_on_desktops"
                :icons-color="snapshot.links_mode"
                :direction="snapshot.direction_messengers"
                :personal-data-link="snapshot.processing_personal_data_link"
            />
        </template>
    </ContentBox>
</template>

<script>
    import {vErrText} from '../../main'
    import {maxLength, required} from 'vuelidate/lib/validators'
    import {boolCheck} from '../../main'
    import Draggable from 'vuedraggable'
    import ContentBox from '../common/ContentBox'
    import ConsultantPreview from '../common/ConsultantPreview.vue'
    import { VARIANT as BUTTON_VARIANT } from '@omnica/button'
    import { SHAPE, LINKS_MODE, DIRECTION } from '@/enum/appearance.ts'
    import modeMixin from './mixins/modeMixin.js'
    import currentManagerMixin from './mixins/currentManagerMixin.js'
    import clone from '@/utils/clone'
    import {messengerLinkPrefixes as linkTypePrefixes} from '../../enum/messenger-link-prefixes'

    const linkTypeVariants = (value) => linkTypePrefixes[value] !== undefined

    export default {
        components: {
            Draggable,
            ContentBox,
            ConsultantPreview
        },

        mixins: [modeMixin, currentManagerMixin],

        props: {
            /**
             * @type crm
             */
            crm: {
                type: Object,
                required: true
            }
        },
        data () {
            return {
                btnClicks: 0,
                dragging: false,
                isLoading: false,
                opened: false,
                messengersIcons: [
                    {value: "telegram"},
                    {value: "vk"},
                    {value: "whatsapp"}
                ],
            }
        },
        validations: {
            snapshot: {
                crm_site: {
                    required,
                    availableSites (value) {
                        for (const s of this.crm.sites) {
                            if (s.code === value) {
                                return true
                            }
                        }

                        return false
                    }
                },
                always_show_links_on_desktops: {
                    required,
                    boolCheck
                },
                use_sound_alerts: {
                    required,
                    boolCheck
                },
                use_unread_counter: {
                    required,
                    boolCheck
                },
                links: {
                    $each: {
                        type: {
                            required,
                            linkTypeVariants
                        },
                        url: {
                            required,
                            maxLength: maxLength(1000)
                        }
                    }
                },
                processing_personal_data: {
                    required,
                    boolCheck
                },
            },
        },
        computed: {
            cssVariables () {
                const mainColor = this.snapshot.elements_color ? '#' + this.snapshot.elements_color : '#005EEB'
                const mainIconColor = this.snapshot.inversion_colors ? '#' + this.snapshot.inversion_colors : '#fff'

                return {
                    '--consultantMainColor': mainColor,
                    '--consultantMainIconColor': mainIconColor
                }
            },
            directionMessengersOptions () {
                return [
                    {
                        label: this.$t('sites.modes.common.form.icon_placement.vertical'),
                        value: DIRECTION.VERTICAL,
                        icon: 'more_vertical'
                    },
                    {
                        label: this.$t('sites.modes.common.form.icon_placement.horizontal'),
                        value: DIRECTION.HORIZONTAL,
                        icon: 'more_horizontal'
                    }
                ]
            },

            iconsColorOptions () {
                return [
                    {
                        label: this.$t('sites.modes.common.form.links_mode.standard'),
                        value: LINKS_MODE.STANDARD,
                    },
                    {
                        label: this.$t('sites.modes.common.form.links_mode.black'),
                        value: LINKS_MODE.BLACK,
                    },
                    {
                        label: this.$t('sites.modes.common.form.links_mode.grey'),
                        value: LINKS_MODE.GREY,
                    },
                    {
                        label: this.$t('sites.modes.common.form.links_mode.widget'),
                        value: LINKS_MODE.WIDGET,
                    }
                ]
            },

            BUTTON_VARIANT: () => BUTTON_VARIANT,
            linkTypePrefixes: () => linkTypePrefixes,
            linkTypesOptions () {
                const opts = []
                Object.keys(linkTypePrefixes).forEach((t) => {
                    opts.push({
                        value: t,
                        label: this.$t('sites.modes.common.form.links.type.values.' + t)
                    })
                })

                return opts
            },

            locale () {
                return {
                    en: 'en-GB',
                    es: 'es-ES',
                    ru: 'ru-RU',
                }[this.$i18n.locale] ?? 'en-EN'
            },

            isRounded () {
                return this.snapshot.element_shape_type === SHAPE.ROUNDED
            },
            errCrmSite () { return this.vErrText(this.$v.snapshot.crm_site, ['availableSites']) },
            errProcessingPersonalData () { return this.vErrText(this.$v.snapshot.processing_personal_data, ['boolCheck']) },
            errProcessingPersonalDataLink () {
                if (!this.btnClicks) {
                    return false
                }

                if (this.snapshot.processing_personal_data_link === '' || this.snapshot.processing_personal_data_link === undefined) {
                    return this.$t('validation.empty_link')
                }

                try {
                    new URL(this.snapshot.processing_personal_data_link);
                } catch (err) {
                    return this.$t('validation.invalid_link')
                }

                return false;
            },
            errUseSoundAlerts () { return this.vErrText(this.$v.snapshot.use_sound_alerts, ['boolCheck']) },
            errUseUnreadCounter () { return this.vErrText(this.$v.snapshot.use_unread_counter, ['boolCheck']) },
            errAlwaysShowLinksOnDesktop () { return this.vErrText(this.$v.snapshot.always_show_links_on_desktops, ['boolCheck']) },
        },

        methods: {
            errLinksType (i) { return this.vErrText(this.$v.snapshot.links.$each[i].type, ['linkTypeVariants']) },
            errLinksUrl (i) { return this.vErrText(this.$v.snapshot.links.$each[i].url) },
            vErrText(v, additionalValidators) {
                if (!this.btnClicks) {
                    return false
                }

                return vErrText(v, additionalValidators)
            },
            save () {
                this.btnClicks++
                if (this.$v.$invalid) {
                    return
                }

                this.isLoading = true
                this.snapshot.contact_form_personalData = this.snapshot.processing_personal_data
                this.snapshot.offline_form_personalData = this.snapshot.processing_personal_data

                this.$emit('save', {
                    site: clone(this.snapshot),
                    callback: () => {
                        this.isLoading = false
                    }
                })
            },
            linkPrefixesOfType (type) {
                const pref = linkTypePrefixes[type]

                if (!pref) {
                    return []
                }

                if (Array.isArray(pref)) {
                    return pref
                }

                return [pref]
            },
            addLink () {
                this.snapshot.links.push({
                    type: null,
                    prefix: null,
                    url: null
                })
            },
            removeLink (index) {
                this.snapshot.links.splice(index, 1)
            },
            getLabel (type) {
                if (!type) {
                    return ''
                }

                return this.$t('sites.modes.common.form.links.type.values.' + type)
            }
        },
        watch: {
            'snapshot.links': {
                deep: true,
                handler (links) {
                    if (links.length === this.site.links.length) {
                        return
                    }

                    links.forEach((l) => {
                        const prefixes = this.linkPrefixesOfType(l?.type)

                        if (prefixes.length > 1) {
                            if (!l.prefix) {
                                l.prefix = prefixes[0]
                            }
                        } else {
                            l.prefix = null
                        }
                    })
                }
            }
        }
    }
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";


.row {
    display: flex;
    align-items: center;

    &__caption {
        margin-left: @spacing-xs;
    }

    &__col {
        &:not(:last-child) {
            margin-right: @spacing-l;
        }
    }

    &-color {
        border-radius: 8px;
        width: 400px;
        height: 56px;
        background: @grey-200;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 12px !important;
    }

    &-messengers {
        display: flex;
        margin: 0 !important;
        left: 12px;
        width: 88px;
        height: @spacing-m;
        justify-content: space-between;
        margin-right: @spacing-m !important;
    }

    &-consultant-settings {
        border-bottom: 1px solid @grey-500;
    }

    &-consent {
        margin-left: @spacing-l !important;
    }
}

.links {
    margin-left: -22px;
}

.social {
    margin-bottom: @spacing-xs;
    display: flex;

    &__select {
        margin-right: @spacing-xs;
        width: 160px;
        flex-shrink: 0;
    }

    &__wrap {
        margin-right: @spacing-xs;
        display: flex;
        flex-grow: 1;
    }

    &__input {
        flex-grow: 1;
    }

    &__telegram {
        margin-right: @spacing-xs;
        width: 120px;
    }
}

.trigger {
    position: relative;
    width: 20px;
    height: 20px;
    align-self: center;

    &__icon {
        visibility: hidden;
        padding: 0px;
        margin: 0px;
        width: 100%;
        height: 100%;
        background-image: url("data:image/svg+xml,%3Csvg fill='%23AFB9C3' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9 8C10.1046 8 11 7.10457 11 6C11 4.89543 10.1046 4 9 4C7.89543 4 7 4.89543 7 6C7 7.10457 7.89543 8 9 8ZM7 12C7 10.8954 7.89543 10 9 10C10.1046 10 11 10.8954 11 12C11 13.1046 10.1046 14 9 14C7.89543 14 7 13.1046 7 12ZM9 16C7.89543 16 7 16.8954 7 18C7 19.1046 7.89543 20 9 20C10.1046 20 11 19.1046 11 18C11 16.8954 10.1046 16 9 16ZM17 6C17 7.10457 16.1046 8 15 8C13.8954 8 13 7.10457 13 6C13 4.89543 13.8954 4 15 4C16.1046 4 17 4.89543 17 6ZM15 10C13.8954 10 13 10.8954 13 12C13 13.1046 13.8954 14 15 14C16.1046 14 17 13.1046 17 12C17 10.8954 16.1046 10 15 10ZM13 18C13 16.8954 13.8954 16 15 16C16.1046 16 17 16.8954 17 18C17 19.1046 16.1046 20 15 20C13.8954 20 13 19.1046 13 18Z'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;
        cursor: move;
    }
}

.input-number {
    & > div > div {
        width: 52px;
    }
}

.show-links {
    & > label > span:nth-child(2) {
        position: relative;
        top: -2px;
        padding-top: 0;

        & > span {
            line-height: (24 / 16);
        }
    }
}

.messengers {


    display: block;
    margin: 0;
    width: @spacing-m;
    height: @spacing-m;
    border-radius: 50%;
    background: none;
    position: relative;

    &_whatsapp {
      background-color: #4BD854;
    }

    &_telegram {
      background-color: #589dd2;
    }

    &_vk {
      background-color: #4f749d;
    }

    &_black {
      background: @black-500;
    }

    &_grey {
      background: @grey-500;
    }

    &_widget {
      background: var(--consultantMainColor);
    }


  &__image {
    background-color: #fff;
    height: 100%;
    width: 100%;

    &_whatsapp {
      --clipPath: path('M 14.1195 12.756 c 0.165 0 1.7572 0.8235 1.8255 0.9397 c 0.0195 0.0488 0.0195 0.1065 0.0195 0.1455 c 0 0.2423 -0.078 0.5138 -0.165 0.7365 c -0.225 0.5423 -1.1332 0.891 -1.6898 0.891 c -0.4688 0 -1.4363 -0.4065 -1.8555 -0.6 c -1.3965 -0.63 -2.2657 -1.7062 -3.1058 -2.9363 c -0.3712 -0.543 -0.7035 -1.2112 -0.6938 -1.8803 V 9.975 c 0.0195 -0.6398 0.2543 -1.095 0.723 -1.5315 c 0.1462 -0.135 0.303 -0.213 0.5078 -0.213 c 0.117 0 0.2348 0.0292 0.3615 0.0292 c 0.2632 0 0.312 0.0772 0.4103 0.33 c 0.0675 0.1643 0.5663 1.482 0.5663 1.5787 c 0 0.3682 -0.6743 0.7853 -0.6743 1.008 c 0 0.048 0.0203 0.0968 0.0495 0.1455 c 0.2145 0.4552 0.6247 0.9788 0.996 1.3275 c 0.4492 0.426 0.9278 0.7072 1.4745 0.9788 c 0.0683 0.0382 0.1365 0.0675 0.2145 0.0675 c 0.2925 0 0.7815 -0.9397 1.035 -0.9397 z m -1.983 5.1353 c 3.3795 0 6.1327 -2.7322 6.1327 -6.0847 s -2.754 -6.0855 -6.1327 -6.0855 c -3.3788 0 -6.1327 2.7322 -6.1327 6.0855 c 0 1.2788 0.4103 2.529 1.1723 3.5655 l -0.7717 2.2575 l 2.3632 -0.7455 a 6.2077 6.2077 90 0 0 3.369 1.0072 z m 0 -13.3912 C 16.2 4.5 19.5 7.7753 19.5 11.8065 c 0 4.0305 -3.3007 7.3057 -7.3635 7.3057 a 7.4122 7.4122 90 0 1 -3.564 -0.9105 L 4.5 19.5 l 1.3275 -3.9247 a 7.2285 7.2285 90 0 1 -1.0537 -3.7688 c 0 -4.0313 3.3 -7.3065 7.3628 -7.3065 z');
      -webkit-clip-path: var(--clipPath);
      clip-path: var(--clipPath);
    }

    &_telegram {
      --clipPath: path("M 15.8475 17.3062 l 2.1128 -9.759 c 0.1875 -0.8595 -0.3165 -1.197 -0.891 -0.9862 l -12.42 4.6898 c -0.8475 0.324 -0.8333 0.7882 -0.1432 0.9997 l 3.177 0.972 l 7.374 -4.5488 c 0.345 -0.225 0.6607 -0.099 0.402 0.1268 l -5.9655 5.2808 l -0.2295 3.2107 c 0.33 0 0.474 -0.141 0.6465 -0.3097 l 1.5525 -1.4647 l 3.2198 2.3235 c 0.5895 0.324 1.0065 0.1552 1.1647 -0.5347 z");
      -webkit-clip-path: var(--clipPath);
      clip-path: var(--clipPath);
    }

    &_vk {
      --clipPath: path("M 18.984 8.619 s -0.1095 -0.3127 -0.789 -0.2265 l -2.25 0.015 c -0.1718 -0.0225 -0.297 0.0473 -0.297 0.0473 s -0.1327 0.0705 -0.195 0.2423 a 14.4195 14.4195 90 0 1 -0.8362 1.758 c -0.9997 1.7025 -1.4063 1.797 -1.5697 1.6875 c -0.3825 -0.2423 -0.2813 -0.984 -0.2813 -1.515 c 0 -1.6575 0.2498 -2.3445 -0.4928 -2.5238 c -0.2423 -0.0547 -0.4215 -0.0938 -1.0545 -0.102 c -0.8047 -0.0075 -1.485 0 -1.8675 0.1875 c -0.2573 0.1253 -0.453 0.4065 -0.3352 0.4222 c 0.1477 0.0232 0.4838 0.0938 0.6638 0.336 c 0 0 0.195 0.3202 0.2265 1.023 c 0.0863 1.9455 -0.3127 2.1877 -0.3127 2.1877 c -0.3045 0.1643 -0.8355 -0.1095 -1.617 -1.719 c 0 0 -0.453 -0.7965 -0.8047 -1.6718 c -0.0622 -0.1643 -0.1875 -0.2498 -0.1875 -0.2498 s -0.1402 -0.1095 -0.3435 -0.141 L 4.5 8.3925 c -0.3285 0 -0.4455 0.1485 -0.4455 0.1485 s -0.117 0.1253 -0.0075 0.3825 c 1.6718 3.93 3.5775 5.8987 3.5775 5.8987 s 1.7422 1.8203 3.7192 1.695 h 0.8985 c 0.273 -0.0225 0.414 -0.1718 0.414 -0.1718 s 0.1245 -0.1402 0.117 -0.3982 c -0.015 -1.203 0.5475 -1.383 0.5475 -1.383 c 0.546 -0.1718 1.2495 1.164 1.9995 1.68 c 0 0 0.57 0.39 0.9997 0.3045 l 2.0002 -0.0315 c 1.0545 -0.0075 0.555 -0.8828 0.555 -0.8828 c -0.0398 -0.0698 -0.2895 -0.609 -1.4925 -1.7265 c -1.2503 -1.164 -1.086 -0.9765 0.4297 -2.9925 c 0.9218 -1.2263 1.2885 -1.9762 1.1715 -2.2965 z");
      -webkit-clip-path: var(--clipPath);
      clip-path: var(--clipPath);
    }

    &_black {
      background: #fff;
    }

    &_grey {
      background: @black-500;
    }

    &_widget {
      background: var(--consultantMainIconColor);
    }
  }
}

.radiogroup {
    width: 360px;
}

.radio-color {
    margin-left: 12px;
}
</style>
