<template>
    <UiCollapseBox
        id="type"
        v-model="expanded"
        :collapsible="!savingAvailable"
    >
        <template #title>
            {{ $t('sites.modes.actions.form.type.title') }}
        </template>

        <template #description>
            {{ $t('sites.modes.actions.form.type.description') }}
        </template>

        <template #body-content>
            <div class="ui-content-box">
                <UiRow
                    v-for="(type, i) in types"
                    :key="i"
                    :mt="i === 0 ? '' : 's'"
                >
                    <UiRadio
                        :label="$t('sites.modes.actions.form.type.names.' + type)"
                        v-model="form.type"
                        :value="type"
                        focused
                    />
                </UiRow>


                <div class="ui-content-box__msg">
                    <UiField
                        :label="$t('sites.modes.actions.form.type.message' + (cntAvailableMessages > 1 ? 's' : ''))"
                    >
                        <div
                            v-for="(_, i) in form.messages"
                            :key="i"
                            class="ui-content-box__msg-area-box .row"
                        >
                            <UiTextarea
                                v-model.trim="form.messages[i]"
                                :error="errMessage(i)"
                                class="ui-content-box__msg-area"
                            />

                            <div class="ui-content-box__msg-area-btn" v-if="(i) > 0">
                                <UiPopconfirm
                                    :title="$t('common.remove_confirm')"
                                    placement="right"
                                    remove
                                    @ok="deleteMessage(i)"
                                >
                                    <template #trigger>
                                        <UiButton
                                            v-tooltip="$t('sites.modes.actions.form.type.delete_message')"
                                            class="omnica-button_muted"
                                            appearance="tertiary"
                                            variant="danger"
                                            shape="square"
                                        >
                                            <SvgSprite name="delete_outlined" />
                                        </UiButton>
                                    </template>
                                </UiPopconfirm>
                            </div>
                        </div>
                    </UiField>
                </div>

                <UiRow v-if="form.messages.length < cntAvailableMessages" mt="m">
                    <div
                        v-show="form.messages.length < cntAvailableMessages"
                        class="ui-auto-messages-inner ui-add-btn"
                    >
                        <UiButton
                            appearance="tertiary"
                            @click="addMessage"
                        >
                            <SvgSprite name="add" />
                            {{ $t('sites.modes.actions.form.type.add_message') }}
                        </UiButton>
                    </div>
                </UiRow>

                <UiRow v-if="form.type === 'contact_form'" mt="m">
                    <div class="ui-form-setting">
                        <UiField :label="$t('sites.modes.actions.form.type.contact_form.fields')">
                            <div
                                v-for="(field, i) in fields"
                                :key="i"
                                :class="$style.row"
                            >
                                <div :class="$style.row__switch">
                                    <UiSwitch
                                        v-model="form.contact_form[field]"
                                        :disabled="field === 'personalData'"
                                    >
                                        <span :class="[
                                            $style['form-fields__label'],
                                            {[$style['form-fields__label_offset']]: field === 'personalData'}
                                        ]">
                                            {{ $t('sites.modes.actions.form.type.contact_form.' + field) }}
                                        </span>
                                    </UiSwitch>
                                    <UiTooltip placement="right" v-if="field === 'personalData'" :class="$style['form-fields__hint-wrap']">
                                        <template #trigger>
                                            <SvgSprite :class="$style['form-fields__hint-icon']" name="info_outlined" />
                                        </template>
                                        <div :class="$style['form-fields__hint-tooltip']">
                                            {{ $t('sites.modes.offline.form.tooltip.consent') }}
                                        </div>
                                    </UiTooltip>
                                </div>

                                <div class="ui-form-setting__col">
                                    <UiSelect
                                        :disabled="!form.contact_form[field]"
                                        placement="bottom-start"
                                        max-width-dropdown
                                    >
                                        <template #target="selectData">
                                            <UiButton
                                                :locked="!form.contact_form[field] || field === 'personalData'"
                                                :active="selectData.data.active"
                                                appearance="tertiary"
                                            >
                                                {{ form.contact_form[field + '_required'].value ? $t('common.required') : $t('common.not_required') }}

                                                <UiIcon name="caretDown" />
                                            </UiButton>
                                        </template>

                                        <UiOption
                                            :active="form.contact_form[field + '_required'].value === true"
                                            :label="$t('common.required')"
                                            value="required"
                                            @click="form.contact_form[field + '_required'].value = true"
                                        />

                                        <UiOption
                                            :active="form.contact_form[field + '_required'].value === false"
                                            :label="$t('common.not_required')"
                                            value="notRequired"
                                            @click="form.contact_form[field + '_required'].value = false"
                                        />
                                    </UiSelect>
                                </div>
                            </div>
                        </UiField>

                        <UiText v-if="errContactForm" color="action">
                            {{ errContactForm }}
                        </UiText>
                    </div>
                </UiRow>
            </div>
        </template>

        <template #footer-content>
            <FooterButtons
                :hide-save="hideSave"
                :saving="saving"
                @close="onCollapse"
                @save="save"
            />
        </template>
    </UiCollapseBox>
</template>

<script>
    import {maxLength, required} from 'vuelidate/lib/validators'
    import {boolCheck, vErrText} from '../../../main'
    import FooterButtons from './FooterButtons'
    import actionSectionMixin from '../mixins/actionSectionMixin'
    import { ACTION_TYPE } from '@/enum/actions.ts'
    import clone from '@/utils/clone'

    export default {
        components: {
            FooterButtons
        },

        mixins: [ actionSectionMixin ],

        props: {
            /**
             * @type action
             */
            action: {
                type: Object,
                required: true
            },
            site: {
                type: Object,
                required: true
            },
            editUrl: String,
        },
        data() {
            return {
                expanded: true,
                saving: false,
                error: null,
                fields: ['name', 'email', 'phone', 'personalData'],
                btnClicks: 0,
                cutMessage: null,
                form: this.defaultForm()
            }
        },
        validations() {
            return  {
                form: {
                    type: {
                        required,
                        actionType: (value) => {
                            return Object.values(ACTION_TYPE).includes(value)
                        }
                    },
                    messages: {
                        required,
                        $each: {
                            required,
                            maxLength: maxLength(1000)
                        }
                    },
                    contact_form: {
                        atLeastOneField: () => {
                            if (this.form.type !== ACTION_TYPE.CONTACT_FORM) {
                                return true
                            }

                            const cf = this.form.contact_form
                            return cf.name || cf.email || cf.phone || cf.personalData
                        },
                        atLeastOneFieldRequired: () => {
                            if (this.form.type !== ACTION_TYPE.CONTACT_FORM) {
                                return true
                            }

                            const cf = this.form.contact_form
                            return cf.name_required.value || cf.email_required.value || cf.phone_required.value
                        },
                        name: {boolCheck},
                        email: {boolCheck},
                        phone: {boolCheck},
                        personalData: {boolCheck},
                    }
                },
            }
        },
        computed: {
            errContactForm () {
                if (!this.btnClicks) {
                    return false
                }

                if (this.$v.form.contact_form.atLeastOneField === false) {
                    return this.$t('validation.at_least_one_form_field')
                }

                if (this.$v.form.contact_form.atLeastOneFieldRequired === false) {
                    return this.$t('sites.modes.contact.form.at_least_one_required')
                }

                return vErrText(this.$v.form.contact_form, [])
            },
            types() {
                return Object.values(ACTION_TYPE)
            },
            cntAvailableMessages() {
                if (this.form?.type === ACTION_TYPE.CHAT_MESSAGE) {
                    return 2
                }

                return 1
            },
            hideSave () {
                return this.action.id > 0 && !this.savingAvailable
            }
        },

        created () {
            this.$emit('update-action', clone(this.form))
        },

        watch: {
            cntAvailableMessages () {
                // Чтобы вотчер не срабатывал при сбросе изменений
                if (this.resetting) {
                    return
                }

                if (this.cntAvailableMessages === 2) {
                    if (this.cutMessage) {
                        this.form.messages.push(this.cutMessage)
                        this.cutMessage = null
                    }
                } else if (this.form.messages.length === 2) {
                    if (this.form.messages[1]) {
                        this.cutMessage = this.form.messages[1]
                    }
                    this.form.messages.splice(1,1)
                }
            },

            form: {
                handler () {
                    this.$emit('update-action', clone(this.form))
                },
                deep: true
            }
        },

        methods: {
            errMessage (i) { return this.vErrText(this.$v.form.messages.$each[i]) },
            addMessage() {
                this.form.messages.push('')
            },
            deleteMessage(i) {
                if (this.form.messages[i] === undefined) {
                    return
                }

                this.form.messages.splice(i, 1)
            },
            vErrText(v, additionalValidators) {
                if (!this.btnClicks) {
                    return false
                }

                return vErrText(v, additionalValidators)
            },
            save() {
                this.btnClicks++
                if (this.$v.$invalid) {
                    return
                }

                this.saving = true

                const f = this.form
                const data = {
                    type: f.type
                }

                if (f.type === ACTION_TYPE.CONTACT_FORM) {
                    data.contact_form = { text: f.messages[0] }
                    for (const prop of this.fields) {
                        data.contact_form[prop] = f.contact_form[prop]
                        data.contact_form[prop + '_required'] = f.contact_form[prop + '_required'].value
                    }
                } else {
                    data.messages = f.messages.slice(0, this.cntAvailableMessages)
                }

                this.$emit('save', {
                    data,
                    onSuccess: () => { this.onSave() },
                    onError: () => {
                        this.error = this.$t('common.error_while_saving')
                        setTimeout(() => {
                            this.error = null
                        }, 5000)
                    },
                    onFinally: () => { this.saving = false }
                })
            },

            defaultForm () {
                const fields = ['name', 'email', 'phone', "personalData"]
                const filledOptions = [
                    {
                        label: this.$t('sites.modes.actions.form.type.contact_form.filled_values.requred'),
                        value: true
                    },
                    {
                        label: this.$t('sites.modes.actions.form.type.contact_form.filled_values.not_requred'),
                        value: false
                    }
                ]

                let messages = this.action.messages || ['']
                let contactForm = {
                    name: false,
                    name_required: clone(filledOptions[1]),
                    email: false,
                    email_required: clone(filledOptions[1]),
                    phone: false,
                    phone_required: clone(filledOptions[1]),
                }

                if (this.action.type === ACTION_TYPE.CONTACT_FORM) {
                    messages = [this.action.contact_form.text]

                    contactForm = {}
                    for (const prop of fields) {
                        contactForm[prop] = this.action.contact_form[prop]
                        if (this.action.contact_form[prop + '_required']) {
                            contactForm[prop + '_required'] = clone(filledOptions[0])
                        } else {
                            contactForm[prop + '_required'] = clone(filledOptions[1])
                        }
                    }
                }

                contactForm['personalData'] = this.site.processing_personal_data
                contactForm['personalData_required'] = clone(filledOptions[0])

                return {
                    type: this.action.type,
                    messages: messages,
                    contact_form: contactForm
                }
            }
        }
    }
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.row {
    display: flex;
    align-items: center;

    &__switch {
        display: flex;
        align-items: center;
        width: 250px;
    }

    &:not(:last-child) {
        margin-bottom: @spacing-s;
    }
}

.form-fields {
    &__label {
        white-space: normal;

        &_offset {
            cursor: default;
        }
    }

    &__hint-icon {
        width: @spacing-s;
        height: @spacing-s;
        fill: @grey-700;
        color: @grey-700;

        &:hover {
            fill: @blue-500;
            color: @blue-500;
        }
    }

    &__hint-wrap {
        align-self: end;
    }

    &__hint-tooltip {
        width: 192px;
        height: auto;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.15px;
    }
}
</style>
