import clone from '@/utils/clone'
import uuid4 from '@/utils/uuid4'

export default {
    inject: {
        __registerSection: {
            default: () => {}
        },

        __unregisterSection: {
            default: () => {}
        },
    },

    data () {
        return {
            formSnapshot: {},
            resetting: false,
            btnClicks: 0
        }
    },

    created () {
        this.id = uuid4()
    },

    computed: {
        savingAvailable () {
            return JSON.stringify(this.form) !== JSON.stringify(this.formSnapshot)
        }
    },

    mounted () {
        this.makeSnapshot()
        this.__registerSection(this.id, this.getSavingAvailable)
    },

    beforeDestroy () {
        this.__unregisterSection(this.id)
    },

    methods: {
        getSavingAvailable () {
            return this.savingAvailable
        },

        /** Коллбек применения настроек после сохранения изменений */
        onSave () {
            this.btnClicks = 0
            this.$nextTick(() => {
                this.form = this.defaultForm()
                this.makeSnapshot()
            })
        },

        /** Создание снимка текущей формы */
        makeSnapshot () {
            this.formSnapshot = clone(this.form)
        },
        
        /** Сброс изменений в форме секции */
        resetForm () {
            this.resetting = true
            this.form = clone(this.formSnapshot)

            setTimeout(() => { this.resetting = false }, 150)
        },

        /** Закрытие секции при клике на кнопку */
        onCollapse () {
            if (!this.savingAvailable) {
                this.expanded = false
                return
            }

            this.$omnicaModalWindow.msgBoxConfirm(this.$t('systemPopup.dataLostDescription'))
                .then(ok => {
                    if (!ok) {
                        return
                    }

                    this.resetForm()
                    this.$nextTick(() => {
                        this.expanded = false
                    })
                })
        }
    }
}