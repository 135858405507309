import clone from '@/utils/clone'
import uuid4 from '@/utils/uuid4'

export default {
    inject: {
        __registerSection: {
            default: () => {}
        },

        __unregisterSection: {
            default: () => {}
        },
    },

    props: {
        /**
         * @type site
         */
        site: {
            type: Object,
            required: true
        },
    },

    data () {
        return {
            snapshot: {}
        }
    },

    computed: {
        savingAvailable () {
            return JSON.stringify(this.site) !== JSON.stringify(this.snapshot)
        }
    },

    watch: {
        site: {
            handler (site) {
                if (site) {
                    this.makeSnapshot()
                }
            },
            deep: true
        }
    },

    created () {
        this.makeSnapshot()
        this.id = uuid4()
        this.__registerSection(this.id, this.getSavingAvailable)
    },

    beforeDestroy () {
        this.__unregisterSection(this.id)
    },

    methods: {
        makeSnapshot () {
            this.snapshot = clone(this.site)
        },

        reset () {
            this.makeSnapshot()
        },

        getSavingAvailable () {
            return this.savingAvailable
        }
    }
}